import { useMemo } from 'react';

/**
 * Generate a random element id.
 *
 * Some elements use ids as required fields, but ids must be
 *   unique to the entire DOM, as such, having two of the same
 *   ids on a page can often lead to an accessibility violation
 *   due to colliding ids.
 *
 * This hook will generate an id with the pattern
 *   `/^prefix-\w{10}$/`. It will only be generated on the
 *   first rendering of a particular element, and it
 *   will be unique for each element.
 *
 * @returns id matching pattern `/^prefix-\w{10}$/`
 */
export const useRandomId = (prefix: string) =>
  useMemo(() => `${prefix}-${Math.random().toString(32).substr(2, 10)}`, [prefix]);
