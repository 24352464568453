import InputAdornment from '@material-ui/core/InputAdornment';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import React, { useMemo, useState } from 'react';
import { OwnUpMenuItem } from '..';
import { useElementId } from '../../hooks/use-element-id';
import { useWrappedFunction } from '../../hooks/use-wrapped-function';
import { ErrorLightIcon } from '../../icon-library/notification-icons/error-light';
import { OwnUpIcon } from '../../icons';
import { OwnUpFormControl } from '../common/form-control/element';
import { OwnUpAutocompleteProps } from './properties';
import { dropdownMenuStyles, StyledDropdownTextInput } from './style';
import { useChevronIcon } from './use-chevron-icon';

/**
 * A dropdown that can have its results filtered out based on the user's input.
 *   Note that if the user removes their input completely, it will result in the
 *   the string 'null'.
 */
export const OwnUpFilterDropdown = ({
  id,
  menuPosition,
  value,
  label,
  labelPosition = 'inner',
  helperText,
  error,
  inputLabelRef,
  formHelperTextRef,
  errorTextRef,
  disabled,
  disabledReason,
  placeholder,
  open,
  onOpen,
  onClose,
  shrink,
  options,
  ...props
}: OwnUpAutocompleteProps) => {
  const $inputId = useElementId(id, 'autocomplete');

  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(open);
  const onOpenWrapper = useWrappedFunction(
    (_: React.ChangeEvent<{}>) => setFilterDropdownOpen(true),
    onOpen
  );
  const onCloseWrapper = useWrappedFunction(
    (_event: React.ChangeEvent<{}>, _reason: AutocompleteCloseReason) =>
      setFilterDropdownOpen(false),
    onClose
  );

  const IconElement = useChevronIcon(menuPosition, isFilterDropdownOpen);

  // Optional placeholder for outer label components
  const outerLabelPlaceholder = useMemo(
    () => (labelPosition === 'outer' && placeholder ? placeholder : undefined),
    [labelPosition, placeholder]
  );

  const classes = dropdownMenuStyles({ menuPosition });

  const CustomPopper = (popperProps: PopperProps) => {
    return <Popper {...popperProps} placement={menuPosition as any} />;
  };

  const [shrinkLabel, setShrinkLabel] = useState(false);

  return (
    <React.Fragment>
      <OwnUpFormControl
        $inputId={$inputId}
        label={label}
        labelPosition={labelPosition}
        helperText={helperText}
        error={error}
        inputLabelRef={inputLabelRef}
        formHelperTextRef={formHelperTextRef}
        errorTextRef={errorTextRef}
        disabled={disabled}
        disabledReason={disabledReason}
        placeholder={outerLabelPlaceholder}
        onClick={() => setShrinkLabel(true)}
        onBlur={() => setShrinkLabel(false)}
        // Always force the label to shrink when the menu is
        //   open or a value is selected. This override's
        //   MUI's behavior that keeps the label shrunk when
        //   clicking out of a menu but not picking anything.
        shrink={isFilterDropdownOpen || shrinkLabel || (!!value && value !== 'null')}
      >
        <Autocomplete
          {...props}
          id={$inputId}
          options={options}
          open={isFilterDropdownOpen}
          onOpen={onOpenWrapper}
          onClose={onCloseWrapper}
          popupIcon={<IconElement />}
          closeIcon={false}
          disabled={disabled}
          value={value}
          classes={{
            paper: classes.paper,
            listbox: classes.listbox,
            option: classes.option,
            noOptions: classes.noOptions
          }}
          PopperComponent={CustomPopper}
          // Hack: when the search value is set to a "null" literal
          //  force it to reset to an empty string.
          getOptionLabel={(option) => (option === 'null' ? '' : option || '')}
          renderOption={(option) => <OwnUpMenuItem value={`${option}`}>{option}</OwnUpMenuItem>}
          renderInput={(params) => (
            <StyledDropdownTextInput
              {...params}
              placeholder={outerLabelPlaceholder}
              labelPosition={labelPosition}
              hasValue={!!placeholder && !!(params.inputProps as any).value}
              // HACK: No matter which way I slice it the typing of this keeps
              //   resolving this as undefined rather than string, as if it's
              //   trying to resolve the original's "boolean" type and the
              //   overridden "string" type. There's no runtime problems here,
              //   just a typescript issue. Hopefully it'll be resolved in
              //   future iterations. - BC
              error={error as any}
              variant="filled"
              InputProps={{
                ...params.InputProps,
                inputProps: {
                  ...params.inputProps,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'aria-label': 'autocomplete-input',
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'aria-invalid': error ? true : false
                },
                // Hack: should be `endAdornment` but AutoComplete enforces its own
                //  `endAdornment` such that re-defining it hides the dropdown icons.
                startAdornment: error && (
                  <InputAdornment position="end">
                    <OwnUpIcon icon={ErrorLightIcon} />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </OwnUpFormControl>
    </React.Fragment>
  );
};
