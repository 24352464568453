import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { POLYFILL_MAP, PolyfillName, polyfillNames } from './polyfill-definitions';
import { OwnUpPolyfillProperties } from './properties';

/**
 * Create a script tag for the given polyfill so that it
 *   will be loaded from the server's static file directory.
 */
const createPolyfill = (name: PolyfillName) => (
  <Helmet key={name}>
    <script src={POLYFILL_MAP[name]} />
  </Helmet>
);

/**
 * Element in which to inject polyfills. Polyfills are all
 *   loaded as script elements rather than imported modules,
 *   as we need to ensure they are executed on the client's
 *   browser at the right time.
 *
 * See the polyfill section of the README for more details.
 *
 * @param props - dictates which polyfills to inject or not
 *   inject. Set a polyfill to "false" to prevent it from
 *   being injected. {@see OwnUpPolyfillProperties}
 */
export const OwnUpComponentLibraryPolyfills = (polyfillConfig: OwnUpPolyfillProperties) => {
  const polyfillElements = useMemo(
    () =>
      polyfillNames.reduce((acc, polyfill) => {
        if (polyfillConfig[polyfill] !== false) {
          acc.push(createPolyfill(polyfill));
        }
        return acc;
      }, [] as React.ReactNode[]),
    [polyfillConfig]
  );
  return <React.Fragment>{polyfillElements}</React.Fragment>;
};
