import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { OwnUpIconButton } from '../../buttons';
import * as colors from '../../colors';
import { createElementOutlineMixin } from '../../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../../core/theme/mixins/focus-visible-selector';
import { aktivGroteskRegularMixin } from '../../fonts';
import { SearchInputProps } from './properties';

export const SearchIconButton = styled(OwnUpIconButton)`
  margin: 8px;
`;

const searchInputHeightAndRadius = '4rem';

export const SearchTextField = styled(TextField)<SearchInputProps>`
  .MuiInputBase-input {
    ${aktivGroteskRegularMixin}
    line-height: 1.5rem;
    color: ${colors.CHARCOAL_100};
    font-size: 1rem;
    padding-left: 28px;
    ::placeholder {
      opacity: 1;
      color: ${colors.SLATE_100};
    }
  }
  .MuiInputBase-root {
    height: ${searchInputHeightAndRadius};
    min-width: 312px;
    border-radius: ${searchInputHeightAndRadius};
    border: 1px solid ${colors.FOG_60};
    background-color: ${colors.WHITE_100};
    box-shadow: 4px 4px 8px 0 rgba(29, 29, 31, 0.05);
    &:hover {
      box-shadow: 4px 8px 8px 0 rgba(29, 29, 31, 0.1);
    }
    &:focus-within {
      border-radius: ${searchInputHeightAndRadius};
      ${createElementOutlineMixin({
        color: colors.ALOE_100,
        width: '2px',
        radius: searchInputHeightAndRadius
      })}
      box-shadow: 4px 8px 8px 0 rgba(29, 29, 31, 0.1);
    }
  }
  &&${focusVisibleSelectorMixin} {
    outline: none;
    .MuiInputBase-root {
      border-radius: ${searchInputHeightAndRadius};
      ${createElementOutlineMixin({
        color: colors.ALOE_100,
        width: '1px'
      })}
      box-shadow: 4px 8px 8px 0 rgba(29, 29, 31, 0.1);
    }
  }
`;
