import styled from 'styled-components';
import { PropsWithTheme } from '../core/theme/properties';
import { createOwnUpButtonThemeStyle, OwnUpButton } from './buttons-common';
import { OwnUpButtonProps } from './properties';
import { actionButtonStyle } from './styles';
import { ACTION_BUTTON_VARIANT } from './theme';

const actionButtonThemeStyle = createOwnUpButtonThemeStyle(ACTION_BUTTON_VARIANT);

export const OwnUpActionButton = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${actionButtonThemeStyle}
  ${actionButtonStyle}
`;
