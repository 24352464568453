/* eslint-disable @typescript-eslint/naming-convention */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../../../core';
import { createElementOutlineMixin } from '../../../core/theme/mixins/element-outline';
import { commonIconInputsMixin } from '../../common/common-icon-inputs-mixin';

interface RadioLabelProps {
  /**
   * The focus-visible polyfill doesn't appear to be compatible with
   *   MUI's radioButtons so we have to make do with some custom props.
   */
  $focusVisible: boolean;
  width?: React.CSSProperties['width'];
}

export const StyledRadioLabel = styled(FormControlLabel)<PropsWithTheme<RadioLabelProps>>`
  ${commonIconInputsMixin}
  color: ${({ theme }: PropsWithTheme<RadioLabelProps>) =>
    theme.radioButtonColors.radioButtonFontColorLabel};
  background-color: ${({ theme }: PropsWithTheme<RadioLabelProps>) =>
    theme.radioButtonColors.radioButtonColorBackground};

  .Mui-disabled {
    color: ${({ theme }: PropsWithTheme<RadioLabelProps>) =>
      theme.radioButtonColors.radioButtonFontColorLabel};
  }
  &.Mui-disabled {
    opacity: 40%;
  }

  .ouRadioButtonChecked {
    & > rect.outer {
      stroke: ${({ theme }) => theme.radioButtonColors.radioButtonColorBorder};
    }
    & > rect.inner {
      fill: ${({ theme }) => theme.radioButtonColors.radioButtonColorBorder};
    }
  }

  .ouRadioButtonUnchecked rect {
    stroke: ${({ theme }: PropsWithTheme<RadioLabelProps>) =>
      theme.radioButtonColors.radioButtonColorBorder};
  }

  &:hover:not(.Mui-disabled) {
    .ouRadioButtonUnchecked rect {
      fill: ${({ theme }: PropsWithTheme<RadioLabelProps>) =>
        theme.radioButtonColors.radioButtonColorHover};
    }
    .ouRadioButtonChecked rect.outer {
      fill: ${({ theme }: PropsWithTheme<RadioLabelProps>) =>
        theme.radioButtonColors.radioButtonColorHover};
    }
  }

  ${({ $focusVisible, theme }: PropsWithTheme<RadioLabelProps>) =>
    $focusVisible
      ? css`
          &:not(:hover):not(.Mui-disabled) {
            .ouRadioButtonUnchecked rect {
              fill: ${theme.radioButtonColors.radioButtonColorFocus};
            }
            .ouRadioButtonChecked rect.outer {
              fill: ${({ theme: innerTheme }: PropsWithTheme<RadioLabelProps>) =>
                innerTheme.radioButtonColors.radioButtonColorHover};
            }
          }
          &${createElementOutlineMixin({
            width: `${theme.spacing(0.25)}px`,
            color: theme.radioButtonColors.radioButtonColorBorder,
            radius: `${theme.shape.borderRadius}px`
          })}
        `
      : undefined}
`;
