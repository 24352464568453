import Drawer from '@material-ui/core/Drawer';
import styled, { css } from 'styled-components';
import { CHARCOAL_40, CHARCOAL_5, FOG_20 } from '../../colors';
import { ownUpWhiteTheme, PropsWithTheme } from '../../core';
import { OwnUpLogoLink, OwnUpLogoWithTextLink } from '../../logos';

interface ModalSectionProps {
  $footer?: boolean;
}

export const ModalSection = styled.div<ModalSectionProps>`
  padding: 0 ${ownUpWhiteTheme.spacing(10)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    padding: 0 ${ownUpWhiteTheme.spacing(6)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    padding: 0 ${ownUpWhiteTheme.spacing(3)}px;
    width: 100%;
  }
  ${({ $footer }: ModalSectionProps) => {
    if ($footer) {
      return css`
        margin-top: ${ownUpWhiteTheme.spacing(4)}px;
        background: ${FOG_20};
        height: 100%;
      `;
    }
  }}
`;

export const OwnUpNavModalHeader = styled.div<PropsWithTheme<{}>>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 4.5rem;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    height: 5rem;
  }
`;

export const ModalOwnUpLogoDesktopContainer = styled(OwnUpLogoLink)<PropsWithTheme<{}>>`
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const ModalOwnUpLogoMobileContainer = styled(OwnUpLogoWithTextLink)<PropsWithTheme<{}>>`
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    display: block;
  }
`;

export const ModalLinksContainer = styled.div`
  padding-top: ${ownUpWhiteTheme.spacing(4)}px;
  display: flex;
  flex-direction: column;
  gap: ${ownUpWhiteTheme.spacing(4)}px;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${ownUpWhiteTheme.spacing(4)}px;
  width: fit-content;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
  padding-bottom: 40px;
  > :last-child {
    margin-top: 12px;
  }
`;

export const OwnUpNavModalContainer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: ${CHARCOAL_40};
  }

  .MuiDrawer-paper {
    display: flex;
    width: 50%;
    min-width: 320px;
    box-shadow: -4px 4px 8px 0px ${CHARCOAL_5};
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
      width: 100%;
    }
  }

  .MuiLink-underlineHover {
    height: fit-content;
    padding: 0;
  }

  .modal-drawer-link {
    margin-top: ${ownUpWhiteTheme.spacing(3)}px;
  }
`;
