import { useMemo } from 'react';
import { useRandomId } from './use-random-id';

/**
 * Generates a random id with the input prefix unless an
 *   id is provided.
 *
 * Due to the nature of hooks, a randomized id will always
 *   be generated even if not used.
 *
 * @param givenId - id to use (if provided)
 * @param randomPrefix - prefix to use for randomized id
 *   (if id not provided)
 * @returns givenId or a random id
 */
export const useElementId = (givenId: string | undefined, randomPrefix: string) => {
  const randomizedId = useRandomId(randomPrefix);
  return useMemo(() => givenId || randomizedId, [givenId, randomizedId]);
};
