import React from 'react';
import * as colors from '../../../colors';

export const ChevronDownIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.22764 6.21182C2.52488 5.92333 2.9997 5.93041 3.28819 6.22764L10 13.1428L16.7118 6.22764C17.0003 5.93041 17.4751 5.92333 17.7724 6.21182C18.0696 6.5003 18.0767 6.97513 17.7882 7.27236L10.5382 14.7421C10.3969 14.8876 10.2028 14.9697 10 14.9697C9.79721 14.9697 9.60306 14.8876 9.46181 14.7421L2.21182 7.27236C1.92333 6.97513 1.93041 6.5003 2.22764 6.21182Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
