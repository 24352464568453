import React from 'react';
import * as colors from '../../colors';

export const SuccessDarkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" style={{ fill: colors.CACTUS_100 }} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2698 8.70938C17.5684 8.99647 17.5777 9.47125 17.2906 9.76983L11.0406 16.2698C10.901 16.4151 10.7088 16.498 10.5074 16.5C10.3059 16.5019 10.1121 16.4228 9.96967 16.2803L6.71967 13.0303C6.42678 12.7374 6.42678 12.2626 6.71967 11.9697C7.01256 11.6768 7.48744 11.6768 7.78033 11.9697L10.4895 14.6788L16.2094 8.73017C16.4965 8.43159 16.9713 8.42228 17.2698 8.70938Z"
      style={{ fill: colors.WHITE_100 }}
    />
  </svg>
);
