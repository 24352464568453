import styled from 'styled-components';
import { PropsWithTheme } from '..';
import { createOwnUpButtonThemeStyle, OwnUpButton } from './buttons-common';
import { OwnUpButtonProps } from './properties';
import { lineButtonStyle } from './styles';
import { LINE_BUTTON_VARIANT } from './theme';

const lineButtonThemeStyle = createOwnUpButtonThemeStyle(LINE_BUTTON_VARIANT);

export const OwnUpLineButton = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${lineButtonThemeStyle}
  ${lineButtonStyle}
`;
