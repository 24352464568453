import React from 'react';
import * as colors from '../colors';

export const OwnUpLogoSvg = () => (
  <svg width="36" height="48" viewBox="0 0 36 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Own Up</title>
    <path
      d="M3.10893 14.4926C2.7452 14.7571 2.53 15.1797 2.53 15.6295V24.7794C2.53 25.3538 3.18171 25.6857 3.64629 25.3478L17.9933 14.9136L32.3404 25.3478C32.805 25.6857 33.4567 25.3538 33.4567 24.7794V15.6295C33.4567 15.1797 33.2415 14.7571 32.8778 14.4926L18.8202 4.26887C18.3272 3.91038 17.6594 3.91038 17.1665 4.26887L3.10893 14.4926Z"
      style={{ fill: colors.ALOE_100 }}
    />
    <path
      d="M22.9135 29.6741V36H13.0732V29.6741C13.0732 26.9568 15.276 24.7539 17.9933 24.7539C20.7107 24.7539 22.9135 26.9568 22.9135 29.6741Z"
      style={{ fill: colors.ALOE_100 }}
    />
  </svg>
);
