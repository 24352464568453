import { Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useMemo } from 'react';
import { OwnUpThemeProvider } from '../core';
import { OwnUpDetail, OwnUpSmallBodyMedium } from '../typography';
import { OwnUpTooltipProps } from './properties';
import { TooltipTitle, useTooltipClasses } from './styles';

const OwnUpTooltipContent = ({
  tooltipTitle,
  description,
  theme
}: {
  tooltipTitle?: React.ReactNode;
  description: React.ReactNode;
  theme: Theme;
}) => (
  <OwnUpThemeProvider theme={theme}>
    {tooltipTitle ? (
      <TooltipTitle>
        <OwnUpSmallBodyMedium variant="body1">{tooltipTitle}</OwnUpSmallBodyMedium>
      </TooltipTitle>
    ) : null}
    <OwnUpDetail>{description}</OwnUpDetail>
  </OwnUpThemeProvider>
);

/**
 * When using this tooltip to wrap other components from the component library, you must first wrap
 *   the component in a div wrapper in order for the tooltip hover to trigger.
 */
export const OwnUpTooltip = ({
  tooltipTitle,
  description,
  themeMode,
  ...props
}: OwnUpTooltipProps) => {
  const [classes, theme] = useTooltipClasses(themeMode);
  const content = useMemo(
    () => (
      <OwnUpTooltipContent theme={theme} tooltipTitle={tooltipTitle} description={description} />
    ),
    [description, theme, tooltipTitle]
  );
  return (
    <Tooltip
      {...props}
      arrow
      classes={classes}
      title={content}
      enterTouchDelay={0}
      leaveTouchDelay={60000}
    />
  );
};
