import { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import {
  aktivGroteskBoldMixin,
  aktivGroteskItalicMixin,
  aktivGroteskMediumMixin,
  aktivGroteskRegularMixin
} from '../../fonts';

const boldMixin = css`
  & em,
  & b {
    ${aktivGroteskBoldMixin}
  }
`;

export const largeBodyMixin = css<PropsWithTheme<{}>>`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  ${boldMixin}
`;

export const bodyMixin = css`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;

  ${boldMixin}
`;

export const smallBodyMixin = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.01em;

  ${boldMixin}
`;

export const ownUpLargeBodyMixin = css`
  ${aktivGroteskRegularMixin}
  ${largeBodyMixin}
`;

export const ownUpLargeBodyMediumMixin = css`
  ${aktivGroteskMediumMixin}
  ${largeBodyMixin}
`;

export const ownUpBodyMixin = css`
  ${aktivGroteskRegularMixin}
  ${bodyMixin}
`;

export const ownUpBodyMediumMixin = css`
  ${aktivGroteskMediumMixin}
  ${bodyMixin}
`;

export const ownUpBodyItalicMixin = css`
  ${aktivGroteskItalicMixin}
  ${bodyMixin}
`;

export const ownUpSmallBodyMixin = css`
  ${aktivGroteskRegularMixin}
  ${smallBodyMixin}
`;

export const ownUpSmallBodyMediumMixin = css`
  ${aktivGroteskMediumMixin}
  ${smallBodyMixin}
`;

export const ownUpSmallBodyItalicMixin = css`
  ${aktivGroteskItalicMixin}
  ${smallBodyMixin}
`;
