import React from 'react';
import * as colors from '../../../colors';
import { ThemeNames } from '../../../core/theme/types';

export interface RadioButtonThemeTokens {
  radioButtonFontColorLabel: React.CSSProperties['color'];
  radioButtonColorBorder: React.CSSProperties['color'];
  radioButtonColorHover: React.CSSProperties['color'];
  radioButtonColorFocus: React.CSSProperties['color'];
  radioButtonColorSelected: React.CSSProperties['color'];
  radioButtonColorControl: React.CSSProperties['color'];
  radioButtonColorBackground: React.CSSProperties['color'];
}

type RadioButtonTheme = {
  [key in ThemeNames]: RadioButtonThemeTokens;
};

const lightColors: RadioButtonThemeTokens = {
  radioButtonFontColorLabel: colors.CHARCOAL_100,
  radioButtonColorBorder: colors.ALOE_100,
  radioButtonColorHover: colors.SAGE_100,
  radioButtonColorFocus: colors.SAGE_100,
  radioButtonColorSelected: colors.ALOE_100,
  radioButtonColorControl: colors.WHITE_100,
  radioButtonColorBackground: colors.WHITE_100
};

const darkColors: RadioButtonThemeTokens = {
  radioButtonFontColorLabel: colors.WHITE_100,
  radioButtonColorBorder: colors.WHITE_100,
  radioButtonColorHover: colors.WHITE_20,
  radioButtonColorFocus: colors.WHITE_20,
  radioButtonColorSelected: colors.WHITE_100,
  radioButtonColorControl: colors.CHARCOAL_100,
  radioButtonColorBackground: colors.WHITE_10
};

export const radioButtonTheme: RadioButtonTheme = {
  [ThemeNames.White]: { ...lightColors, radioButtonColorBackground: colors.FOG_20 },
  [ThemeNames.Light]: lightColors,
  [ThemeNames.Midtone]: lightColors,
  [ThemeNames.Dark]: darkColors
};
