import React from 'react';
import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export const FILL_BUTTON_PRIMARY_VARIANT = 'fillButtonPrimaryTheme';
export const FILL_BUTTON_PRIMARY_ACCENT_VARIANT = 'fillButtonPrimaryAccentTheme';
export const FILL_BUTTON_SECONDARY_VARIANT = 'fillButtonSecondaryTheme';
export const FILL_BUTTON_SECONDARY_ACCENT_VARIANT = 'fillButtonSecondaryAccentTheme';
export const ACTION_BUTTON_VARIANT = 'actionButtonTheme';
export const LINE_BUTTON_VARIANT = 'lineButtonTheme';
export const SOCIAL_BUTTON_VARIANT = 'socialButtonTheme';
export const FILL_BUTTON_VARIANTS = [
  FILL_BUTTON_PRIMARY_VARIANT,
  FILL_BUTTON_PRIMARY_ACCENT_VARIANT,
  FILL_BUTTON_SECONDARY_VARIANT,
  FILL_BUTTON_SECONDARY_ACCENT_VARIANT,
  ACTION_BUTTON_VARIANT,
  LINE_BUTTON_VARIANT,
  SOCIAL_BUTTON_VARIANT
] as const;
export type FillButtonVariants = (typeof FILL_BUTTON_VARIANTS)[number];

export const ICON_BUTTON_DEFAULT_VARIANT = 'defaultColors';
export const ICON_BUTTON_NEUTRAL_VARIANT = 'neutralColors';
export const ICON_BUTTON_ACCENT_VARIANT = 'accentColors';
export const ICON_BUTTON_QUIET_VARIANT = 'quietColors';
export const ICON_BUTTON_VARIANTS = [
  ICON_BUTTON_DEFAULT_VARIANT,
  ICON_BUTTON_NEUTRAL_VARIANT,
  ICON_BUTTON_ACCENT_VARIANT,
  ICON_BUTTON_QUIET_VARIANT
] as const;
export type IconButtonVariant = (typeof ICON_BUTTON_VARIANTS)[number];

export const SLIM_BUTTON_PRIMARY_VARIANT = 'slimButtonPrimaryTheme';
export const SLIM_BUTTON_SECONDARY_VARIANT = 'slimButtonSecondaryTheme';
export const SLIM_BUTTON_VARIANTS = [
  SLIM_BUTTON_PRIMARY_VARIANT,
  SLIM_BUTTON_SECONDARY_VARIANT
] as const;
export type SlimButtonVariant = (typeof SLIM_BUTTON_VARIANTS)[number];

export type IconButtonColorTokens = {
  radius: React.CSSProperties['borderRadius'];
  /**
   * Icon size for buttons at and above 2rem
   */
  iconSize: React.CSSProperties['height'];
  /**
   * Icon size for buttons under 2rem
   */
  iconSizeMicro: React.CSSProperties['height'];
  /**
   * Icon size for buttons at 1.125rem and less.
   */
  iconSizeMicroMicro: React.CSSProperties['height'];
  focusOutlineSizeMultiplier: number;
  /**
   * Focus border offset for buttons at and above 2.5rem
   */
  focusOutlineSpacingLargeMultiplier: number;
  /**
   * Focus border offset for buttons between 2rem and 1.5rem (inclusive)
   */
  focusOutlineSpacingMultiplier: number;
  /**
   * Focus border offset for buttons at and below 1.25rem.
   */
  focusOutlineSpacingMicroMultiplier: number;
} & Record<
  IconButtonVariant,
  | {
      color: React.CSSProperties['color'];
      backgroundColorActive: React.CSSProperties['color'];
      backgroundColorHover: React.CSSProperties['color'];
      backgroundColorFocus: React.CSSProperties['color'];
      focusOutlineColor: React.CSSProperties['color'];
    }
  | undefined
>;

export type SlimButtonColorTokens = Record<
  SlimButtonVariant,
  | {
      buttonFontColor: React.CSSProperties['color'];
      buttonIdleFontColor: React.CSSProperties['color'];
      buttonIconColor: React.CSSProperties['color'];
      buttonIdleIconColor: React.CSSProperties['color'];
      buttonBackgroundColorActive: React.CSSProperties['color'];
      buttonBackgroundColorHover: React.CSSProperties['color'];
      buttonBackgroundColorFocus: React.CSSProperties['color'];
      buttonIdleBackgroundColor: React.CSSProperties['color'];
      buttonFocusColor: React.CSSProperties['color'];
    }
  | undefined
>;

export type ButtonThemeTokens = { iconButtons: IconButtonColorTokens } & {
  slimButtons: SlimButtonColorTokens;
} & Record<
    FillButtonVariants,
    {
      buttonFontColor: React.CSSProperties['color'];
      buttonIconColor: React.CSSProperties['color'];
      buttonBackgroundColorActive: React.CSSProperties['color'];
      buttonBackgroundColorHover: React.CSSProperties['color'];
      buttonBackgroundColorFocus: React.CSSProperties['color'];
      buttonFocusColor: React.CSSProperties['color'];
      buttonFontColorHover: React.CSSProperties['color'];
      buttonFontColorFocus: React.CSSProperties['color'];
      buttonBorderColor: React.CSSProperties['color'];
    }
  >;

type ButtonTheme = Record<ThemeNames, ButtonThemeTokens>;

const socialColors = {
  buttonFontColor: colors.CHARCOAL_100,
  buttonBackgroundColorActive: colors.WHITE_100,
  buttonBorderColor: colors.FOG_60,
  buttonBackgroundColorHover: colors.FOG_60,
  buttonBackgroundColorFocus: colors.FOG_60,
  buttonFocusColor: colors.CHARCOAL_100,
  buttonIconColor: colors.CHARCOAL_100,
  buttonFontColorHover: colors.CHARCOAL_100,
  buttonFontColorFocus: colors.CHARCOAL_100
};

const commonColors: ButtonThemeTokens = {
  [FILL_BUTTON_PRIMARY_VARIANT]: {
    buttonFontColor: colors.WHITE_100,
    buttonIconColor: colors.CACTUS_100,
    buttonBackgroundColorActive: colors.ALOE_100,
    buttonBackgroundColorHover: colors.PINE_100,
    buttonBackgroundColorFocus: colors.PINE_100,
    buttonFocusColor: colors.WHITE_100,
    buttonFontColorHover: colors.CHARCOAL_100,
    buttonFontColorFocus: colors.CHARCOAL_100,
    buttonBorderColor: colors.MINT_100
  },
  [FILL_BUTTON_PRIMARY_ACCENT_VARIANT]: {
    buttonFontColor: colors.CHARCOAL_100,
    buttonIconColor: colors.CHARCOAL_100,
    buttonBackgroundColorActive: colors.MANGO_100,
    buttonBackgroundColorHover: colors.APRICOT_60,
    buttonBackgroundColorFocus: colors.APRICOT_60,
    buttonFocusColor: colors.CHARCOAL_100,
    buttonFontColorHover: colors.CHARCOAL_100,
    buttonFontColorFocus: colors.CHARCOAL_100,
    buttonBorderColor: colors.MINT_100
  },
  [ACTION_BUTTON_VARIANT]: {
    buttonFontColor: colors.WHITE_100,
    buttonIconColor: colors.CACTUS_100,
    buttonBackgroundColorActive: colors.ALOE_100,
    buttonBackgroundColorHover: colors.GRADIENT_ALOE_PINE,
    buttonBackgroundColorFocus: colors.GRADIENT_ALOE_PINE,
    buttonFocusColor: colors.WHITE_100,
    buttonFontColorHover: colors.CHARCOAL_100,
    buttonFontColorFocus: colors.CHARCOAL_100,
    buttonBorderColor: colors.MINT_100
  },
  [FILL_BUTTON_SECONDARY_VARIANT]: {
    buttonFontColor: colors.CHARCOAL_100,
    buttonIconColor: colors.ALOE_100,
    buttonBackgroundColorActive: colors.SAGE_100,
    buttonBackgroundColorHover: colors.MINT_100,
    buttonBackgroundColorFocus: colors.MINT_100,
    buttonFocusColor: colors.ALOE_100,
    buttonFontColorHover: colors.CHARCOAL_100,
    buttonFontColorFocus: colors.CHARCOAL_100,
    buttonBorderColor: colors.MINT_100
  },
  [FILL_BUTTON_SECONDARY_ACCENT_VARIANT]: {
    buttonFontColor: colors.CHARCOAL_100,
    buttonIconColor: colors.CHARCOAL_100,
    buttonBackgroundColorActive: colors.MANGO_60,
    buttonBackgroundColorHover: colors.APRICOT_40,
    buttonBackgroundColorFocus: colors.APRICOT_40,
    buttonFocusColor: colors.CHARCOAL_100,
    buttonFontColorHover: colors.CHARCOAL_100,
    buttonFontColorFocus: colors.CHARCOAL_100,
    buttonBorderColor: colors.MINT_100
  },
  [LINE_BUTTON_VARIANT]: {
    buttonIconColor: colors.CHARCOAL_100,
    buttonBackgroundColorHover: colors.APRICOT_40,
    buttonBackgroundColorFocus: colors.APRICOT_40,
    buttonFontColor: colors.CHARCOAL_100,
    buttonFontColorHover: colors.CHARCOAL_100,
    buttonBackgroundColorActive: colors.MINT_100,
    buttonBorderColor: colors.MINT_100,
    buttonFocusColor: colors.ALOE_100,
    buttonFontColorFocus: colors.CHARCOAL_100
  },
  [SOCIAL_BUTTON_VARIANT]: {
    ...socialColors
  },
  iconButtons: {
    radius: '50%',
    iconSize: '1.25rem',
    iconSizeMicro: '0.75rem',
    iconSizeMicroMicro: '0.625rem',
    focusOutlineSizeMultiplier: 0.25,
    focusOutlineSpacingMultiplier: 0,
    focusOutlineSpacingLargeMultiplier: 0.25,
    focusOutlineSpacingMicroMultiplier: -0.25,
    [ICON_BUTTON_DEFAULT_VARIANT]: {
      color: colors.ALOE_100,
      backgroundColorActive: colors.SAGE_100,
      backgroundColorHover: colors.MINT_100,
      backgroundColorFocus: colors.MINT_100,
      focusOutlineColor: colors.ALOE_100
    },
    [ICON_BUTTON_NEUTRAL_VARIANT]: {
      color: colors.SLATE_100,
      backgroundColorActive: colors.FOG_40,
      backgroundColorHover: colors.FOG_60,
      backgroundColorFocus: colors.FOG_60,
      focusOutlineColor: colors.SLATE_100
    },
    [ICON_BUTTON_QUIET_VARIANT]: {
      color: colors.ALOE_100,
      backgroundColorActive: colors.MOSS_50,
      backgroundColorHover: colors.MOSS_100,
      backgroundColorFocus: colors.MOSS_100,
      focusOutlineColor: colors.ALOE_100
    },
    [ICON_BUTTON_ACCENT_VARIANT]: undefined
  },
  slimButtons: {
    [SLIM_BUTTON_PRIMARY_VARIANT]: {
      buttonFontColor: colors.WHITE_100,
      buttonIdleFontColor: colors.CHARCOAL_100,
      buttonIconColor: colors.WHITE_100,
      buttonIdleIconColor: colors.CHARCOAL_100,
      buttonBackgroundColorActive: colors.ALOE_100,
      buttonBackgroundColorHover: colors.PINE_100,
      buttonBackgroundColorFocus: colors.PINE_100,
      buttonIdleBackgroundColor: colors.FOG_40,
      buttonFocusColor: colors.WHITE_100
    },
    [SLIM_BUTTON_SECONDARY_VARIANT]: {
      buttonFontColor: colors.CHARCOAL_100,
      buttonIdleFontColor: colors.CHARCOAL_100,
      buttonIconColor: colors.CHARCOAL_100,
      buttonIdleIconColor: colors.CHARCOAL_100,
      buttonBackgroundColorActive: colors.SAGE_100,
      buttonBackgroundColorHover: colors.MINT_100,
      buttonBackgroundColorFocus: colors.MINT_100,
      buttonIdleBackgroundColor: colors.FOG_40,
      buttonFocusColor: colors.ALOE_100
    }
  }
};

export const buttonTheme: ButtonTheme = {
  [ThemeNames.White]: {
    ...commonColors
  },
  [ThemeNames.Light]: {
    ...commonColors,
    iconButtons: {
      ...commonColors.iconButtons,
      [ICON_BUTTON_DEFAULT_VARIANT]: {
        color: colors.ALOE_100,
        backgroundColorActive: colors.SAGE_100,
        backgroundColorHover: colors.MINT_100,
        backgroundColorFocus: colors.MINT_100,
        focusOutlineColor: colors.ALOE_100
      },
      [ICON_BUTTON_NEUTRAL_VARIANT]: undefined,
      [ICON_BUTTON_QUIET_VARIANT]: undefined,
      [ICON_BUTTON_ACCENT_VARIANT]: undefined
    }
  },
  [ThemeNames.Midtone]: {
    ...commonColors,
    iconButtons: {
      ...commonColors.iconButtons,
      [ICON_BUTTON_DEFAULT_VARIANT]: {
        color: colors.ALOE_100,
        backgroundColorActive: colors.WHITE_50,
        backgroundColorHover: colors.WHITE_60,
        backgroundColorFocus: colors.WHITE_60,
        focusOutlineColor: colors.ALOE_100
      },
      [ICON_BUTTON_NEUTRAL_VARIANT]: undefined,
      [ICON_BUTTON_QUIET_VARIANT]: undefined,
      [ICON_BUTTON_ACCENT_VARIANT]: {
        color: colors.WHITE_100,
        backgroundColorActive: colors.ALOE_100,
        backgroundColorHover: colors.PINE_100,
        backgroundColorFocus: colors.PINE_100,
        focusOutlineColor: colors.WHITE_100
      }
    },
    [LINE_BUTTON_VARIANT]: {
      buttonFontColor: colors.CHARCOAL_100,
      buttonFontColorHover: colors.WHITE_100,
      buttonFontColorFocus: colors.WHITE_100,
      buttonBackgroundColorActive: colors.ALOE_100,
      buttonBorderColor: colors.ALOE_100,
      buttonFocusColor: colors.WHITE_100,
      buttonIconColor: colors.CHARCOAL_100,
      buttonBackgroundColorHover: colors.APRICOT_40,
      buttonBackgroundColorFocus: colors.APRICOT_40
    }
  },
  [ThemeNames.Dark]: {
    ...commonColors,
    [FILL_BUTTON_PRIMARY_VARIANT]: {
      ...commonColors[FILL_BUTTON_PRIMARY_VARIANT],
      buttonFontColor: colors.CHARCOAL_100,
      buttonIconColor: colors.ALOE_100,
      buttonBackgroundColorActive: colors.SAGE_100,
      buttonBackgroundColorHover: colors.MINT_100,
      buttonBackgroundColorFocus: colors.MINT_100,
      buttonFocusColor: colors.ALOE_100
    },
    [FILL_BUTTON_PRIMARY_ACCENT_VARIANT]: {
      ...commonColors[FILL_BUTTON_PRIMARY_ACCENT_VARIANT],
      buttonBackgroundColorActive: colors.MANGO_60,
      buttonBackgroundColorHover: colors.APRICOT_40,
      buttonBackgroundColorFocus: colors.APRICOT_40
    },
    [FILL_BUTTON_SECONDARY_VARIANT]: {
      ...commonColors[FILL_BUTTON_SECONDARY_VARIANT],
      buttonBackgroundColorActive: colors.MINT_80,
      buttonBackgroundColorHover: colors.CACTUS_80,
      buttonBackgroundColorFocus: colors.CACTUS_80
    },
    [FILL_BUTTON_SECONDARY_ACCENT_VARIANT]: {
      ...commonColors[FILL_BUTTON_SECONDARY_ACCENT_VARIANT],
      buttonBackgroundColorActive: colors.MANGO_100,
      buttonBackgroundColorHover: colors.APRICOT_60,
      buttonBackgroundColorFocus: colors.APRICOT_60
    },
    [ACTION_BUTTON_VARIANT]: {
      buttonFontColor: colors.CHARCOAL_100,
      buttonIconColor: colors.ALOE_100,
      buttonBackgroundColorActive: colors.CACTUS_100,
      buttonBackgroundColorHover: colors.GRADIENT_MINT_CACTUS,
      buttonBackgroundColorFocus: colors.GRADIENT_MINT_CACTUS,
      buttonFocusColor: colors.ALOE_100,
      buttonFontColorHover: colors.CHARCOAL_100,
      buttonFontColorFocus: colors.CHARCOAL_100,
      buttonBorderColor: colors.MINT_100
    },
    [LINE_BUTTON_VARIANT]: {
      buttonBackgroundColorActive: colors.MINT_100,
      buttonFontColor: colors.WHITE_100,
      buttonFontColorHover: colors.CHARCOAL_100,
      buttonFontColorFocus: colors.CHARCOAL_100,
      buttonBorderColor: colors.MINT_100,
      buttonFocusColor: colors.ALOE_100,
      buttonIconColor: colors.ALOE_100,
      buttonBackgroundColorHover: colors.MINT_100,
      buttonBackgroundColorFocus: colors.MINT_100
    },
    iconButtons: {
      ...commonColors.iconButtons,
      [ICON_BUTTON_DEFAULT_VARIANT]: {
        color: colors.WHITE_100,
        backgroundColorActive: colors.WHITE_10,
        backgroundColorHover: colors.WHITE_20,
        backgroundColorFocus: colors.WHITE_20,
        focusOutlineColor: colors.WHITE_100
      },
      [ICON_BUTTON_NEUTRAL_VARIANT]: undefined,
      [ICON_BUTTON_QUIET_VARIANT]: undefined,
      [ICON_BUTTON_ACCENT_VARIANT]: {
        color: colors.PINE_100,
        backgroundColorActive: colors.SAGE_100,
        backgroundColorHover: colors.MINT_100,
        backgroundColorFocus: colors.MINT_100,
        focusOutlineColor: colors.PINE_100
      }
    },
    [SOCIAL_BUTTON_VARIANT]: {
      ...commonColors,
      ...socialColors
    }
  }
};
