import { Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { ownUpDarkTheme, ownUpLightTheme, ownUpWhiteTheme } from '../core/theme';

/**
 * Find and return the appropriate theme for the child
 *   elements of an accordion.
 *
 * See: {@link https://zeroheight.com/7b3231350/p/02537d-fill-accordion/t/969ae5}
 *
 * @returns - selected theme
 */
export const useAccordionChildTheme = () => {
  const parentTheme = useTheme() as Theme;
  const childTheme = useMemo(() => {
    if (parentTheme.name === ownUpWhiteTheme.name) {
      return ownUpLightTheme;
    } else if (parentTheme.name === ownUpDarkTheme.name) {
      return ownUpDarkTheme;
    }
    return ownUpWhiteTheme;
  }, [parentTheme]);
  return childTheme;
};

/**
 * @notice helper function for building ownUpWhiteTheme spacing in px.
 * @param spacingNum is the specification for the spacing (as per design) to be converted to px.
 * @returns ownUpWhiteTheme's spacing in px.
 */
export const whiteThemeSpacingBuilderPX = (spacingNum: number) =>
  `${ownUpWhiteTheme.spacing(spacingNum)}px`;
