import styled from 'styled-components';
import { OwnUpGridItem } from '../item';

export const StyledGridBreak = styled(OwnUpGridItem)`
  /* HACK-ish: This className used to ensure it can override
   *   Mui's paddings applied by classes appended at render time. */
  &.break {
    margin: 0;
    flex: 1;
    flex-basis: 100%;
    padding: 0;
  }
`;
