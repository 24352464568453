import { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { ownUpBodyMixin } from '../../typography/body';

/**
 * Mixin for styles common to checkboxes and radio buttons
 */
export const commonIconInputsMixin = css<{ width?: React.CSSProperties['width'] }>`
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.shape.borderRadius}px;
  margin: 0;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5)}px;
  width: ${({ width }) => width};
  min-width: 160px;

  .MuiTypography-root {
    font-size: unset;
  }

  .MuiIconButton-root {
    padding: 0;
    align-self: start;
  }

  .MuiFormControlLabel-label {
    ${ownUpBodyMixin};
    padding-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  }
`;
