import React, { useCallback } from 'react';
import { preventEventPropagation } from '../hooks/prevent-event-propagation';
import { OwnUpUnderlineTooltipTriggerProps } from './properties';
import { TextStyle } from './styles';

/**
 * This component should be wrapped in the OwnUpTooltip component, in order for the tooltip
 *   to trigger.
 */
// eslint-disable-next-line react/display-name
export const OwnUpUnderlineTooltipTrigger = React.forwardRef<
  HTMLElement,
  OwnUpUnderlineTooltipTriggerProps
>((props: OwnUpUnderlineTooltipTriggerProps, ref) => {
  const handleOnMouseDown = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    preventEventPropagation(event);
  }, []);
  return (
    <TextStyle onMouseDown={handleOnMouseDown} tabIndex={0} ref={ref} {...props}>
      {props.children}
    </TextStyle>
  );
});
