import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../core/theme/properties';
import { useCombinedClassName } from '../hooks/use-combined-class-name';
import { OwnUpIconProps } from './properties';

/**
 * Span wrapper for the icon
 */
const OwnUpStyledIcon = styled.span<PropsWithTheme<Partial<OwnUpIconProps>>>`
  display: flex;
  & svg {
    width: ${({ height }) => (height ? height : '1.25rem')};
    height: ${({ height }) => (height ? height : '1.25rem')};
  }
  & path {
    /* Use the theme text color if not provided */
    fill: ${({ color, theme }: PropsWithTheme<Partial<OwnUpIconProps>>) =>
      color || theme.palette.text.primary};
  }
`;

/**
 * Render a span that contains a defined icon.
 *
 * @param props See {@link OwnUpIconProps}
 */
export const OwnUpIcon = ({ className, icon: Icon, spanRef, ...props }: OwnUpIconProps) => {
  // Icon could be a functional component or a simple JSX element.
  //   Use this helper function to handle either.
  const renderedIcon = useMemo(() => (typeof Icon === 'function' ? <Icon /> : Icon), [Icon]);
  return (
    <OwnUpStyledIcon
      ref={spanRef}
      {...props}
      className={useCombinedClassName(className, 'ou-icon')}
    >
      {renderedIcon}
    </OwnUpStyledIcon>
  );
};
