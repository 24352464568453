/* eslint-disable @typescript-eslint/naming-convention */
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { useCombinedClassName } from '../../hooks/use-combined-class-name';
import { ErrorDarkIcon } from '../../icon-library/notification-icons/error-dark';
import { ErrorLightIcon } from '../../icon-library/notification-icons/error-light';
import { OwnUpIcon } from '../../icons';
import { OwnUpDetail } from '../../typography';
import { StandaloneErrorProps } from './properties';

export const StandaloneErrorImpl = ({
  className,
  detailProps,
  children,
  ...props
}: StandaloneErrorProps) => {
  const fullClassName = useCombinedClassName('standalone-error', className);
  const currentTheme = useTheme() as Theme;
  return (
    <div className={fullClassName} {...props}>
      <OwnUpIcon
        height="1.5rem"
        icon={currentTheme.name === 'own-up-midtone' ? ErrorDarkIcon : ErrorLightIcon}
      />
      <OwnUpDetail variant="body2" {...detailProps}>
        {children}
      </OwnUpDetail>
    </div>
  );
};

/**
 * A standalone error is a single block of error copy that is
 *   mainly meant to be inserted as part of a Checkbox or
 *   Radio Group component, alerting of the error.
 */
export const StandaloneError = styled(StandaloneErrorImpl)<PropsWithTheme<StandaloneErrorProps>>`
  color: ${({ theme }: PropsWithTheme<StandaloneErrorProps>) =>
    theme.errorContainerColors.inputFontColorError};
  display: flex;
  align-items: center;

  margin: ${({ theme }: PropsWithTheme<{}>) =>
    `0 0 ${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`};

  & svg {
    /* 16px to the right of the icon */
    margin-right: ${({ theme }: PropsWithTheme<StandaloneErrorProps>) => `${theme.spacing(2)}px`};
  }
  ${({ addedErrorStyle }: StandaloneErrorProps) => addedErrorStyle};
`;
