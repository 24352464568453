import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import styled from 'styled-components';
import { ownUpWhiteTheme, PropsWithTheme } from '../core';
import { OwnUpThemeProvider } from '../core/provider';
import { OwnUpGridContainer } from '../grid';
import { OwnUpNavBarProps } from './properties';
import { navBarBaseStyle, NavBarGridWrapper } from './styles/nav-bar';

export const OwnUpNavBarImpl = styled(AppBar)`
  ${navBarBaseStyle}
  height: 4.5rem;
  ${({ theme }: PropsWithTheme<OwnUpNavBarProps>) => theme.breakpoints.up('lg')} {
    height: 5rem;
  }
`;

/**
 * A nav bar element. This bar provides its own {@see OwnUpGridWrapper} and
 *   {@see OwnUpGridContainer}. The immediate children should be
 *   {@see OwnUpGridItem}s. If you don't want to use them, you should
 *   wrap the children in `<OwnUpGridItem xs={12} />` to satisfy the
 *   grid structure requirements.
 *
 * @param props - {@see OwnUpNavbarProps}
 */
export const OwnUpNavBar = ({ children, ...props }: OwnUpNavBarProps) => (
  <OwnUpNavBarImpl {...props}>
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <NavBarGridWrapper>
        <OwnUpGridContainer variant="slim">{children}</OwnUpGridContainer>
      </NavBarGridWrapper>
    </OwnUpThemeProvider>
  </OwnUpNavBarImpl>
);
