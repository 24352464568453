import Button from '@material-ui/core/Button';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { createElementOutlineMixin } from '../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { stickyHoverMixin } from '../core/theme/mixins/sticky-hover';
import { PropsWithTheme } from '../core/theme/properties';
import { OwnUpTooltip } from '../tooltips/tooltip';
import { OwnUpButtonProps } from './properties';
import {
  baseButtonStyle,
  buttonFocusInset,
  buttonFocusRadius,
  buttonFocusWidth,
  IconStyle,
  TextStyle
} from './styles';
import { FillButtonVariants } from './theme';

interface TooltipWrapperDivProps extends Omit<OwnUpButtonProps, 'children'> {
  buttonChildren: OwnUpButtonProps['children'];
}

/**
 * Any inactive buttons with tooltips need to be wrapped in a div so the
 *   tooltip has something to anchor itself to.
 *
 * Because the button stretches itself to take up the whole width of
 *   the parent container, and this is now the parent container, we
 *   need to also expand this container in those scenarios.
 *
 * See also: {@link https://v4.mui.com/components/tooltips/#disabled-elements}
 */
const TooltipWrapperDiv = styled.div<PropsWithTheme<TooltipWrapperDivProps>>`
  display: inline-block;
  ${({ theme }: PropsWithTheme<TooltipWrapperDivProps>) => theme.breakpoints.down('sm')} {
    width: ${({ icon, buttonChildren }: PropsWithTheme<TooltipWrapperDivProps>) =>
      icon && !buttonChildren ? '' : '100%'};
  }
`;

/**
 * Icons and the text have different spacing depending on what is present.
 * material-ui adds another span under the parent Button, so need to add
 * the icons and text under a common element to determine which one gets
 * the proper spacing value.
 *
 * @param icon - (optional) The svg icon element
 * @param iconPosition - (optional) 'left' or 'right' to indicate icon's position
 * @param disabledReason - (optional) message to show in the disabled
 *    tooltip when disabled
 */
export const OwnUpButton = ({
  icon,
  iconPosition,
  children,
  disabledReason,
  ...props
}: OwnUpButtonProps) => {
  // if there is an onClick handler and the button is inactive, prevent action
  const onClick = useMemo(
    () =>
      !!props.onClick && props.disabled
        ? () => {
            return;
          }
        : props.onClick,
    [props]
  );
  const buttonComponent = useMemo(
    () => (
      <Button
        {...{ ...props, onClick }}
        disabled={false}
        aria-disabled={props.disabled}
        aria-describedby="disabledReason"
      >
        <span>
          {icon && iconPosition !== 'right' && <IconStyle icon={icon}>{icon}</IconStyle>}
          {children && <TextStyle variant="body1">{children}</TextStyle>}
          {icon && iconPosition === 'right' && <IconStyle icon={icon}>{icon}</IconStyle>}
        </span>
      </Button>
    ),
    [children, icon, iconPosition, props, onClick]
  );
  const renderedComponent = useMemo(
    () =>
      props.disabled && disabledReason ? (
        <OwnUpTooltip description={disabledReason} id="disabledReason">
          <TooltipWrapperDiv icon={icon} buttonChildren={children}>
            {buttonComponent}
          </TooltipWrapperDiv>
        </OwnUpTooltip>
      ) : (
        buttonComponent
      ),
    [buttonComponent, children, disabledReason, icon, props.disabled]
  );
  return renderedComponent;
};

/**
 * Constructs the css for the specified button theme style
 * @param themeStyle e.g. 'fillButtonPrimaryTheme'
 */
export const createOwnUpButtonThemeStyle = (themeStyle: FillButtonVariants) => css<
  PropsWithTheme<OwnUpButtonProps>
>`
  ${baseButtonStyle}
  color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    theme.buttonColors[themeStyle].buttonFontColor};
  background: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    theme.buttonColors[themeStyle].buttonBackgroundColorActive};

  &[aria-disabled='true'] {
    color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
      theme.buttonColors[themeStyle].buttonFontColor};
    cursor: default;
  }
  ${stickyHoverMixin} {
    &:hover:not(&[aria-disabled='true']) {
      background: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
        theme.buttonColors[themeStyle].buttonBackgroundColorHover};
    }
  }
  &${focusVisibleSelectorMixin} {
    background: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
      theme.buttonColors[themeStyle].buttonBackgroundColorFocus};
    ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
      createElementOutlineMixin({
        color: theme.buttonColors[themeStyle].buttonFocusColor,
        width: buttonFocusWidth,
        inset: buttonFocusInset,
        radius: buttonFocusRadius
      })}
  }

  svg {
    path {
      fill: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
        theme.buttonColors[themeStyle].buttonIconColor};
    }
  }

  /* icons with text should fill width for mobile.  Icon only should always be square */
  ${({ theme }: PropsWithTheme<OwnUpButtonProps>) => theme.breakpoints.down('xs')} {
    width: ${({ icon, children }: PropsWithTheme<OwnUpButtonProps>) =>
      icon && !children ? '' : '100%'};
  }
`;
