import React from 'react';
import { useSanitizedUrl } from '../hooks/use-sanitized-url';
import { OwnUpIcon } from '../icons/component';
import { OwnUpBodyMedium, OwnUpLargeBodyMedium, OwnUpSmallBodyMedium } from '../typography';
import { StyledIconLinkProps } from './properties';
import { StyledIconLink } from './styles';

/**
 *
 * @param link - The link url. Refers to the href location for this link.
 * @param wrapperRef - (optional) ref element to access <IconLinkWrapper>
 * @param styledLinkRef - (optional) ref element to access <StyledLink>
 * @param themeName - (optional) The theme name. Refers to the background color behind the input. Values supported are 'white', 'light', 'midtone' or 'dark'
 * @param iconPlacement - (optional) Refers to the location of the arrow icon. Values supported are 'left' and 'right'.
 * @param size - (optional) Refers to the size of the icon font and the arrow height. Values supported are 'small', 'default', and 'large'
 */
export const IconLink = ({
  link,
  iconPlacement = 'right',
  icon,
  size = 'default',
  children,
  ...props
}: StyledIconLinkProps) => (
  <StyledIconLink
    {...props}
    iconPlacement={iconPlacement}
    size={size}
    href={useSanitizedUrl(link)}
    underline="none"
  >
    {iconPlacement === 'left' ? <OwnUpIcon icon={icon} /> : null}
    {size === 'small' && <OwnUpSmallBodyMedium variant="body2">{children}</OwnUpSmallBodyMedium>}
    {size === 'large' && <OwnUpLargeBodyMedium variant="body2">{children}</OwnUpLargeBodyMedium>}
    {size === 'default' && <OwnUpBodyMedium variant="body2">{children}</OwnUpBodyMedium>}
    {iconPlacement === 'right' ? <OwnUpIcon icon={icon} /> : null}
  </StyledIconLink>
);
