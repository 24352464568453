/**
 * List of Z-Indices used throughout the project.
 *
 * Numbers are somewhat arbitrary, so long as everyone
 *   is using these constants to ensure their order
 *   relative to other components in the library.
 */
export const Z_INDICES = {
  BASE: 0,
  FORM_LABELS: 10,
  FORM_ICONS: 15,
  DROPDOWN_OPTIONS: 20,
  NAVBAR_BASE: 50,
  NAVBAR_DRAWER: 55
};
