import React from 'react';
import * as colors from '../../../colors';

export const ChevronUpIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7724 14.7579C17.4751 15.0464 17.0003 15.0393 16.7118 14.7421L10 7.82685L3.28818 14.7421C2.99969 15.0393 2.52487 15.0464 2.22764 14.7579C1.93041 14.4694 1.92332 13.9946 2.21181 13.6973L9.46181 6.22764C9.60306 6.08212 9.7972 6 10 6C10.2028 6 10.3969 6.08212 10.5382 6.22764L17.7882 13.6973C18.0767 13.9946 18.0696 14.4694 17.7724 14.7579Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
