import React from 'react';
import * as colors from '../../../colors';

export const MicroQuestionIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.25 3.53869C4.25 2.8037 4.91888 2 6.00157 2C7.08298 2 7.75 2.80247 7.75 3.53869C7.75 4.24847 7.3667 4.67735 6.75068 5.22853C6.71118 5.26388 6.6698 5.3005 6.62708 5.33831C6.36441 5.57078 6.05077 5.84837 5.80633 6.15387C5.50157 6.53476 5.25157 7.01783 5.25157 7.63704C5.25157 8.05126 5.58735 8.38704 6.00157 8.38704C6.41578 8.38704 6.75157 8.05126 6.75157 7.63704C6.75157 7.45788 6.81387 7.29559 6.97756 7.091C7.13496 6.89428 7.33806 6.71371 7.60722 6.4744C7.65305 6.43366 7.70113 6.3909 7.75088 6.34639C8.38408 5.77984 9.25 4.95792 9.25 3.53869C9.25 1.92327 7.85971 0.5 6.00157 0.5C4.1447 0.5 2.75 1.92204 2.75 3.53869C2.75 3.95291 3.08579 4.28869 3.5 4.28869C3.91421 4.28869 4.25 3.95291 4.25 3.53869Z"
        fill={colors.CHARCOAL_100}
      />
      <path
        d="M6.00002 9.79999C5.53058 9.79999 5.15002 10.1805 5.15002 10.65C5.15002 11.1194 5.53058 11.5 6.00002 11.5C6.46947 11.5 6.85002 11.1194 6.85002 10.65C6.85002 10.1805 6.46947 9.79999 6.00002 9.79999Z"
        fill={colors.CHARCOAL_100}
      />
    </svg>
  );
};
