import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../core';
import { createElementOutlineMixin } from '../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { stickyHoverMixin } from '../core/theme/mixins/sticky-hover';
import { OwnUpButton } from './buttons-common';
import { OwnUpButtonProps } from './properties';
import {
  baseButtonStyle,
  buttonFocusInset,
  buttonFocusRadius,
  buttonFocusWidth,
  slimIconStyle,
  slimTextStyle
} from './styles';
import {
  SLIM_BUTTON_PRIMARY_VARIANT,
  SLIM_BUTTON_SECONDARY_VARIANT,
  SlimButtonVariant
} from './theme';

const iconSize = '1rem';
const backgroundRadius = '8px';

/**
 * Helper function to generate the various color schemes for
 *   the slim buttons, as well as base styling.
 */
export const createStyledSlimButton = (variant: SlimButtonVariant) => styled(OwnUpButton)<
  PropsWithTheme<OwnUpButtonProps>
>`
  ${baseButtonStyle}
  ${slimTextStyle}
    ${slimIconStyle}
    ${({
    $idle,
    theme: {
      buttonColors: { slimButtons }
    }
  }: PropsWithTheme<OwnUpButtonProps>) => {
    const colorScheme = slimButtons[variant]!;
    return css`
      padding: 0;
      border-radius: ${backgroundRadius};
      color: ${$idle ? colorScheme.buttonIdleFontColor : colorScheme.buttonFontColor};
      background: ${$idle
        ? colorScheme.buttonIdleBackgroundColor
        : colorScheme.buttonBackgroundColorActive};
      &[aria-disabled='true'] {
        color: ${$idle ? colorScheme.buttonIdleFontColor : colorScheme.buttonFontColor};
        cursor: default;
      }
      ${stickyHoverMixin} {
        &:hover:not(&[aria-disabled='true']) {
          background-color: ${colorScheme.buttonBackgroundColorHover};
          color: ${colorScheme.buttonFontColor};
        }
      }
      &${focusVisibleSelectorMixin} {
        background: ${colorScheme.buttonBackgroundColorFocus};
        color: ${colorScheme.buttonFontColor};
        ${({
          theme: {
            buttonColors: { slimButtons: innerSlimButtons }
          }
        }: PropsWithTheme<OwnUpButtonProps>) =>
          createElementOutlineMixin({
            color: innerSlimButtons[variant]!.buttonFocusColor,
            width: buttonFocusWidth,
            inset: buttonFocusInset,
            radius: buttonFocusRadius
          })}
      }
      svg {
        width: ${iconSize};
        height: ${iconSize};
        path {
          fill: ${$idle ? colorScheme.buttonIdleIconColor : colorScheme.buttonIconColor};
        }
      }
    `;
  }}
`;

/**
 * Primary Own Up Slim Button. Wraps {@see OwnUpButton}
 *
 * @param {@see OwnUpButtonProps}
 */
export const OwnUpSlimButtonPrimary = createStyledSlimButton(SLIM_BUTTON_PRIMARY_VARIANT);

/**
 * Secondary Own Up Slim Button. Wraps {@see OwnUpButton}
 *
 * @param {@see OwnUpButtonProps}
 */
export const OwnUpSlimButtonSecondary = createStyledSlimButton(SLIM_BUTTON_SECONDARY_VARIANT);
