import Card, { CardProps } from '@material-ui/core/Card';
import CardActions, { CardActionsProps } from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import styled, { css } from 'styled-components';
import { ownUpWhiteTheme, PropsWithTheme } from '../core/theme';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { OwnUpIcon, OwnUpIconProps } from '../icons';
import { IconLink } from '../links/icon-link';
import { OwnUpBody, OwnUpBodyMedium, OwnUpBodyProps, OwnUpSmallBody } from '../typography';
import { OwnUpNotificationProps } from './properties';
import { NotificationTypes, NotificationVariants } from './theme';

const notificationBackgroundRadius = '4px';
const notificationBackgroundFillRadius = '6px';

// Border
const notificationBorder = '1px';
const notificationBackgroundFill = '6px';
// Spacing
const notificationFontSpacing = ownUpWhiteTheme.spacing(1) + 'px';
export const notificationBackgroundSpacing = ownUpWhiteTheme.spacing(3) + 'px';
const notificationIconSpacing = ownUpWhiteTheme.spacing(2) + 'px';
const linkSpacingGroup = ownUpWhiteTheme.spacing(1) + 'px';

const notificationIconSize = '1.5rem';

// Wrapper div for the notification elements
export const StyledNotification = styled.div<PropsWithTheme<OwnUpNotificationProps>>`
  .root: {
    min-width: 200px;
    width: 100%;
  }
`;

interface CommonNotificationProps {
  variation: NotificationVariants;
  type: NotificationTypes;
}

// Card container for the notifications
interface StyledCardProps
  extends Omit<CardProps, 'title'>,
    CommonNotificationProps,
    OwnUpNotificationProps {}

export const StyledCard = styled(Card)<PropsWithTheme<StyledCardProps>>`
  display: flex;
  flex-direction: column-reverse;
  border-radius: ${notificationBackgroundFillRadius} ${notificationBackgroundRadius}
    ${notificationBackgroundRadius} ${notificationBackgroundFillRadius};
  ${(props: PropsWithTheme<StyledCardProps>) => {
    const colorScheme = props.theme.notificationColors[props.variation][props.type];
    return css`
      background-color: ${colorScheme.notificationBackgroundColor};
      box-shadow: ${
        props.variation === 'default'
          ? `4.0px 4.0px 8.0px 0px ${props.theme.notificationColors.notificationShadow},
       inset 0px ${notificationBorder} ${colorScheme.notificationBorderColor}, 
       inset -${notificationBorder} -${notificationBorder} ${colorScheme.notificationBorderColor}`
          : `inset 0px ${notificationBorder} ${colorScheme.notificationBorderColor},
       inset -${notificationBorder} -${notificationBorder} ${colorScheme.notificationBorderColor}`
      };
      &.MuiCard-root {
        border-left: ${notificationBackgroundFill} solid
          ${colorScheme.notificationBackgroundFillColor};
      }
      &${focusVisibleSelectorMixin} {
        /*
        * using createElementOutlineMixin here leads to a focus border inside the
        * border-left fill, instead of outside as required. 
        */
        outline: 2px solid ${props.theme.notificationColors.notificationBorderFocusColor};
        border-radius: 2px;        
    `;
  }}
`;

interface StyledCardActionsProps extends Omit<CardActionsProps, 'title'> {
  $hasCloseIcon?: boolean;
}
export const StyledCardActions = styled(CardActions)<PropsWithTheme<StyledCardActionsProps>>`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  margin-top: ${notificationBackgroundSpacing};
  margin-right: ${notificationBackgroundSpacing};
  justify-content: ${({ $hasCloseIcon }: PropsWithTheme<StyledCardActionsProps>) =>
    $hasCloseIcon ? 'space-between' : undefined};
  &.MuiCardActions-spacing > :not(:first-child) {
    margin-left: ${notificationIconSpacing};
  }
`;

export const TitleAndDescriptionDiv = styled.div<PropsWithTheme<OwnUpNotificationProps>>`
  display: flex;
  flex-direction: column;
  color: ${({ theme }: PropsWithTheme<OwnUpNotificationProps>) =>
    theme.notificationColors.notificationFontColor};
  margin-left: ${notificationBackgroundSpacing};
`;

// Card content for optional links below the description
interface StyledCardContentProps extends CardProps {
  $hasIcon?: boolean;
}
export const StyledCardContent = styled(CardContent)<PropsWithTheme<StyledCardContentProps>>`
  padding: ${linkSpacingGroup} ${notificationBackgroundSpacing} ${notificationBackgroundSpacing}
    ${({ $hasIcon }: PropsWithTheme<StyledCardContentProps>) => {
      return $hasIcon ? '64px' : notificationBackgroundSpacing;
    }};
`;

// Icons
interface StyledNotificationIconProps extends OwnUpIconProps, CommonNotificationProps {}
export const OwnUpNotificationIcon = styled(OwnUpIcon)<PropsWithTheme<StyledNotificationIconProps>>`
  svg {
    width: ${notificationIconSize};
    height: ${notificationIconSize};
  }
`;

// Font
export const NotificationDescriptionStyle = OwnUpBody;
export const NotificationDescriptionSmallStyle = styled(OwnUpSmallBody)<OwnUpBodyProps>`
  margin-top: ${notificationFontSpacing};
`;
export const NotificationTitleStyle = OwnUpBodyMedium;

// Link content
const notificationLinkStyles = css`
  &:not(:first-child) p {
    padding-top: ${linkSpacingGroup};
  }
  &:not(:first-child) span {
    padding-top: ${linkSpacingGroup};
  }
`;

// A variant of the IconLink with zero horizontal padding around the href a tag,
// and some padding on top.
// Necessary for the styling specifications of icon links to be used in notifications.
export const NotificationIconLink = styled(IconLink)`
  ${notificationLinkStyles}
`;
