import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../../core/theme';
import { OwnUpRadioGroupProps } from './properties';

const OwnUpRadioGroupImpl = (props: OwnUpRadioGroupProps) => <RadioGroup {...props} />;

/**
 * Container for many radio buttons where only one can be
 *   selected at a time.
 *
 */
export const OwnUpRadioGroup = styled(OwnUpRadioGroupImpl)<OwnUpRadioGroupProps>`
  row-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;
  column-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;
`;
