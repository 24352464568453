import { debounce } from '@material-ui/core/utils';
import React, { useState } from 'react';
import { useWrappedFunction } from '../hooks/use-wrapped-function';
import { MicroChevronDownIcon } from '../icon-library/system-icons/micro-icons/chevron-down';
import { MicroChevronUpIcon } from '../icon-library/system-icons/micro-icons/chevron-up';
import {
  OwnUpNavMenuDrawer,
  OwnUpNavMenuDrawerItemDesktop,
  OwnUpNavMenuItemModal
} from './nav-menu-drawer';
import { OwnUpNavButtonProps } from './properties';
import {
  NavButtonIconStyle,
  NavMenuDrawerContainer,
  OwnUpNavButtonContainer,
  OwnUpNavButtonWrapper
} from './styles';

const OwnUpNavButton = ({
  drawerItems,
  defaultShowDrawer = false,
  onModal = false,
  children,
  onFocus,
  onClick,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  className,
  ...props
}: OwnUpNavButtonProps) => {
  const [showDrawer, setShowDrawer] = useState(defaultShowDrawer);
  // There is a race condition where clicking on the element also focuses the element,
  // and toggle gets called twice.  Need to debounce the onFocus and onClick events.
  const debounceToggle = debounce(setShowDrawer, 150);
  const handleFocus = useWrappedFunction<[React.FocusEvent<HTMLDivElement>], void>(() => {
    debounceToggle(true);
  }, onFocus);
  const handleClick = useWrappedFunction<[React.MouseEvent<HTMLAnchorElement, MouseEvent>], void>(
    () => debounceToggle(!showDrawer),
    onClick
  );
  const handleBlur = useWrappedFunction<[React.FocusEvent<HTMLElement>], void>(
    ({ relatedTarget, currentTarget }: React.FocusEvent<HTMLElement>) => {
      if (relatedTarget !== null && currentTarget !== null) {
        if (currentTarget.contains(relatedTarget as Node)) {
          return;
        }
      }
      setShowDrawer(false);
    },
    onBlur
  );
  const handleMouseOver = useWrappedFunction<[React.MouseEvent<HTMLDivElement, MouseEvent>], void>(
    () => setShowDrawer(true),
    onMouseEnter
  );
  const handleMouseLeave = useWrappedFunction<[React.MouseEvent<HTMLDivElement, MouseEvent>], void>(
    () => setShowDrawer(false),
    onMouseLeave
  );

  const drawerItemsElement = onModal
    ? drawerItems.map(({ title, link, linkProps }) => (
        <OwnUpNavMenuItemModal title={title} link={link} linkProps={linkProps} key={title} />
      ))
    : drawerItems.map(({ title, description, linkProps, link, linkText }) => (
        <OwnUpNavMenuDrawerItemDesktop
          title={title}
          description={description}
          link={link}
          linkProps={linkProps}
          key={title}
          linkText={linkText}
        />
      ));

  return (
    <OwnUpNavButtonContainer
      onMouseOver={!onModal ? handleMouseOver : undefined}
      onMouseLeave={!onModal ? handleMouseLeave : undefined}
      onFocus={handleFocus}
      onBlur={handleBlur}
      $onModal={onModal}
      className={className}
    >
      <OwnUpNavButtonWrapper tabIndex={0} onClick={handleClick} $showDrawer={showDrawer} {...props}>
        {children}
        <NavButtonIconStyle
          icon={showDrawer ? MicroChevronUpIcon : MicroChevronDownIcon}
          size={props.size === 'large' ? 1.5 : 1.25}
          name={props.name}
          tabIndex={-1} // Only the parent element should be focused, not the icon alone
        />
      </OwnUpNavButtonWrapper>
      <NavMenuDrawerContainer showDrawer={showDrawer}>
        <OwnUpNavMenuDrawer onModal={onModal}>{drawerItemsElement}</OwnUpNavMenuDrawer>
      </NavMenuDrawerContainer>
    </OwnUpNavButtonContainer>
  );
};

/**
 * Only used with a full width NavBar
 */
export const OwnUpNavButtonSmall = ({ ...props }: Omit<OwnUpNavButtonProps, 'size'>) => (
  <OwnUpNavButton size="small" {...props} />
);

/**
 * Only used with NavBarModal
 */
export const OwnUpNavButtonMedium = ({ ...props }: Omit<OwnUpNavButtonProps, 'size'>) => (
  <OwnUpNavButton size="medium" {...props} />
);

/**
 * Only used with NavBarModal
 */
export const OwnUpNavButtonLarge = ({ ...props }: Omit<OwnUpNavButtonProps, 'size'>) => (
  <OwnUpNavButton size="large" {...props} />
);
