import React from 'react';
import { OwnUpNotification } from './element';
import { OwnUpNotificationProps } from './properties';

export const OwnUpDefaultErrorNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="default" type="error" />;
};

export const OwnUpDefaultWarningNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="default" type="warning" />;
};

export const OwnUpDefaultInfoNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="default" type="info" />;
};

export const OwnUpDefaultSuccessNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="default" type="success" />;
};
