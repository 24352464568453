import MuiRadioButton from '@material-ui/core/Radio';
import React, { useMemo } from 'react';
import { OwnUpTooltip } from '../../../tooltips/tooltip';
import { OwnUpRadioButtonProps } from '.';
import { RadioButtonIconChecked, RadioButtonIconUnchecked } from './icons';
import { StyledRadioLabel } from './styles';

/**
 * Renders a checkbox.
 *
 * @remarks
 * This component leverages material-ui's Checkbox component {@link https://mui.com/components/checkboxes/}
 *
 * @param id - (optional) The element id
 * @param disabled - (optional) Boolean indicating whether the input should be in a disabled state
 * @param disabledReason - (optional) Message to display in a tooltip when the input is disabled
 * @param labelRef - (optional) ref to access <FormControlLabel>
 * @param width - (optional) CSS width value for the component
 * @returns A styled checkbox component
 *
 */
export const OwnUpRadioButton = ({
  children,
  width,
  disabledReason,
  labelRef,
  ...props
}: OwnUpRadioButtonProps) => {
  const [focusVisible, setFocusVisible] = React.useState(false);

  // Mimics disabled state, but still allows keyboard focus
  const inactiveProps = useMemo(
    () => ({
      onChange: undefined, // Prevent field from being toggled
      disabled: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'aria-disabled': true,
      className: 'Mui-disabled', // easiest way to maintain disabled styling
      checked: props.checked || false // Lock the state as-is
    }),
    [props.checked]
  );

  const radioButtonComponent = (
    <StyledRadioLabel
      width={width}
      $focusVisible={focusVisible}
      {...(props.disabled ? { className: 'Mui-disabled' } : {})}
      control={
        <MuiRadioButton
          {...props}
          icon={<RadioButtonIconUnchecked />}
          checkedIcon={<RadioButtonIconChecked />}
          onFocusVisible={() => setFocusVisible(true)}
          onBlur={() => setFocusVisible(false)}
          {...(props.disabled ? inactiveProps : {})}
        />
      }
      ref={labelRef}
      label={children}
    />
  );

  return props.disabled && disabledReason ? (
    <OwnUpTooltip description={disabledReason}>{radioButtonComponent}</OwnUpTooltip>
  ) : (
    radioButtonComponent
  );
};
