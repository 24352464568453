export enum ThemeNames {
  'White' = 'white',
  'Light' = 'light',
  'Midtone' = 'midtone',
  'Dark' = 'dark'
}

export enum SizeNames {
  'Small' = 'small',
  'Default' = 'default',
  'Large' = 'large'
}
