import React from 'react';
import * as colors from '../../../colors';

export const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5353 13.596C11.4268 14.475 10.0247 15 8.5 15C4.91015 15 2 12.0898 2 8.49997C2 4.91012 4.91015 1.99997 8.5 1.99997C12.0899 1.99997 15 4.91012 15 8.49997C15 10.0247 14.475 11.4267 13.596 12.5353L17.7803 16.7196C18.0732 17.0125 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L12.5353 13.596ZM13.5 8.49997C13.5 11.2614 11.2614 13.5 8.5 13.5C5.73858 13.5 3.5 11.2614 3.5 8.49997C3.5 5.73855 5.73858 3.49997 8.5 3.49997C11.2614 3.49997 13.5 5.73855 13.5 8.49997Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
