import styled from 'styled-components';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { PropsWithTheme } from '../core/theme/properties';
import { OwnUpIconTooltipTriggerProps, OwnUpUnderlineTooltipTriggerProps } from './properties';

export const TextStyle = styled.span<PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>>`
  border-bottom: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
    `1px dashed ${theme.underlineTooltipTriggerTheme.underlineColor}`};
  color: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
    theme.underlineTooltipTriggerTheme.fontColor};
  padding: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
    `0 ${theme.spacing(theme.underlineTooltipTriggerTheme.focusSpacingMultiplier)}px`};
  box-sizing: border-box;
  &:hover {
    background: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
      theme.underlineTooltipTriggerTheme.backgroundColor};
    border-radius: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
      `${theme.underlineTooltipTriggerTheme.backgroundRadius} ${theme.underlineTooltipTriggerTheme.backgroundRadius} 0 0`};
  }
  &${focusVisibleSelectorMixin} {
    border: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
      `2px solid ${theme.underlineTooltipTriggerTheme.focusColor}`};
    border-radius: ${({ theme }: PropsWithTheme<OwnUpUnderlineTooltipTriggerProps>) =>
      theme.underlineTooltipTriggerTheme.focusRadius};
    padding: 0;
    outline: none;
  }
`;

export const IconTooltipTriggerStyle = styled.span<PropsWithTheme<OwnUpIconTooltipTriggerProps>>`
  color: ${({ theme }: PropsWithTheme<OwnUpIconTooltipTriggerProps>) =>
    theme.iconTooltipTriggerTheme.fontColor};
  padding: ${({ theme }: PropsWithTheme<OwnUpIconTooltipTriggerProps>) =>
    `${theme.spacing(theme.iconTooltipTriggerTheme.focusSpacingMultiplier)}px`};
  box-sizing: border-box;
  display: inline-block;
  &${focusVisibleSelectorMixin} {
    border: ${({ theme }: PropsWithTheme<OwnUpIconTooltipTriggerProps>) =>
      `2px solid ${theme.iconTooltipTriggerTheme.focusColor}`};
    border-radius: ${({ theme }: PropsWithTheme<OwnUpIconTooltipTriggerProps>) =>
      theme.iconTooltipTriggerTheme.focusRadius};
    padding: 0;
    outline: none;
  }
`;

export const IconStyle = styled.div<PropsWithTheme<OwnUpIconTooltipTriggerProps>>`
  margin-left: ${({ iconPosition, theme }: PropsWithTheme<OwnUpIconTooltipTriggerProps>) =>
    `${iconPosition !== 'left' ? theme.spacing(1) + 'px' : ''}`};
  margin-right: ${({ iconPosition, theme }: PropsWithTheme<OwnUpIconTooltipTriggerProps>) =>
    `${iconPosition === 'left' ? theme.spacing(1) + 'px' : ''}`};
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
`;

export const IconTextStyle = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
