import React from 'react';
import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

const DEFAULT_NOTIFICATION_VARIANT = 'default';
const URGENT_NOTIFICATION_VARIANT = 'urgent';
const NOTIFICATION_VARIANTS = [DEFAULT_NOTIFICATION_VARIANT, URGENT_NOTIFICATION_VARIANT] as const;
export type NotificationVariants = (typeof NOTIFICATION_VARIANTS)[number];

const ERROR_NOTIFICATION_TYPE = 'error';
const WARNING_NOTIFICATION_TYPE = 'warning';
const INFO_NOTIFICATION_TYPE = 'info';
const SUCCESS_NOTIFICATION_TYPE = 'success';
const NOTIFICATION_TYPES = [
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE
] as const;
export type NotificationTypes = (typeof NOTIFICATION_TYPES)[number];

export interface NotificationColors {
  notificationBackgroundColor: React.CSSProperties['color'];
  notificationBorderColor: React.CSSProperties['color'];
  notificationBackgroundFillColor: React.CSSProperties['color'];
}

export type NotificationThemeTokens = {
  notificationFontColor: React.CSSProperties['color'];
  notificationShadow: React.CSSProperties['color'];
  notificationBorderFocusColor: React.CSSProperties['color'];
} & Record<NotificationVariants, Record<NotificationTypes, NotificationColors>>;

const notificationThemeColors: NotificationThemeTokens = {
  notificationFontColor: colors.CHARCOAL_100,
  notificationShadow: colors.CHARCOAL_5,
  notificationBorderFocusColor: colors.SLATE_100,
  [DEFAULT_NOTIFICATION_VARIANT]: {
    [ERROR_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.WHITE_100,
      notificationBorderColor: colors.FOG_60,
      notificationBackgroundFillColor: colors.BERRY_100
    },
    [WARNING_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.WHITE_100,
      notificationBorderColor: colors.FOG_60,
      notificationBackgroundFillColor: colors.APRICOT_100
    },
    [INFO_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.WHITE_100,
      notificationBorderColor: colors.FOG_60,
      notificationBackgroundFillColor: colors.WALDEN_100
    },
    [SUCCESS_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.WHITE_100,
      notificationBorderColor: colors.FOG_60,
      notificationBackgroundFillColor: colors.CACTUS_100
    }
  },
  [URGENT_NOTIFICATION_VARIANT]: {
    [ERROR_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.BERRY_10,
      notificationBorderColor: colors.BERRY_10,
      notificationBackgroundFillColor: colors.BERRY_100
    },
    [WARNING_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.APRICOT_10,
      notificationBorderColor: colors.APRICOT_10,
      notificationBackgroundFillColor: colors.APRICOT_100
    },
    [INFO_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.MYSTIC_20,
      notificationBorderColor: colors.MYSTIC_20,
      notificationBackgroundFillColor: colors.WALDEN_100
    },
    [SUCCESS_NOTIFICATION_TYPE]: {
      notificationBackgroundColor: colors.MOSS_100,
      notificationBorderColor: colors.MOSS_100,
      notificationBackgroundFillColor: colors.CACTUS_100
    }
  }
};

type NotificationTheme = Record<ThemeNames, NotificationThemeTokens>;

export const notificationTheme: NotificationTheme = {
  [ThemeNames.White]: notificationThemeColors,
  [ThemeNames.Light]: notificationThemeColors,
  [ThemeNames.Midtone]: notificationThemeColors,
  [ThemeNames.Dark]: { ...notificationThemeColors, notificationBorderFocusColor: colors.WHITE_100 }
};
