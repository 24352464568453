import { useMemo } from 'react';

const getTextRepresentation = (node: React.ReactNode): string => {
  if (node == undefined) {
    // Return empty if not defined
    return '';
  } else if (typeof node === 'object') {
    if (Array.isArray(node)) {
      // If it's an array, concatenate all the content
      return (node as React.ReactNodeArray).reduce<string>((acc, child) => {
        const representation = getTextRepresentation(child);
        if (representation) {
          if (acc) {
            return `${acc}, ${representation}`;
          }
          return representation;
        }
        return acc;
      }, '');
    }
    if ('props' in node && 'children' in node.props) {
      // If it has children, traverse them
      return getTextRepresentation(node.props.children);
    }
  }
  // If all else fails, just stringify
  return node.toString();
};

/**
 * Traverses the input node and attempts to put together a
 *   string representation of it.
 *
 * @param node - Node to traverse
 * @returns - Our best guess of what it is in text
 */
export const useTextRepresentation = (node: React.ReactNode) =>
  useMemo(() => getTextRepresentation(node), [node]);
