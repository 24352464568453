import MuiCheckbox from '@material-ui/core/Checkbox';
import React, { useMemo } from 'react';
import { OwnUpTooltip } from '../../tooltips/tooltip';
import { CheckboxIconChecked, CheckboxIconUnchecked } from './icons';
import { OwnUpCheckboxProps } from './properties';
import { StyledCheckboxLabel } from './styles';

/**
 * Renders a checkbox.
 *
 * @remarks
 * This component leverages material-ui's Checkbox component {@link https://mui.com/components/checkboxes/}
 *
 * @param id - (optional) The element id
 * @param disabled - (optional) Boolean indicating whether the input should be in an inactive state
 * @param disabledReason - (optional) Message to display in a tooltip when the input is inactive
 * @param labelRef - (optional) ref to access <FormControlLabel>
 * @param width - (optional) CSS width value for the component
 * @returns A styled checkbox component
 *
 */
export const OwnUpCheckbox = ({
  children,
  width,
  disabledReason,
  labelRef,
  ...props
}: OwnUpCheckboxProps) => {
  const [focusVisible, setFocusVisible] = React.useState(false);

  const inactiveProps = useMemo(
    () => ({
      onChange: undefined, // Prevent field from being toggled
      disabled: false,
      checked: props.checked || false, // Lock the state as-is
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'aria-disabled': true,
      className: 'Mui-disabled'
    }),
    [props.checked]
  );

  const { onChange: _, checked: __, ...restInactiveProps } = inactiveProps;

  const checkboxComponent = useMemo(
    () => (
      <StyledCheckboxLabel
        width={width}
        $focusVisible={focusVisible}
        control={
          <MuiCheckbox
            {...props}
            icon={<CheckboxIconUnchecked />}
            checkedIcon={<CheckboxIconChecked />}
            onFocusVisible={() => setFocusVisible(true)}
            onBlur={() => setFocusVisible(false)}
            {...(props.disabled ? inactiveProps : {})}
          />
        }
        ref={labelRef}
        label={children}
        {...(props.disabled ? restInactiveProps : {})}
      />
    ),
    [
      width,
      focusVisible,
      setFocusVisible,
      props,
      inactiveProps,
      restInactiveProps,
      labelRef,
      children
    ]
  );

  return props.disabled && disabledReason ? (
    <OwnUpTooltip description={disabledReason}>{checkboxComponent}</OwnUpTooltip>
  ) : (
    checkboxComponent
  );
};
