import * as colors from '../../colors';
import { ThemeNames } from '../../core/theme/types';

export interface InputThemeTokens {
  inputFontColorLabelAbove: React.CSSProperties['color'];
  inputFontColorLabel: React.CSSProperties['color'];
  inputFontColorResponse: React.CSSProperties['color'];
  inputFontColorHint: React.CSSProperties['color'];
  inputFontColorPlaceholder: React.CSSProperties['color'];
  inputFontColorHelp: React.CSSProperties['color'];
  inputFontColorError: React.CSSProperties['color'];
  inputIconColorLeading: React.CSSProperties['color'];
  inputIconColorError: React.CSSProperties['color'];
  inputIconColorErrorBackground: React.CSSProperties['color'];
  inputBackgroundColor: React.CSSProperties['color'];
  inputBackgroundColorActive: React.CSSProperties['color'];
  inputBackgroundColorFocus: React.CSSProperties['color'];
  inputBackgroundColorHover: React.CSSProperties['color'];
  inputFocusColor: React.CSSProperties['color'];
  inputFocusColorError: React.CSSProperties['color'];
  inputUnderline: React.CSSProperties['color'];
  inputLeadingIconColor: React.CSSProperties['color'];
  inputBorderColor: React.CSSProperties['color'];
  inputBorderColorError: React.CSSProperties['color'];
}

type InputTheme = {
  [key in ThemeNames]: InputThemeTokens;
};

const commonColors = {
  inputFontColorLabelAbove: colors.CHARCOAL_100,
  inputFontColorLabel: colors.SLATE_100,
  inputFontColorResponse: colors.CHARCOAL_100,
  inputFontColorHint: colors.SLATE_100,
  inputFontColorPlaceholder: colors.SLATE_50,
  inputFontColorHelp: colors.CHARCOAL_100,
  inputFontColorError: colors.BERRY_100,
  inputIconColorLeading: colors.ALOE_100,
  inputIconColorError: colors.BERRY_100,
  inputIconColorErrorBackground: colors.BERRY_20,
  inputBackgroundColor: colors.FOG_20,
  inputBackgroundColorActive: colors.WHITE_100,
  inputBackgroundColorFocus: colors.MOSS_50,
  inputBackgroundColorHover: colors.MOSS_50,
  inputFocusColor: colors.ALOE_100,
  inputFocusColorError: colors.BERRY_100,
  inputUnderline: colors.ALOE_100,
  inputLeadingIconColor: colors.ALOE_100,
  inputBorderColor: colors.FOG_60,
  inputBorderColorError: colors.BERRY_100
};

export const inputTheme: InputTheme = {
  [ThemeNames.White]: {
    ...commonColors
  },
  [ThemeNames.Light]: {
    ...commonColors,
    inputBackgroundColor: colors.WHITE_100
  },
  [ThemeNames.Midtone]: {
    ...commonColors,
    inputFontColorError: colors.CHARCOAL_100,
    inputBackgroundColor: colors.WHITE_100
  },
  [ThemeNames.Dark]: {
    ...commonColors,
    inputFontColorLabelAbove: colors.WHITE_100,
    inputFontColorLabel: colors.WHITE_100,
    inputFontColorResponse: colors.WHITE_100,
    inputFontColorHint: colors.WHITE_100,
    inputFontColorPlaceholder: colors.WHITE_50,
    inputFontColorHelp: colors.WHITE_100,
    inputFontColorError: colors.WHITE_100,
    inputIconColorLeading: colors.WHITE_100,
    inputBackgroundColor: colors.WHITE_10,
    inputFocusColor: colors.WHITE_100,
    inputFocusColorError: colors.WHITE_100,
    inputUnderline: colors.WHITE_100,
    inputLeadingIconColor: colors.WHITE_100,
    inputIconColorError: colors.WHITE_100
  }
};
