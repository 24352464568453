/* eslint-disable @typescript-eslint/naming-convention */
import TextField from '@material-ui/core/TextField';
import styled, { css } from 'styled-components';
import * as colors from '../../colors';
import { PropsWithTheme } from '../../core';
import { Z_INDICES } from '../../core/theme/constants/z-indices';
import { OwnUpBody } from '../../typography/body';
import { OwnUpDetail, ownUpDetailMixin } from '../../typography/detail';
import { inputIconSizeDropdown, inputPadding } from '../common/form-control/style';
import { DivProps, InputWrapperProps, LabelProps, TextInputProps } from './internal-props';

// typography
const inputFontSizeLabel = OwnUpBody;
const inputFontSizeHelp = OwnUpDetail;
const inputFontSizeError = OwnUpDetail;

// spacing
const inputFontSpacingHelp = '12px';
const inputFontSpacingError = '12px';
const inputBackgroundSpacingHorizontal = '20px';
const inputBackgroundSpacingTop = '20px';
const inputBackgroundSpacingTopEntry = '10px';
const inputBackgroundSpacingBottomEntry = '12px';

// radius
const inputBackgroundRadius = '4px';

// height
const inputHeight = '4rem';

export const SvgWrapper = styled.div<PropsWithTheme<DivProps>>`
  width: 44px;
  position: absolute;
  z-index: ${Z_INDICES.FORM_ICONS};
  display: flex;
  align-items: center;
  height: ${inputHeight};
  padding-left: 20px;
  & svg {
    width: 20px;
    height: 20px;
  }
  & svg path {
    fill: ${(props: PropsWithTheme<DivProps>) => props.theme.inputColors.inputLeadingIconColor};
  }
`;

export const RightSvgWrapper = styled(SvgWrapper)<PropsWithTheme<DivProps>>`
  position: relative;
`;

export const textInputStyles = css<PropsWithTheme<TextInputProps>>`
  .MuiInputBase-root {
    background-color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.theme.inputColors.inputBackgroundColor};
  }
  .MuiFilledInput-underline:not(.Mui-error):before,
  .MuiFilledInput-underline:not(.Mui-error):after {
    border-bottom: none;
    height: 1px;
    background-color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.theme.inputColors.inputUnderline};
    margin: auto;
    width: 100%;
    border-radius: 25px;
  }
  .MuiFilledInput-underline.Mui-error:before,
  .MuiFilledInput-underline.Mui-error:after {
    border-bottom: none;
    height: 1px;
    background-color: ${colors.BERRY_100};
    margin: auto;
    width: 100%;
    border-radius: 25px;
  }
  .MuiFilledInput-underline:not(.Mui-disabled):hover:before,
  .MuiFilledInput-underline:not(.Mui-disabled):hover:after,
  .MuiFilledInput-underline.Mui-focused:before,
  .MuiFilledInput-underline.Mui-focused:after {
    border-bottom: none;
    height: 2px;
    background-color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.error
        ? props.theme.inputColors.inputIconColorError
        : props.theme.inputColors.inputUnderline};
    margin: auto;
    width: 100%;
    border-radius: 25px;
  }
  .MuiFilledInput-root {
    height: ${inputHeight};
  }
  .MuiFormHelperText-root {
    ${inputFontSizeHelp};
    margin: ${inputFontSpacingHelp} ${inputBackgroundSpacingHorizontal} 0;
    color: ${(props: PropsWithTheme<TextInputProps>) => props.theme.inputColors.inputFontColorHelp};
    &.Mui-error {
      ${ownUpDetailMixin}
    }
  }
`;

export const DropdownTextInput = styled(TextField)<PropsWithTheme<TextInputProps>>`
  ${textInputStyles}
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 12px;
    padding-left: ${inputPadding};
    ${({ placeholder }: PropsWithTheme<TextInputProps>) => {
      return placeholder
        ? css`
            &.Mui-disabled {
              opacity: 0.3;
            }
          `
        : css`
            :not(.Mui-disabled) {
              opacity: 1;
            }
          `;
    }}
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: ${(props: PropsWithTheme<TextInputProps>) =>
      props.labelPosition === 'outer' ? '12px' : '28px'};
    padding-left: 0px;
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionEnd {
    bottom: 20px;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 0.625rem);
    width: ${inputIconSizeDropdown};
    height: ${inputIconSizeDropdown};
    line-height: 1.1876em;
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-endAdornment {
    right: 20px;
  }
  .MuiAutocomplete-popupIndicator {
    margin-top: -2px;
    padding: 0px;
    &:hover {
      background-color: transparent;
    }
    svg {
      & path {
        fill: ${(props: PropsWithTheme<TextInputProps>) =>
          props.error
            ? props.theme.inputColors.inputIconColorError
            : props.theme.inputColors.inputUnderline};
      }
    }
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
`;

export const TextInput = styled(TextField)<PropsWithTheme<TextInputProps>>`
  ${textInputStyles}
  .MuiFilledInput-underline.Mui-disabled:before {
    opacity: 0.4;
  }
  .MuiFormLabel-root {
    ${inputFontSizeLabel};
    font-size: 1rem;
  }
  input {
    font-size: 1rem;
    height: ${(props) =>
      props.labelPosition == 'outer'
        ? `calc(${inputHeight} - 2 * ${inputBackgroundSpacingTop})`
        : `calc(${inputHeight} - (27px + ${inputBackgroundSpacingBottomEntry}))`};
    padding: ${(props) => {
      if (props.labelPosition == 'outer' && props.$leadingIcon) {
        return `${inputBackgroundSpacingTop} ${inputBackgroundSpacingHorizontal} ${inputBackgroundSpacingTop} 16px`;
      } else if (props.labelPosition == 'outer' && !props.$leadingIcon) {
        return `${inputBackgroundSpacingTop}`;
      } else if (props.labelPosition == 'inner' && props.$leadingIcon) {
        return `27px ${inputBackgroundSpacingHorizontal} ${inputBackgroundSpacingBottomEntry} 56px`;
      } else {
        return `27px ${inputBackgroundSpacingHorizontal} ${inputBackgroundSpacingBottomEntry}`;
      }
    }};
  }
  .MuiFilledInput-input {
    ${inputFontSizeLabel};
    border-radius: ${inputBackgroundRadius} ${inputBackgroundRadius} 0 0;
    color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.theme.inputColors.inputFontColorResponse};
  }
  .MuiFormHelperText-root.Mui-error {
    ${inputFontSizeError};
    margin-top: ${inputFontSpacingError};
    color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.theme.inputColors.inputFontColorError};
  }
  .MuiFilledInput-adornedStart {
    background-color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.theme.inputColors.inputBackgroundColor};
    padding-left: 20px;

    .MuiInputAdornment-positionStart {
      & svg {
        width: 20px;
        height: 20px;
      }
      & svg path {
        fill: ${(props: PropsWithTheme<TextInputProps>) =>
          props.theme.inputColors.inputLeadingIconColor};
      }
    }
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin: 0;
  }
  .MuiFilledInput-adornedEnd {
    background-color: ${(props: PropsWithTheme<TextInputProps>) =>
      props.theme.inputColors.inputBackgroundColor};
    padding-right: 20px;
    .MuiInputAdornment-positionEnd {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const InputWrapper = styled.div<PropsWithTheme<InputWrapperProps>>`
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiInputLabel-filled {
    ${inputFontSizeLabel};
    color: ${(props: PropsWithTheme<InputWrapperProps>) =>
      props.theme.inputColors.inputFontColorLabel};
    transform: translate(${inputBackgroundSpacingHorizontal}, 25px) scale(1);
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    line-height: 1.5rem;
    color: ${(props: PropsWithTheme<InputWrapperProps>) =>
      props.theme.inputColors.inputFontColorLabel};
    transform: translate(${inputBackgroundSpacingHorizontal}, ${inputBackgroundSpacingTopEntry})
      scale(0.75);
  }
  .Mui-disabled:not(.MuiFilledInput-input),
  .Mui-disabled:not(.MuiInputBase-input) {
    opacity: 0.4;
  }
  .MuiInputBase-input::-webkit-input-placeholder {
    color: ${(props: PropsWithTheme<InputWrapperProps>) =>
      props.theme.inputColors.inputFontColorHint};
    font-style: italic;
    opacity: 0.5;
  }
  .MuiInputLabel-formControl {
    left: ${(props) => (props.labelPosition == 'inner' && props.$leadingIcon ? '36px' : '0')};
  }
`;

export const InputLabelText = styled.label<PropsWithTheme<LabelProps>>`
  color: ${(props: PropsWithTheme<LabelProps>) => props.theme.inputColors.inputFontColorLabelAbove};
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  text-align: left;
`;

export const MainWrapper = styled.div<DivProps>`
  position: relative;
`;
