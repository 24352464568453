import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import { OwnUpBodyWrapper } from '../body';
import { OwnUpIconButtonProps, OwnUpIconButtonQuiet } from '../buttons';
import { CHARCOAL_100, CHARCOAL_5, WHITE_100 } from '../colors';
import { ownUpWhiteTheme } from '../core/theme';
import { OwnUpSmallSubheadlineMedium } from '../typography/subheadline';
import { OwnUpModalBackgroundProps, OwnUpSideModalBodyProps } from './properties';

// Common Modal elements
export const OwnUpModalBackground = styled(Modal)<OwnUpModalBackgroundProps>``;

const sideAndBottomModalTitleRightPadding = `calc(40px + ${ownUpWhiteTheme.spacing(2)}px)`;

const commonBodyStyle = `
  background-color: ${WHITE_100};
  color: ${CHARCOAL_100};
  box-shadow: 4px 4px 8px 0px ${CHARCOAL_5};
  display: block;
  position: absolute;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

// Bottom Modal

const modalBottomTitleTopMargin = `${ownUpWhiteTheme.spacing(2)}px`;

export const OwnUpBottomModalTitle = styled(OwnUpSmallSubheadlineMedium)`
  padding-right: ${sideAndBottomModalTitleRightPadding};
  margin-top: ${modalBottomTitleTopMargin};
`;

const modalBottomBodyPadding = `${ownUpWhiteTheme.spacing(3)}px ${ownUpWhiteTheme.spacing(3)}px 
${ownUpWhiteTheme.spacing(4)}px ${ownUpWhiteTheme.spacing(3)}px`;

export const OwnUpBottomModalBody = styled(Box)`
  ${commonBodyStyle}
  bottom: 0px;
  width: 100vw;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  max-height: calc(100% - ${ownUpWhiteTheme.spacing(6)}px);
  max-height: calc(100dvh - ${ownUpWhiteTheme.spacing(6)}px);
  padding: ${modalBottomBodyPadding};
`;

export const OwnUpBottomModalExitButton = styled(OwnUpIconButtonQuiet)<OwnUpIconButtonProps>`
  float: right;
  margin-right: 0px;
  margin-top: 0px;
`;

// Floating Modal

const modalFloatingTitleTopMargin = `${ownUpWhiteTheme.spacing(3)}px`;

// 24px is a bit of a magic number derived from half the size variable used for
//  the exit button in ./element.tsx
const floatingModalTitleRightPadding = `calc(24px + ${ownUpWhiteTheme.spacing(2)}px)`;

export const OwnUpFloatingModalTitle = styled(OwnUpSmallSubheadlineMedium)`
  padding-right: ${floatingModalTitleRightPadding};
  margin-top: ${modalFloatingTitleTopMargin};
`;

const modalFloatingBodyPadding = `${ownUpWhiteTheme.spacing(3)}px 
${ownUpWhiteTheme.spacing(5)}px ${ownUpWhiteTheme.spacing(6)}px ${ownUpWhiteTheme.spacing(5)}px`;
const modalFloatingBodyMaxHeight = `calc(100vh - (2 * ${ownUpWhiteTheme.spacing(8)}px))`;

export const OwnUpFloatingModalBody = styled(Box)`
  ${commonBodyStyle}
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: auto;
  max-height: ${modalFloatingBodyMaxHeight};
  padding: ${modalFloatingBodyPadding};
`;

const floatingModalContentMargin = `${ownUpWhiteTheme.spacing(2)}px 0px 0px 0px`;

export const OwnUpFloatingModalContent = styled(OwnUpBodyWrapper)`
  margin: ${floatingModalContentMargin};
  padding: 0px;
`;

const floatingModalExitButtonRightMargin = `-${ownUpWhiteTheme.spacing(2)}px`;

export const OwnUpFloatingModalExitButton = styled(OwnUpIconButtonQuiet)<OwnUpIconButtonProps>`
  float: right;
  margin-right: ${floatingModalExitButtonRightMargin};
  margin-top: 0px;
`;

// Side Modal

const modalSideBodyPadding = `${ownUpWhiteTheme.spacing(3)}px ${ownUpWhiteTheme.spacing(3)}px 
${ownUpWhiteTheme.spacing(6)}px ${ownUpWhiteTheme.spacing(5)}px`;

export const OwnUpSideModalBody = styled(Box)<OwnUpSideModalBodyProps>`
  ${commonBodyStyle}
  ${({ $anchorSide }) => ($anchorSide === 'right' ? 'right: 0px' : 'left: 0px')};
  height: 100vh;
  max-width: max(50vw, 420px);
  padding: ${modalSideBodyPadding};
`;

const sideModalTitleTopMargin = `${ownUpWhiteTheme.spacing(3)}px`;

export const OwnUpSideModalTitle = styled(OwnUpSmallSubheadlineMedium)`
  padding-right: ${sideAndBottomModalTitleRightPadding};
  margin-top: ${sideModalTitleTopMargin};
`;

const sideModalContentMargin = `${ownUpWhiteTheme.spacing(2)}px 0px 0px 0px`;

export const OwnUpSideModalContent = styled(OwnUpBodyWrapper)`
  margin: ${sideModalContentMargin};
  padding: 0px 16px 0px 0px;
`;
