import React from 'react';
import { OwnUpGridItem } from '../item';
import { OwnUpGridOffsetProps } from './properties';

/**
 * A wrapper around our {@link OwnUpGridItem} component that
 *   contains nothing in it, which can be used to offset grids.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://v4.mui.com/api/grid/}
 *
 * @param props - {@link OwnUpGridOffsetProps}
 */
export const OwnUpGridOffset = (props: OwnUpGridOffsetProps) => <OwnUpGridItem {...props} />;
