import Link from '@material-ui/core/Link';
import styled, { css } from 'styled-components';
import { OwnUpIconButton, OwnUpIconButtonProps } from '../../buttons';
import { ALOE_100 } from '../../colors';
import { ownUpWhiteTheme, PropsWithTheme } from '../../core';
import { createElementOutlineMixin } from '../../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../../core/theme/mixins/focus-visible-selector';
import { OwnUpIcon } from '../../icons/component';
import { ownUpBodyMediumMixin, ownUpLargeBodyMediumMixin } from '../../typography/body';
import { ownUpSmallSubheadlineMediumMixin } from '../../typography/subheadline';
import { OwnUpNavButtonProps, OwnUpNavLinkProps } from '../properties';

// Nav bar icon

const navigationIconSpacing = ownUpWhiteTheme.spacing(1) + 'px';
const navigationIconSize = '1.25rem';

export const NavIconStyle = styled(OwnUpIcon)`
  display: inline-block;
  padding-right: ${navigationIconSpacing};
  height: ${navigationIconSize};
  vertical-align: middle;
`;

// Nav link

const navLinkTextStyle = css`
  display: inline-flex;
  align-items: center;
  span {
    vertical-align: middle;
  }
`;

export const navLinkTextStyleSmall = css`
  ${ownUpBodyMediumMixin}
  ${navLinkTextStyle}
`;

export const navLinkTextStyleMedium = css`
  ${ownUpLargeBodyMediumMixin}
  ${navLinkTextStyle}
`;

export const navLinkTextStyleLarge = css`
  ${ownUpSmallSubheadlineMediumMixin}
  ${navLinkTextStyle}
`;

const navigationFontColorHover = ALOE_100;
const navigationFontColorFocus = ALOE_100;
export const navigationFocus = '2px';
export const navigationFocusRadius = '4px';
export const navigationFocusSpacing = '4px';

export const OwnUpNavLinkWrapper = styled(Link)<OwnUpNavLinkProps>`
  cursor: pointer;
  color: ${({ theme, $currentlyVisiting }: PropsWithTheme<OwnUpNavLinkProps>) =>
    $currentlyVisiting
      ? theme.navLinkColors.navigationFontColorSelected
      : theme.navLinkColors.navigationFontColorActive};
  padding: 0 ${navigationFocusSpacing};
  height: 100%;

  &:visited {
    color: ${({ theme, $currentlyVisiting }: PropsWithTheme<OwnUpNavLinkProps>) =>
      $currentlyVisiting
        ? theme.navLinkColors.navigationFontColorSelected
        : theme.navLinkColors.navigationFontColorActive};
  }
  &:hover {
    color: ${({ theme }: PropsWithTheme<{}>) => theme.navLinkColors.navigationFontColorHover};
    text-decoration: none;
  }
  &${focusVisibleSelectorMixin} {
    color: ${({ theme }: PropsWithTheme<{}>) => theme.navLinkColors.navigationFontColorFocus};
    ${({ theme }: PropsWithTheme<{}>) =>
      createElementOutlineMixin({
        color: theme.navLinkColors.navigationFontColorFocus,
        width: navigationFocus,
        inset: 0,
        radius: navigationFocusRadius
      })}
  }

  &:focus {
    outline-style: unset;
  }

  ${({ size }: OwnUpNavLinkProps) => {
    switch (size) {
      case 'small':
        return navLinkTextStyleSmall;
      case 'medium':
        return navLinkTextStyleMedium;
      default:
        return navLinkTextStyleLarge;
    }
  }}
`;

// Nav button

interface NavButtonStyleProps {
  $onModal: boolean;
}

export const OwnUpNavButtonContainer = styled.div<NavButtonStyleProps>`
  height: ${({ $onModal }: NavButtonStyleProps) => ($onModal ? 'fit-content' : '100%')};
`;

interface NavButtonWrapperProps extends Omit<OwnUpNavButtonProps, 'drawerItems'> {
  $showDrawer: boolean;
}

// A couple notes:
// - Need to apply appropriate css to the button when the parent element is focused/hovered
// - The NavLink has its own focusVisible mixin, but this needs to be overriden here
//    for the icon spacing
export const OwnUpNavButtonWrapper = styled(OwnUpNavLinkWrapper)<NavButtonWrapperProps>`
  ${({ size }: NavButtonWrapperProps) => {
    switch (size) {
      case 'small':
        return navLinkTextStyleSmall;
      case 'medium':
        return navLinkTextStyleMedium;
      default:
        return navLinkTextStyleLarge;
    }
  }}
  color: ${({ $showDrawer, $currentlyVisiting, theme }: PropsWithTheme<NavButtonWrapperProps>) => {
    if ($showDrawer) {
      return navigationFontColorHover;
    } else if ($currentlyVisiting) {
      return theme.navLinkColors.navigationFontColorSelected;
    } else {
      return '';
    }
  }};
  button {
    background: ${({ $showDrawer, theme }: PropsWithTheme<NavButtonWrapperProps>) =>
      $showDrawer ? theme.buttonColors.iconButtons.defaultColors?.backgroundColorHover : ''};
  }

  &:focus > button {
    background: ${({ theme }: PropsWithTheme<NavButtonWrapperProps>) =>
      theme.buttonColors.iconButtons.defaultColors?.backgroundColorFocus};
  }

  &:hover > button {
    &:not(&[aria-disabled='true']) {
      background: ${({ theme }: PropsWithTheme<NavButtonWrapperProps>) =>
        theme.buttonColors.iconButtons.defaultColors?.backgroundColorHover};
    }
  }

  &${focusVisibleSelectorMixin} {
    color: ${navigationFontColorFocus};
    ${() =>
      createElementOutlineMixin({
        color: navigationFontColorFocus,
        width: navigationFocus,
        radius: navigationFocusRadius
      })}
    &::after {
      left: 0;
      right: 0;
    }
  }
  position: relative;
`;

// Setting margin-top is because the icon wasn't centering properly.
//  Used negative margin on top because doing it on the bottom changed the height
//  of the parent element and messed up the size.
export const NavButtonIconStyle = styled(OwnUpIconButton)<OwnUpIconButtonProps>`
  margin-left: ${navigationIconSpacing};
`;

interface NavMenuDrawerContainerProps extends React.PropsWithChildren<{}> {
  showDrawer: boolean;
}

export const NavMenuDrawerContainer = styled.div<NavMenuDrawerContainerProps>`
  display: ${({ showDrawer }: NavMenuDrawerContainerProps) => (showDrawer ? 'block' : 'none')};
`;
