/* eslint-disable import/extensions */
import AktivGroteskBoldWOFF from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff';
import AktivGroteskBoldWOFF2 from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff2';
import AktivGroteskItalicWOFF from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_It.woff';
import AktivGroteskItalicWOFF2 from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_It.woff2';
import AktivGroteskMediumWOFF from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff';
import AktivGroteskMediumWOFF2 from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff2';
import AktivGroteskRegularWOFF from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff';
import AktivGroteskRegularWOFF2 from '../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff2';

export const aktivGroteskBoldFontDefinition = {
  fontFamily: 'Aktiv-Grotesk-Bold',
  src: `url("${AktivGroteskBoldWOFF2}") format("woff2"),
         url("${AktivGroteskBoldWOFF}") format("woff")`,
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'fallback'
} as const;

export const aktivGroteskItalicFontDefinition = {
  fontFamily: 'Aktiv-Grotesk-Italic',
  src: `url("${AktivGroteskItalicWOFF2}") format("woff2"),
         url("${AktivGroteskItalicWOFF}") format("woff")`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'fallback'
} as const;

export const aktivGroteskMediumFontDefinition = {
  fontFamily: 'Aktiv-Grotesk-Medium',
  src: `url("${AktivGroteskMediumWOFF2}") format("woff2"),
         url("${AktivGroteskMediumWOFF}") format("woff")`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'fallback'
} as const;

export const aktivGroteskRegularFontDefinition = {
  fontFamily: 'Aktiv-Grotesk-Regular',
  src: `url("${AktivGroteskRegularWOFF2}") format("woff2"),
         url("${AktivGroteskRegularWOFF}") format("woff")`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'fallback'
} as const;
