import { Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTheme } from 'styled-components';
import { useSanitizedUrl } from '../hooks/use-sanitized-url';
import { OwnUpNavLinkProps } from './properties';
import { NavIconStyle, OwnUpNavLinkWrapper } from './styles';

export const OwnUpNavLink = ({ children, icon, href, ...props }: OwnUpNavLinkProps) => {
  const theme = useTheme() as Theme;

  return (
    <OwnUpNavLinkWrapper tabIndex={0} href={useSanitizedUrl(href)} {...props}>
      {icon && (
        <NavIconStyle icon={icon} color={theme.navLinkColors.navigationIconColor}></NavIconStyle>
      )}
      <span>{children}</span>
    </OwnUpNavLinkWrapper>
  );
};

/**
 * Only used with a full width NavBar
 *
 * @deprecated Use OwnUpNavLink with size prop.
 */
export const OwnUpNavLinkSmall = (props: OwnUpNavLinkProps) => (
  <OwnUpNavLink {...props} size="small" />
);

/**
 * Only used with NavBarModal
 *
 * @deprecated Use OwnUpNavLink with size prop.
 */
export const OwnUpNavLinkMedium = (props: OwnUpNavLinkProps) => (
  <OwnUpNavLink {...props} size="medium" />
);

/**
 * Only used with NavBarModal
 *
 * @deprecated Use OwnUpNavLink with size prop.
 */
export const OwnUpNavLinkLarge = (props: OwnUpNavLinkProps) => (
  <OwnUpNavLink {...props} size="large" />
);
