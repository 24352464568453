/* stylelint-disable font-family-no-missing-generic-family-keyword */
import { css } from 'styled-components';

/**
 * Mixin to apply the P22 Mackinac Medium font.
 */
export const p22MackinacMediumMixin = css`
  font-family: 'P22-Mackinac-Medium', Georgia, serif;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
`;

/**
 * Mixin to apply the P22 Mackinac Book font.
 */
export const p22MackinacBookMixin = css`
  font-family: 'P22-Mackinac-Book', Georgia, serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
`;
