import styled from 'styled-components';
import { PropsWithTheme } from '..';
import { createOwnUpButtonThemeStyle, OwnUpButton } from './buttons-common';
import { OwnUpButtonProps } from './properties';
import { socialButtonStyle } from './styles';
import { SOCIAL_BUTTON_VARIANT } from './theme';

const socialButtonThemeStyle = createOwnUpButtonThemeStyle(SOCIAL_BUTTON_VARIANT);

export const OwnUpSocialButton = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${socialButtonThemeStyle}
  ${socialButtonStyle}
`;
