import React from 'react';
import * as colors from '../../../colors';

export const MicroChevronDownIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7679 4.20748C11.4682 3.92148 10.9935 3.93252 10.7075 4.23214L6 9.16379L1.29252 4.23214C1.00651 3.93252 0.531767 3.92148 0.232143 4.20748C-0.0674805 4.49349 -0.0785212 4.96823 0.207483 5.26786L5.45748 10.7679C5.599 10.9161 5.79504 11 6 11C6.20496 11 6.40099 10.9161 6.54252 10.7679L11.7925 5.26786C12.0785 4.96823 12.0675 4.49349 11.7679 4.20748Z"
        fill={colors.CHARCOAL_100}
      />
    </svg>
  );
};
