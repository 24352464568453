import InputAdornment from '@material-ui/core/InputAdornment';
import { MenuProps } from '@material-ui/core/Menu';
import { PopoverOrigin } from '@material-ui/core/Popover';
import React, { useMemo, useState } from 'react';
import { OwnUpThemeProvider } from '../../core/provider';
import { ownUpWhiteTheme } from '../../core/theme';
import { useElementId } from '../../hooks/use-element-id';
import { useRandomId } from '../../hooks/use-random-id';
import { useWrappedFunction } from '../../hooks/use-wrapped-function';
import { ErrorLightIcon } from '../../icon-library/notification-icons/error-light';
import { OwnUpIcon } from '../../icons';
import { OwnUpFormControl } from '../common/form-control/element';
import { OwnUpDropdownProps, OwnUpMenuItemProps } from './properties';
import { dropdownMenuStyles, StyledMenuItem, StyledSelect } from './style';
import { useChevronIcon } from './use-chevron-icon';

export const OwnUpMenuItem = (props: OwnUpMenuItemProps) => {
  //  Menu elements are forced to the white theme
  return (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <StyledMenuItem {...props} />
    </OwnUpThemeProvider>
  );
};

/**
 * Build the anchor attributes when the position is set.
 */
const useAnchorProps = (menuPosition: OwnUpDropdownProps['menuPosition']) =>
  useMemo(() => {
    if (menuPosition) {
      let anchorVert: PopoverOrigin['vertical'];
      let transformVert: PopoverOrigin['vertical'];
      switch (menuPosition) {
        case 'top':
          // Top-positioned popouts attach to the bottom of the
          //   element and anchor to the bottom of the menu
          anchorVert = 'bottom';
          transformVert = 'bottom';
          break;
        case 'bottom':
          // Bottom-positioned popouts attach to the bottom of the
          //   element and anchor to the top of the menu
          anchorVert = 'bottom';
          transformVert = 'top';
          break;
        default:
          // Center-positioned popouts attach to the center of the
          //   element and  anchor to the center of the menu
          anchorVert = 'center';
          transformVert = 'center';
          break;
      }
      return {
        // This function must explicitly be set to undefined in order to
        //   use custom anchoring rules.
        // See: https://github.com/mui-org/material-ui/pull/8948
        getContentAnchorEl: undefined,
        anchorOrigin: {
          horizontal: 'left',
          vertical: anchorVert
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: transformVert
        }
      } as Partial<MenuProps>;
    }
    return undefined;
  }, [menuPosition]);

export const OwnUpDropdown = ({
  id,
  menuPosition,
  value,
  label,
  labelPosition,
  helperText,
  error,
  inputLabelRef,
  formHelperTextRef,
  errorTextRef,
  disabled,
  disabledReason,
  placeholder,
  children,
  open,
  onOpen,
  onClose,
  ...props
}: OwnUpDropdownProps) => {
  // In order to make sure our form control labels align, we need
  //   to ensure a label is provided. If the implementor does not
  //   provide one, we'll make one for them.
  const $inputId = useElementId(id, 'select');
  // An id is required on the Mui selector outer element, but Mui
  //   will not generate a unique one if multiple are used, so we
  //   must generate them ourselves.
  const labelId = useRandomId('select-label');
  // Mui prefers to use dummy elements as placeholders.
  //   This will be hidden from the selection popup via CSS selectors
  const placeholderElement = useMemo(
    () =>
      labelPosition === 'outer' && placeholder ? (
        <OwnUpMenuItem className="placeholder" value="" disabled>
          {/* Mark span as a placeholder so a11y tests know it's an
                exception to color contrast rules.
              TODO: The WCAG spec says they aren't supposed to be.
                See: https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html */}
          <span aria-placeholder={placeholder}>{placeholder}</span>
        </OwnUpMenuItem>
      ) : undefined,
    [labelPosition, placeholder]
  );

  // Track the open state of this component
  const [isOpen, setOpen] = useState(open);
  const onOpenWrapper = useWrappedFunction<[React.ChangeEvent<{}>], void>(
    () => setOpen(true),
    onOpen
  );
  const onCloseWrapper = useWrappedFunction<[React.ChangeEvent<{}>], void>(
    () => setOpen(false),
    onClose
  );

  // Figure out which icon to use
  const IconElement = useChevronIcon(menuPosition, isOpen);

  // Figure out the anchor configuration for this dropdown
  const anchorProps = useAnchorProps(menuPosition);

  const menuStyleClasses = dropdownMenuStyles({ menuPosition });
  return (
    <React.Fragment>
      <OwnUpFormControl
        $inputId={$inputId}
        label={label}
        labelPosition={labelPosition}
        helperText={helperText}
        error={error}
        inputLabelRef={inputLabelRef}
        formHelperTextRef={formHelperTextRef}
        errorTextRef={errorTextRef}
        disabled={disabled}
        disabledReason={disabledReason}
        placeholder={placeholder}
        // Always force the label to shrink when the menu is
        //   open or a value is selected. This override's
        //   MUI's behavior that keeps the label shrunk when
        //   clicking out of a menu but not picking anything.
        shrink={isOpen || !!value}
      >
        <StyledSelect
          {...props}
          id={labelId}
          MenuProps={{
            classes: { paper: menuStyleClasses.paper, list: menuStyleClasses.list },
            MenuListProps: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'aria-labelledby': labelId
            },
            ...anchorProps
          }}
          open={isOpen}
          onOpen={onOpenWrapper}
          onClose={onCloseWrapper}
          value={value}
          label={label}
          labelPosition={labelPosition}
          disabled={disabled}
          // HACK: No matter which way I slice it the typing of this keeps
          //   resolving this as undefined rather than string, as if it's
          //   trying to resolve the original's "boolean" type and the
          //   overridden "string" type. There's no runtime problems here,
          //   just a typescript issue. Hopefully it'll be resolved in
          //   future iterations. - BC
          error={error as any}
          inputProps={{ name: label, id: $inputId, placeholder }}
          displayEmpty
          IconComponent={IconElement}
          endAdornment={
            error && (
              <InputAdornment position="end">
                <OwnUpIcon icon={ErrorLightIcon} />
              </InputAdornment>
            )
          }
        >
          {placeholderElement}
          {children}
        </StyledSelect>
      </OwnUpFormControl>
    </React.Fragment>
  );
};
