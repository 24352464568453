import React, { useMemo, useState } from 'react';
import { OwnUpIconButtonNeutral } from '../buttons';
import { OwnUpThemeProvider, ownUpWhiteTheme } from '../core';
import { useTextRepresentation } from '../hooks/use-text-representation';
import { useWrappedFunction } from '../hooks/use-wrapped-function';
import { ErrorDarkIcon } from '../icon-library/notification-icons/error-dark';
import { ErrorLightIcon } from '../icon-library/notification-icons/error-light';
import { InformationDarkIcon } from '../icon-library/notification-icons/information-dark';
import { InformationLightIcon } from '../icon-library/notification-icons/information-light';
import { SuccessDarkIcon } from '../icon-library/notification-icons/success-dark';
import { WarningDarkIcon } from '../icon-library/notification-icons/warning-dark';
import { MicroCloseIcon } from '../icon-library/system-icons/micro-icons/close-icon';
import { OwnUpNotificationProps } from './properties';
import {
  notificationBackgroundSpacing,
  NotificationDescriptionSmallStyle,
  NotificationDescriptionStyle,
  NotificationTitleStyle,
  OwnUpNotificationIcon,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledNotification,
  TitleAndDescriptionDiv
} from './styles';
import { NotificationTypes, NotificationVariants } from './theme';

/**
 * Determine which icon to show based on notification variation/type.
 *
 * @param type - error | warning | info | warning
 * @param variation - default | urgent
 * @param $hasIcon - boolean that determines whether to show the icon or not
 * @returns - which icon to show
 */
const useNotificationIcon = (
  type: NotificationTypes,
  variation: NotificationVariants,
  $hasIcon?: boolean
) =>
  useMemo(() => {
    let icon: JSX.Element;
    if ($hasIcon) {
      switch (type) {
        case 'error':
          icon = variation === 'default' ? <ErrorLightIcon /> : <ErrorDarkIcon />;
          break;
        case 'warning':
          icon = <WarningDarkIcon />;
          break;
        case 'info':
          icon = variation === 'default' ? <InformationLightIcon /> : <InformationDarkIcon />;
          break;
        case 'success':
          icon = <SuccessDarkIcon />;
          break;
        default:
          icon = <></>;
      }
      return (
        <OwnUpNotificationIcon
          style={{ paddingLeft: `${notificationBackgroundSpacing}` }}
          variation={variation}
          type={type}
          icon={icon}
          aria-hidden="true"
        />
      );
    } else {
      return null;
    }
  }, [variation, type, $hasIcon]);

interface NotificationElementProps extends OwnUpNotificationProps {
  type: NotificationTypes;
  variation: NotificationVariants;
}

export const OwnUpNotification = ({
  title,
  description,
  children,
  type,
  variation,
  $hasIcon,
  $hasCloseIcon,
  ...props
}: NotificationElementProps) => {
  const [showNotification, setShowNotification] = useState(true);

  const dismissNotification = useWrappedFunction(() => setShowNotification(false));

  const renderDescription = useMemo(() => {
    return title ? (
      <NotificationDescriptionSmallStyle variant="body1">
        {description}
      </NotificationDescriptionSmallStyle>
    ) : (
      <NotificationDescriptionStyle variant="body1">{description}</NotificationDescriptionStyle>
    );
  }, [title, description]);

  const derivedAriaLive =
    variation !== 'urgent' || type === 'info' || type === 'success' ? 'polite' : 'assertive';
  const derivedAriaDescribedBy = useTextRepresentation(title ? title : description);

  const renderNotificationIcon = useNotificationIcon(type, variation, $hasIcon);

  const renderCloseIcon = useMemo(() => {
    return (
      variation === 'default' &&
      !$hasIcon &&
      $hasCloseIcon && (
        <OwnUpIconButtonNeutral
          name="Dismiss Notification"
          size={1.5}
          icon={<MicroCloseIcon />}
          onClick={dismissNotification}
          aria-label="close"
        />
      )
    );
  }, [variation, $hasIcon, $hasCloseIcon, dismissNotification]);

  return (
    <StyledNotification description={description} {...props}>
      {showNotification && (
        <StyledCard
          variation={variation}
          type={type}
          aria-live={derivedAriaLive}
          aria-describedby={derivedAriaDescribedBy}
          description={description}
          tabIndex={0}
          // The below CSS line gets overriden when placed in the styles file.
          style={{ paddingBottom: children ? 0 : `${notificationBackgroundSpacing}` }}
        >
          <OwnUpThemeProvider theme={ownUpWhiteTheme}>
            {children && <StyledCardContent $hasIcon={$hasIcon}>{children}</StyledCardContent>}
            <StyledCardActions $hasCloseIcon={$hasCloseIcon}>
              {renderNotificationIcon}
              <TitleAndDescriptionDiv description={description}>
                {title && <NotificationTitleStyle variant="body1">{title}</NotificationTitleStyle>}
                {renderDescription}
              </TitleAndDescriptionDiv>
              {renderCloseIcon}
            </StyledCardActions>
          </OwnUpThemeProvider>
        </StyledCard>
      )}
    </StyledNotification>
  );
};
