import React from 'react';
import { OwnUpAccordion } from './element';
import { OwnUpAccordionProps } from './properties';

export const OwnUpFillAccordion = ({ children, ...props }: OwnUpAccordionProps) => {
  return (
    <OwnUpAccordion
      title={props.title}
      defaultExpanded={props.defaultExpanded}
      $isLineAccordion={false}
      $isSlimLineAccordion={false}
      {...props}
    >
      {children}
    </OwnUpAccordion>
  );
};
