import React from 'react';
import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export interface TooltipThemeTokens {
  tooltipFontColor: React.CSSProperties['color'];
  tooltipBackgroundColor: React.CSSProperties['color'];
  tooltipBorderColor: React.CSSProperties['color'];
  tooltipBoxShadow: React.CSSProperties['color'];
}

type TooltipTheme = {
  [key in ThemeNames]: TooltipThemeTokens;
};

const lightColors = {
  tooltipFontColor: colors.CHARCOAL_100,
  tooltipBackgroundColor: colors.WHITE_100,
  tooltipBorderColor: colors.FOG_60,
  tooltipBoxShadow: `4.0px 4.0px 8.0px 0px ${colors.CHARCOAL_5}`
};

const darkColors = {
  tooltipFontColor: colors.WHITE_100,
  tooltipBackgroundColor: colors.CHARCOAL_90,
  tooltipBorderColor: colors.CHARCOAL_90,
  tooltipBoxShadow: 'none'
};

export const tooltipTheme: TooltipTheme = {
  [ThemeNames.White]: darkColors,
  [ThemeNames.Light]: darkColors,
  [ThemeNames.Midtone]: darkColors,
  [ThemeNames.Dark]: lightColors
};
