import styled, { css } from 'styled-components';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { stickyHoverMixin } from '../core/theme/mixins/sticky-hover';
import { PropsWithTheme } from '../core/theme/properties';
import { ownUpWhiteTheme } from '../core/theme/theme';
import { aktivGroteskMediumMixin } from '../fonts/mixins';
import { OwnUpIcon } from '../icons/component';
import { OwnUpBodyMedium, OwnUpBodyProps, smallBodyMixin } from '../typography';
import { OwnUpButtonProps } from './properties';
import { LINE_BUTTON_VARIANT, SOCIAL_BUTTON_VARIANT } from './theme';

// These should be moved to somewhere common
const borderRadius = 8;
const shadowCharcoal20 = '4.0px 4.0px 8.0px 0px rgba(29, 29, 31, 0.2)';

// Button focus
const buttonBorderWidth = ownUpWhiteTheme.spacing(0.25) + 'px';
export const buttonFocusWidth = buttonBorderWidth;
export const buttonFocusInset = ownUpWhiteTheme.spacing(0.25) + 'px';
export const buttonFocusRadius = '6px';

// General color and spacing
const buttonBackgroundSpacingHorizonatal = ownUpWhiteTheme.spacing(4) + 'px';
const buttonBackgroundSpacingVertical = ownUpWhiteTheme.spacing(2) + 'px';
const buttonBackgroundRadius = borderRadius + 'px';
const buttonShadow = shadowCharcoal20;
const actionButtonRadius = borderRadius * 3.5 + 'px';
const socialButtonBackgroundSpacingHorizonatal = ownUpWhiteTheme.spacing(3) + 'px';
export const slimButtonBackgroundSpacingHorizonatal = ownUpWhiteTheme.spacing(2.5) + 'px';
const slimButtonBackgroundSpacingVertical = ownUpWhiteTheme.spacing(1.75) + 'px';

// Icon size and spacing
const buttonIconSize = '1.25rem';
const socialButtonIconSize = '2rem';
const buttonIconSpacing = ownUpWhiteTheme.spacing(1) + 'px';
const buttonBackgroundSpacingIcon = ownUpWhiteTheme.spacing(2.25) + 'px';
const socialButtonBackgroundSpacingIcon = ownUpWhiteTheme.spacing(1.5) + 'px';

const buttonSpacing = css`
  :first-child {
    margin-left: ${buttonBackgroundSpacingHorizonatal};
  }
  :last-child {
    margin-right: ${buttonBackgroundSpacingHorizonatal};
  }
`;

const slimButtonSpacing = css`
  :first-child {
    margin-left: ${slimButtonBackgroundSpacingHorizonatal};
  }
  :last-child {
    margin-right: ${slimButtonBackgroundSpacingHorizonatal};
  }
`;

const socialButtonSpacing = css`
  :first-child {
    margin-left: ${socialButtonBackgroundSpacingHorizonatal};
  }
  :last-child {
    margin-right: ${buttonBackgroundSpacingHorizonatal};
  }
  :only-child {
    margin-left: ${socialButtonBackgroundSpacingHorizonatal};
    margin-right: ${socialButtonBackgroundSpacingHorizonatal};
  }
`;

export const IconStyle = styled(OwnUpIcon)<OwnUpButtonProps>`
  display: inline-block;

  ${buttonSpacing}
  margin: ${buttonBackgroundSpacingIcon} ${buttonIconSpacing};

  vertical-align: middle;
  svg {
    vertical-align: middle;
    height: ${buttonIconSize};
    width: ${buttonIconSize};
  }

  /* smaller spacing if icon with no text */
  :only-child {
    font-size: 0;
    margin: ${buttonBackgroundSpacingIcon} ${buttonBackgroundSpacingIcon};
  }
`;

export const TextStyle = styled(OwnUpBodyMedium)<OwnUpBodyProps>`
  ${buttonSpacing}
  display: inline-block;
  margin-top: ${buttonBackgroundSpacingVertical};
  margin-bottom: ${buttonBackgroundSpacingVertical};
  vertical-align: middle;
`;

export const slimTextStyle = css`
  .MuiTypography-root {
    ${aktivGroteskMediumMixin}
    ${smallBodyMixin}
    ${slimButtonSpacing}
    margin-top: ${slimButtonBackgroundSpacingVertical};
    margin-bottom: ${slimButtonBackgroundSpacingVertical};
    text-transform: none;
  }
`;

export const slimIconStyle = css`
  .ou-icon {
    ${slimButtonSpacing}
    margin: ${slimButtonBackgroundSpacingVertical} ${buttonIconSpacing};
    margin-top: ${slimButtonBackgroundSpacingVertical};
    margin-bottom: ${slimButtonBackgroundSpacingVertical};
    vertical-align: middle;
    /* smaller spacing if icon with no text */
    :only-child {
      font-size: 0;
      margin: ${buttonBackgroundSpacingVertical};
    }
  }
`;

export const baseButtonStyle = css`
  text-transform: unset;
  transition: unset;
  min-width: unset;
  margin: 0;
  padding: 0;
  /* having a font-size for the icon only button messes up the height.  But this needs to be set for all other cases */
  font-size: 0;
  box-sizing: border-box;
  border-radius: ${buttonBackgroundRadius};
`;

export const actionButtonStyle = css`
  box-shadow: ${buttonShadow};
  border-radius: ${actionButtonRadius};

  &:focus-visible {
    &::after {
      border-radius: ${actionButtonRadius};
    }
  }
`;

export const lineButtonHoverAndFocusStyle = css`
  /* 'FF' added to background color for 100% opacity */
  background-color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    theme.buttonColors[LINE_BUTTON_VARIANT].buttonBackgroundColorActive}FF;
`;

export const lineButtonStyle = css`
  ${baseButtonStyle}
  box-sizing: border-box;
  display: block;

  /* '00' added to background color for 0% opacity */
  background-color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    theme.buttonColors[LINE_BUTTON_VARIANT].buttonBackgroundColorActive}00;

  color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    theme.buttonColors[LINE_BUTTON_VARIANT].buttonFontColor};

  box-shadow: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    `0px 0px 0px ${buttonFocusWidth} ${theme.buttonColors[LINE_BUTTON_VARIANT].buttonBorderColor} inset`};

  ${stickyHoverMixin} {
    &:hover:not(&[aria-disabled='true']) {
      ${lineButtonHoverAndFocusStyle}
      color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
        theme.buttonColors[LINE_BUTTON_VARIANT].buttonFontColorHover}
    }
  }
  &${focusVisibleSelectorMixin} {
    ${lineButtonHoverAndFocusStyle}
    color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
      theme.buttonColors[LINE_BUTTON_VARIANT].buttonFontColorFocus};
  }
`;

export const socialButtonStyle = css`
  ${baseButtonStyle}

  box-shadow: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    `0px 0px 0px ${buttonFocusWidth} ${theme.buttonColors[SOCIAL_BUTTON_VARIANT].buttonBorderColor} inset`};

  color: ${({ theme }: PropsWithTheme<OwnUpButtonProps>) =>
    theme.buttonColors[SOCIAL_BUTTON_VARIANT].buttonFontColor};

  .ou-icon {
    ${socialButtonSpacing}
    margin: ${socialButtonBackgroundSpacingIcon} ${buttonIconSpacing};

    vertical-align: middle;
    svg {
      vertical-align: middle;
      height: ${socialButtonIconSize};
      width: ${socialButtonIconSize};
    }
  }
`;
