import styled from 'styled-components';
import { PropsWithTheme } from '../core/theme/properties';
import { createOwnUpButtonThemeStyle, OwnUpButton } from './buttons-common';
import { OwnUpButtonProps } from './properties';
import {
  FILL_BUTTON_PRIMARY_ACCENT_VARIANT,
  FILL_BUTTON_PRIMARY_VARIANT,
  FILL_BUTTON_SECONDARY_ACCENT_VARIANT,
  FILL_BUTTON_SECONDARY_VARIANT
} from './theme';

export const fillButtonPrimaryVariantStyle = createOwnUpButtonThemeStyle(
  FILL_BUTTON_PRIMARY_VARIANT
);

export const OwnUpFillButtonPrimary = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${fillButtonPrimaryVariantStyle}
`;

const fillButtonPrimaryAccentVariantStyle = createOwnUpButtonThemeStyle(
  FILL_BUTTON_PRIMARY_ACCENT_VARIANT
);
export const OwnUpFillButtonPrimaryAccent = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${fillButtonPrimaryAccentVariantStyle}
`;

const fillButtonSecondaryThemeStyle = createOwnUpButtonThemeStyle(FILL_BUTTON_SECONDARY_VARIANT);
export const OwnUpFillButtonSecondary = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${fillButtonSecondaryThemeStyle}
`;

const fillButtonSecondaryAccentThemeStyle = createOwnUpButtonThemeStyle(
  FILL_BUTTON_SECONDARY_ACCENT_VARIANT
);
export const OwnUpFillButtonSecondaryAccent = styled(OwnUpButton)<PropsWithTheme<OwnUpButtonProps>>`
  ${fillButtonSecondaryAccentThemeStyle}
`;
