import React, { useCallback } from 'react';
import { OwnUpIconButton, OwnUpIconButtonAccent } from '../buttons/icon-buttons';
import { preventEventPropagation } from '../hooks/prevent-event-propagation';
import { MicroQuestionIcon } from '../icon-library/system-icons/micro-icons/question';
import { OwnUpTooltip } from '../tooltips';
import {
  OwnUpBody,
  OwnUpBodyMedium,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium
} from '../typography/body';
import { CreateIconTooltipTriggerProps, OwnUpIconTooltipTriggerProps } from './properties';
import { IconStyle, IconTextStyle, IconTooltipTriggerStyle } from './styles';

/**
 * This component should be wrapped in the OwnUpTooltip component, in order for the tooltip
 *   to trigger. This tooltip trigger contains an icon.
 */

export const OwnUpIconTooltipTriggerImpl = (props: CreateIconTooltipTriggerProps) => {
  const {
    icon,
    iconPosition,
    children,
    tooltipTitle,
    description,
    tooltipPlacement,
    additionalTooltipProps
  } = props;
  const handleOnMouseDown = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    preventEventPropagation(event);
  }, []);
  const styledIcon = <IconStyle {...props}>{icon}</IconStyle>;
  return (
    <IconTooltipTriggerStyle tabIndex={0} {...props} onMouseDown={handleOnMouseDown}>
      {iconPosition === 'left' && (
        <OwnUpTooltip
          tooltipTitle={tooltipTitle}
          description={description}
          placement={tooltipPlacement}
          {...additionalTooltipProps}
        >
          {styledIcon}
        </OwnUpTooltip>
      )}
      <IconTextStyle>{children}</IconTextStyle>
      {iconPosition !== 'left' && (
        <OwnUpTooltip
          tooltipTitle={tooltipTitle}
          description={description}
          placement={tooltipPlacement}
          {...additionalTooltipProps}
        >
          {styledIcon}
        </OwnUpTooltip>
      )}
    </IconTooltipTriggerStyle>
  );
};

const largeIcon = (
  <OwnUpIconButton
    icon={MicroQuestionIcon}
    name="Tooltip"
    aria-label={'tooltip'}
    size={1.25}
    title="" // Do not use the name for the title attribute on the base element (button).
  />
);
const smallIcon = (
  <OwnUpIconButton
    icon={MicroQuestionIcon}
    name="Tooltip"
    aria-label={'tooltip'}
    size={1.125}
    title="" // Do not use the name for the title attribute on the base element (button).
  />
);

export const OwnUpIconTooltipTriggerBodyMedium = (props: OwnUpIconTooltipTriggerProps) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={largeIcon}>
      <OwnUpBodyMedium variant="body1">{props.children}</OwnUpBodyMedium>
    </OwnUpIconTooltipTriggerImpl>
  );
};

export const OwnUpIconTooltipTriggerBodyRegular = (props: OwnUpIconTooltipTriggerProps) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={largeIcon}>
      <OwnUpBody variant="body1">{props.children}</OwnUpBody>
    </OwnUpIconTooltipTriggerImpl>
  );
};

export const OwnUpIconTooltipTriggerSmallBodyMedium = (props: OwnUpIconTooltipTriggerProps) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={smallIcon}>
      <OwnUpSmallBodyMedium variant="body1">{props.children}</OwnUpSmallBodyMedium>
    </OwnUpIconTooltipTriggerImpl>
  );
};

export const OwnUpIconTooltipTriggerSmallBodyRegular = (props: OwnUpIconTooltipTriggerProps) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={smallIcon}>
      <OwnUpSmallBody variant="body1">{props.children}</OwnUpSmallBody>
    </OwnUpIconTooltipTriggerImpl>
  );
};

const largeIconAccent = (
  <OwnUpIconButtonAccent
    icon={MicroQuestionIcon}
    name="Tooltip"
    aria-label={'tooltip'}
    size={1.25}
    title="" // Do not use the name for the title attribute on the base element (button).
  />
);
const smallIconAccent = (
  <OwnUpIconButtonAccent
    icon={MicroQuestionIcon}
    name="Tooltip"
    aria-label={'tooltip'}
    size={1.125}
    title="" // Do not use the name for the title attribute on the base element (button).
  />
);

export const OwnUpIconTooltipTriggerBodyMediumAccent = (props: OwnUpIconTooltipTriggerProps) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={largeIconAccent}>
      <OwnUpBodyMedium variant="body1">{props.children}</OwnUpBodyMedium>
    </OwnUpIconTooltipTriggerImpl>
  );
};

export const OwnUpIconTooltipTriggerBodyRegularAccent = (props: OwnUpIconTooltipTriggerProps) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={largeIconAccent}>
      <OwnUpBody variant="body1">{props.children}</OwnUpBody>
    </OwnUpIconTooltipTriggerImpl>
  );
};

export const OwnUpIconTooltipTriggerSmallBodyMediumAccent = (
  props: OwnUpIconTooltipTriggerProps
) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={smallIconAccent}>
      <OwnUpSmallBodyMedium variant="body1">{props.children}</OwnUpSmallBodyMedium>
    </OwnUpIconTooltipTriggerImpl>
  );
};

export const OwnUpIconTooltipTriggerSmallBodyRegularAccent = (
  props: OwnUpIconTooltipTriggerProps
) => {
  return (
    <OwnUpIconTooltipTriggerImpl {...props} icon={smallIconAccent}>
      <OwnUpSmallBody variant="body1">{props.children}</OwnUpSmallBody>
    </OwnUpIconTooltipTriggerImpl>
  );
};
