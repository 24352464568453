import React from 'react';
import * as colors from '../../../colors';
import { useRandomId } from '../../../hooks/use-random-id';

export const CloseLargeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1724_42921)">
      <path
        d="M16.7803 15.7196C17.0732 16.0125 17.0732 16.4874 16.7803 16.7803C16.4874 17.0732 16.0126 17.0732 15.7197 16.7803L10 11.0606L4.28034 16.7803C3.98745 17.0732 3.51258 17.0732 3.21968 16.7803C2.92679 16.4874 2.92679 16.0125 3.21968 15.7196L8.93935 9.99994L3.21802 4.27862C2.92513 3.98573 2.92513 3.51085 3.21802 3.21796C3.51092 2.92507 3.98579 2.92507 4.27868 3.21796L10 8.93928L15.7213 3.21796C16.0142 2.92507 16.4891 2.92507 16.782 3.21796C17.0749 3.51085 17.0749 3.98573 16.782 4.27862L11.0607 9.99995L16.7803 15.7196Z"
        fill={colors.CHARCOAL_100}
      />
    </g>
    <defs>
      <clipPath id={useRandomId('clip')}>
        <rect width="14" height="14" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
