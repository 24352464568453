// See polyfills section of README file for information on how
//   to properly inject polyfills.
/* eslint-disable import/extensions */
import focusVisible from 'focus-visible/dist/focus-visible.min.js';

/**
 * Mapping of Polyfills to their proper import. Note that this is
 *   distinct from the resolved paths in the polyfills config file
 *   these should be your module inputs that will be converted
 *   by rollup.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const POLYFILL_MAP = { 'focus-visible': focusVisible };

/**
 * Names of various polyfill libraries
 */
export type PolyfillName = keyof typeof POLYFILL_MAP;

/**
 * List of all polyfills loaded by this project.
 */
export const polyfillNames = Object.keys(POLYFILL_MAP) as PolyfillName[];
