import React from 'react';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '..';
import { createElementOutlineMixin } from '../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { stickyHoverMixin } from '../core/theme/mixins/sticky-hover';
import { OwnUpButton } from './buttons-common';
import { fillButtonPrimaryVariantStyle } from './fill-buttons';
import { OwnUpIconButtonProps } from './properties';
import {
  ICON_BUTTON_ACCENT_VARIANT,
  ICON_BUTTON_DEFAULT_VARIANT,
  ICON_BUTTON_NEUTRAL_VARIANT,
  ICON_BUTTON_QUIET_VARIANT,
  IconButtonColorTokens,
  IconButtonVariant
} from './theme';

/**
 * "size" is a reserved word on MUI icons and is used to generate
 *   different variants of the icon. We want to block that functionality
 *   and use the size solely for styles, so we strip it off the props
 *   before sending it to the wrapped component.
 *
 * Also attaches the required "name" field to relevant accessibility/scraper
 *   tags.
 *
 * @param {@see OwnUpIconButtonProps}
 */
const BaseIconButton = ({
  size: _,
  name,
  'aria-label': ariaLabel,
  ...props
}: OwnUpIconButtonProps) => (
  <OwnUpButton
    name={name}
    aria-label={ariaLabel || name}
    button-name={name}
    title={name}
    {...props}
  />
);

const determineIconSize = (
  size: Exclude<OwnUpIconButtonProps['size'], undefined>,
  tokens: IconButtonColorTokens
) => {
  if (size >= 2) {
    return tokens.iconSize;
  } else if (size > 1.125) {
    return tokens.iconSizeMicro;
  }
  return tokens.iconSizeMicroMicro;
};

const determineFocusSpacing = (
  size: Exclude<OwnUpIconButtonProps['size'], undefined>,
  tokens: IconButtonColorTokens
) => {
  if (size >= 2.5) {
    return tokens.focusOutlineSpacingLargeMultiplier;
  } else if (size >= 1.5) {
    return tokens.focusOutlineSpacingMultiplier;
  }
  return tokens.focusOutlineSpacingMicroMultiplier;
};

/**
 * Helper function to generate the various color schemes for
 *   the icon buttons, as well as the base styling.
 */
const createStyledIconButton = (variant: IconButtonVariant) => styled(BaseIconButton)<
  PropsWithTheme<OwnUpIconButtonProps>
>`
  ${fillButtonPrimaryVariantStyle}
  ${({
    size = 3,
    theme: {
      buttonColors: { iconButtons },
      ...theme
    }
  }: PropsWithTheme<OwnUpIconButtonProps>) => {
    const iconSize = determineIconSize(size, iconButtons);
    const focusSpacing = determineFocusSpacing(size, iconButtons);
    const colorScheme = (iconButtons[variant] || iconButtons.defaultColors)!;
    return css`
      height: ${size}rem;
      width: ${size}rem;
      border-radius: ${iconButtons.radius};
      color: ${colorScheme.color};
      background-color: ${colorScheme.backgroundColorActive};

      &[aria-disabled='true'] {
        color: ${colorScheme.color};
        cursor: default;
      }
      ${stickyHoverMixin} {
        &:hover:not(&[aria-disabled='true']),
        &[data-hover-added] {
          background-color: ${colorScheme.backgroundColorHover};
        }
      }
      &${focusVisibleSelectorMixin} {
        background-color: ${colorScheme.backgroundColorFocus};
        ${createElementOutlineMixin({
          color: colorScheme.focusOutlineColor,
          width: `${theme.spacing(iconButtons.focusOutlineSizeMultiplier)}px`,
          inset: `${theme.spacing(focusSpacing)}px`,
          radius: iconButtons.radius
        })}
      }
      svg {
        width: ${iconSize};
        height: ${iconSize};
        path {
          fill: ${colorScheme.color};
        }
      }
    `;
  }}
`;

/**
 * Default Own Up Icon Button. Wraps {@see OwnUpButton}
 *
 * @param {@see OwnUpIconButtonProps}
 */
export const OwnUpIconButton = createStyledIconButton(ICON_BUTTON_DEFAULT_VARIANT);

/**
 * Accented variant of the Own Up Icon Button. Wraps {@see OwnUpButton}
 *
 * @param {@see OwnUpIconButtonProps}
 */
export const OwnUpIconButtonAccent = createStyledIconButton(ICON_BUTTON_ACCENT_VARIANT);

/**
 * Quiet variant of the Own Up Icon Button. Wraps {@see OwnUpButton}
 *
 * @param {@see OwnUpIconButtonProps}
 */
export const OwnUpIconButtonQuiet = createStyledIconButton(ICON_BUTTON_QUIET_VARIANT);

/**
 * Neutral variant of the Own Up Icon Button. Wraps {@see OwnUpButton}
 *
 * @param {@see OwnUpIconButtonProps}
 */
export const OwnUpIconButtonNeutral = createStyledIconButton(ICON_BUTTON_NEUTRAL_VARIANT);
