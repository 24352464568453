import React from 'react';
import * as colors from '../../../colors';

export const ArrowRightIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5306 4.21943C13.2377 3.92653 12.7628 3.92653 12.4699 4.21943C12.177 4.51232 12.177 4.98719 12.4699 5.28009L16.4399 9.25002H1.75C1.33579 9.25002 1 9.58581 1 10C1 10.4142 1.33579 10.75 1.75 10.75H16.4399L12.4699 14.72C12.177 15.0129 12.177 15.4877 12.4699 15.7806C12.7628 16.0735 13.2377 16.0735 13.5306 15.7806L18.7809 10.5304C19.0738 10.2375 19.0738 9.76259 18.7809 9.46969L13.5306 4.21943Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
