import React from 'react';
import { OwnUpNotification } from './element';
import { OwnUpNotificationProps } from './properties';

export const OwnUpUrgentErrorNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="urgent" type="error" />;
};

export const OwnUpUrgentWarningNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="urgent" type="warning" />;
};

export const OwnUpUrgentInfoNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="urgent" type="info" />;
};

export const OwnUpUrgentSuccessNotification = (props: OwnUpNotificationProps) => {
  return <OwnUpNotification {...props} variation="urgent" type="success" />;
};
