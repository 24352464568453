import InputAdornment from '@material-ui/core/InputAdornment';
import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useCombinedClassName } from '../../hooks/use-combined-class-name';
import { useElementId } from '../../hooks/use-element-id';
import { ErrorLightIcon } from '../../icon-library/notification-icons/error-light';
import { OwnUpIcon } from '../../icons';
import { OwnUpSmallBodyMedium } from '../../typography/body';
import { OwnUpNumberInputProps, OwnUpTextInputProps } from './properties';
import {
  InputLabelText,
  InputWrapper,
  MainWrapper,
  RightSvgWrapper,
  SvgWrapper,
  TextInput
} from './styles';

const BaseTextInput = <TProps extends OwnUpTextInputProps>({
  id,
  labelPosition = 'inner',
  label,
  error,
  $hideErrorIcon,
  $endIcon,
  $leadingIcon,
  mainWrapperRef,
  inputWrapperRef,
  inputProps,
  InputProps,
  disabled,
  FormHelperTextProps,
  ...props
}: TProps) => {
  const $inputId = useElementId(id, 'text-input');
  const labelId = `${$inputId}-label`;
  const builtInputProps = useMemo(
    () => ({
      ...inputProps,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'aria-labelledby': labelPosition === 'outer' ? labelId : undefined
    }),
    [inputProps, labelId, labelPosition]
  );

  const renderEndAdornment = useMemo(() => {
    if ($endIcon) {
      return (
        <RightSvgWrapper>
          <OwnUpIcon icon={$endIcon} />
        </RightSvgWrapper>
      );
    } else if (error && !$hideErrorIcon) {
      return (
        <InputAdornment position="end">
          <OwnUpIcon icon={ErrorLightIcon} />
        </InputAdornment>
      );
    } else {
      return <></>;
    }
  }, [error, $hideErrorIcon, $endIcon]);

  return (
    <MainWrapper ref={mainWrapperRef} aria-label="textbox">
      {labelPosition === 'inner' && $leadingIcon && (
        <SvgWrapper>
          <OwnUpIcon icon={$leadingIcon} />
        </SvgWrapper>
      )}
      <InputWrapper ref={inputWrapperRef} labelPosition={labelPosition} $leadingIcon={$leadingIcon}>
        {labelPosition === 'outer' && (
          <InputLabelText id={labelId} htmlFor={$inputId}>
            <OwnUpSmallBodyMedium variant="body1" component="span">
              {label}
            </OwnUpSmallBodyMedium>
          </InputLabelText>
        )}
        <TextInput
          id={$inputId}
          {...props}
          labelPosition={labelPosition}
          $leadingIcon={$leadingIcon}
          variant="filled"
          label={labelPosition == 'inner' && label}
          error={error}
          inputProps={builtInputProps}
          disabled={disabled}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          FormHelperTextProps={{ ...FormHelperTextProps, disabled, 'aria-disabled': disabled }}
          InputProps={{
            ...InputProps,
            startAdornment: labelPosition == 'outer' && $leadingIcon && (
              <InputAdornment position="start">
                <OwnUpIcon icon={$leadingIcon} />
              </InputAdornment>
            ),
            endAdornment: renderEndAdornment
          }}
        />
      </InputWrapper>
    </MainWrapper>
  );
};

/**
 * Renders a text input.
 *
 * @param props - {@see OwnUpTextInputProps}
 * @remarks This component leverages material-ui's TextField component {@link https://v4.mui.com/api/text-field}
 */
export const OwnUpTextInput = (props: OwnUpTextInputProps) => <BaseTextInput {...props} />;

/**
 * Renders a number input.
 *
 * @param props - {@see OwnUpNumberInputProps}
 * @remarks This component leverages material-ui's TextField component {@link https://v4.mui.com/api/text-field}
 *   with {@link https://github.com/s-yadav/react-number-format}
 */
export const OwnUpNumberInput = ({
  className,
  thousandSeparator = true,
  customInput = OwnUpTextInput,
  ...props
}: OwnUpNumberInputProps) => {
  // Add a classname to the input to make it more distinguishable
  const combinedClassName = useCombinedClassName('number-input', className);

  // Render the element
  return (
    <NumberFormat
      className={combinedClassName}
      thousandSeparator={thousandSeparator}
      customInput={customInput}
      {...props}
    />
  );
};
