import Box, { BoxProps } from '@material-ui/core/Box';
import React from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../core';
import { OwnUpGridWrapperProps } from './properties';

/**
 * {@link Box} component with dynamic size and margins based
 *   on the current screen size.
 */
const StyledGridWrapper = styled(Box)<PropsWithTheme<BoxProps>>`
  /* Maximum width is equal to the xl breakpoint size */
  max-width: ${({ theme }: PropsWithTheme<BoxProps>) => `${theme.breakpoints.values.xl}px`};

  /* Dynamically set the margin based on the current screen size
   * default margin: 10x spacing factor (8px * 10 = 80px) */
  margin: ${({ theme }: PropsWithTheme<BoxProps>) => theme.spacing(0, 10)};
  /* medium breakpoint and down: 6 spacing factor (8px * 6 = 48px) */
  ${({ theme }: PropsWithTheme<BoxProps>) => theme.breakpoints.down('md')} {
    margin: ${({ theme }: PropsWithTheme<BoxProps>) => theme.spacing(0, 6)};
  }
  /* xsmall breakpoint and down: 3x spacing factor (8px * 3 = 24px) */
  ${({ theme }: PropsWithTheme<BoxProps>) => theme.breakpoints.down('xs')} {
    margin: ${({ theme }: PropsWithTheme<BoxProps>) => theme.spacing(0, 3)};
  }
  /* At xl and above, the margins should be "auto" with a minimum of 80px.
   *   Because there is no "min margin" css attribute, we can hack this by
   *   setting the maximum width of this container to be the width of the
   *   xl breakpoint, then when the screen size reaches the xl breakpoint
   *   plus 160px (80px on both sides) switching the margins to 80px and
   *   setting this container to take up 100% of its parent container.
   * This screen size check is done with a custom media query.
   */
  ${({ theme }: PropsWithTheme<BoxProps>) =>
    // media query block starts at the xl breakpoint plus 10x spacing factor (8px * 10 = 80px)
    `@media (min-width: ${theme.breakpoints.values.xl + theme.spacing(10) * 2}px)`} {
    margin: auto;
    width: 100%;
  }
`;

/**
 * A wrapper around Material UI's {@link Box} component that
 *   enforces margins and size limitations for the grid it
 *   should contain.
 *
 * Should be used to wrap all {@link OwnUpGrid} layouts.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://v4.mui.com/api/grid/}
 *
 * @param props - {@link OwnUpGridWrapperProps}
 */
export const OwnUpGridWrapper = (props: OwnUpGridWrapperProps) => <StyledGridWrapper {...props} />;
