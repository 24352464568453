import Grid from '@material-ui/core/Grid';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../core/theme/properties';
import { OwnUpGridItemProps } from './properties';
import { noDisplayMixin } from './styles';

type ZeroableGridProp =
  | OwnUpGridItemProps['xs']
  | OwnUpGridItemProps['sm']
  | OwnUpGridItemProps['md']
  | OwnUpGridItemProps['lg']
  | OwnUpGridItemProps['xl'];

const coerceZeroIntoFalse = (size: ZeroableGridProp) => (size === 0 ? false : size);

const GridItem = (props: OwnUpGridItemProps) => {
  const { xs, sm, md, lg, xl, ...other } = props;

  const validGridSizes = useMemo(
    () => ({
      xs: coerceZeroIntoFalse(xs),
      sm: coerceZeroIntoFalse(sm),
      md: coerceZeroIntoFalse(md),
      lg: coerceZeroIntoFalse(lg),
      xl: coerceZeroIntoFalse(xl)
    }),
    [xs, sm, md, lg, xl]
  );

  return <Grid item {...{ ...other, ...validGridSizes }} />;
};

const StyledGridItem = styled(GridItem)<PropsWithTheme<OwnUpGridItemProps>>`
  ${noDisplayMixin}
`;

/**
 * A wrapper around Material UI's {@link Grid} element with the
 *   `item` property forced on.
 * Uses an intermediary GridItem component to apply the desired styling
 *   to hide the element if the `xs` property, etc., is 0 without
 *   passing invalid props to the underlying Grid element.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://v4.mui.com/api/grid/}
 *
 * @param props - {@link OwnUpGridItemProps}
 */
export const OwnUpGridItem = (props: OwnUpGridItemProps) => <StyledGridItem {...props} />;
