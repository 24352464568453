import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import * as colors from '../../colors';
import { ownUpWhiteTheme, Z_INDICES } from '../../core/theme';
import { focusVisibleSelectorMixin } from '../../core/theme/mixins/focus-visible-selector';
import { PropsWithTheme } from '../../core/theme/properties';
import { ownUpBodyItalicMixin, ownUpBodyMixin } from '../../typography/body/mixins';
import {
  innerLabelFontSizeSelected,
  inputBackgroundRadius,
  inputBackgroundRadiusBottom,
  inputBackgroundRadiusTop,
  inputBackgroundSpacing,
  inputFontSizePlaceholder,
  inputFontSizeResponse,
  inputFontSpacingBottomEntry,
  inputFontSpacingHorizontal,
  inputFontSpacingTopEntry,
  inputIconSizeDropdown,
  inputPadding,
  inputRightPaddingMixin,
  inputScrollbarSpacing,
  inputScrollbarWidth
} from '../common/form-control/style';
import { DropdownTextInput } from '../text-input/styles';
import { OwnUpDropdownProps, OwnUpMenuItemProps } from './properties';

const outerLabelSelectorStyle = css<PropsWithTheme<OwnUpDropdownProps>>`
  padding: ${inputPadding};
`;

const innerLabelSelectorStyle = css<PropsWithTheme<OwnUpDropdownProps>>`
  line-height: ${innerLabelFontSizeSelected};
  padding: calc(${inputFontSpacingTopEntry} + ${innerLabelFontSizeSelected})
    ${inputFontSpacingHorizontal} calc(${inputFontSpacingBottomEntry}) ${inputFontSpacingHorizontal};
`;

const selectedMixin = css<PropsWithTheme<{}>>`
  ${ownUpBodyMixin}
  color: ${({ theme }: PropsWithTheme<{}>) => theme.inputColors.inputFontColorResponse};
`;

const unselectedMixin = css<PropsWithTheme<{}>>`
  ${ownUpBodyItalicMixin}
  line-height: ${inputFontSizePlaceholder};
  color: ${({ theme }: PropsWithTheme<{}>) => theme.inputColors.inputFontColorPlaceholder};
`;

export const StyledSelect = styled(Select)<PropsWithTheme<OwnUpDropdownProps>>`
  & .MuiSelect-root {
    background-color: ${({ theme }: PropsWithTheme<OwnUpDropdownProps>) =>
      theme.inputColors.inputBackgroundColor};
    line-height: ${inputFontSizeResponse};
    border-radius: ${inputBackgroundRadius} ${inputBackgroundRadius} 0 0;

    ${({ labelPosition }) =>
      labelPosition === 'outer' ? outerLabelSelectorStyle : innerLabelSelectorStyle};

    ${({ value }) => (value === '' ? unselectedMixin : selectedMixin)}

    ${inputRightPaddingMixin}
  }

  & > svg {
    position: absolute;
    right: ${inputFontSpacingHorizontal};
    pointer-events: none;
    margin: 0;
    width: ${inputIconSizeDropdown};
    height: ${inputIconSizeDropdown};

    & path {
      fill: ${({ theme }: PropsWithTheme<OwnUpDropdownProps>) => theme.inputColors.inputUnderline};
    }
  }

  &.Mui-error {
    & > svg {
      & path {
        fill: ${({ theme }: PropsWithTheme<OwnUpDropdownProps>) =>
          theme.inputColors.inputIconColorError};
      }
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)<PropsWithTheme<OwnUpMenuItemProps>>`
  ${ownUpBodyMixin}
  color: ${({ theme }: PropsWithTheme<OwnUpMenuItemProps>) =>
    theme.inputColors.inputFontColorResponse};
  padding: ${inputBackgroundSpacing};
  /* Don't allow placeholder elements to be selected */
  &.placeholder {
    display: none;
  }
  /* HACK: MUI injects its own "Mui-selected" classes
   *   so use this selector instead to not cause conflicts */
  &[aria-selected='true'] {
    /* Override MUI style */
    background-color: transparent;
  }
  &:hover,
  &[aria-selected='true']:hover {
    background-color: ${({ theme }: PropsWithTheme<OwnUpMenuItemProps>) =>
      theme.inputColors.inputBackgroundColorHover};
  }

  &:focus,
  &${focusVisibleSelectorMixin}, &${focusVisibleSelectorMixin}:hover {
    background-color: ${({ theme }: PropsWithTheme<OwnUpMenuItemProps>) =>
      theme.inputColors.inputBackgroundColorFocus};
  }
`;

/**
 * Like tooltip popups, dropdown menus are rendered outside of its parent
 *   element so we must use Material's style engine to override its styles.
 */
export const dropdownMenuStyles = makeStyles(() => {
  // Dropdown menu styles are forced to the white theme.
  const theme = ownUpWhiteTheme;

  const borderStyle = `1px solid ${theme.inputColors.inputBorderColor}`;
  const borderRadiusStyle = `${inputBackgroundRadiusTop} ${inputBackgroundRadiusTop} ${inputBackgroundRadiusBottom} ${inputBackgroundRadiusBottom}`;

  const commonScrollbarStyles = {
    borderRadius: `calc(${inputScrollbarSpacing} * 2)`,
    // HACK: There is no webkit support for scrollbar padding so we
    //   simulate it by adding a border to the scrollbar.
    border: `${inputScrollbarSpacing} solid transparent`,
    // Ensures the element does not render over its own transparent
    //   border.
    backgroundClip: 'padding-box'
  };
  return {
    paper: ({ menuPosition }: OwnUpDropdownProps) => {
      // Styles for specific anchoring contexts
      const positionStyles: React.CSSProperties = {
        borderTop: borderStyle,
        borderBottom: borderStyle,
        borderLeft: borderStyle,
        borderRight: borderStyle,
        borderRadius: borderRadiusStyle
      };
      if (menuPosition === 'top') {
        positionStyles.marginTop = '-1px';
        positionStyles.borderBottom = '0';
        positionStyles.borderRadius = `${inputBackgroundRadiusTop} ${inputBackgroundRadiusTop} 0 0`;
      } else if (menuPosition === 'bottom') {
        positionStyles.marginTop = '-1px';
        positionStyles.borderTop = '0';
        positionStyles.borderRadius = `0 0 ${inputBackgroundRadiusBottom} ${inputBackgroundRadiusBottom}`;
      }
      // Only apply position styles when the menu is actually anchored to the menu,
      //   which doesn't always happen if the screen is too small.
      const menuIsAttachedSelector =
        menuPosition === 'bottom'
          ? // Only use the attached style when the popover has not drifted from the element
            "&[style*='transform-origin: 0px 0px']"
          : // Always use the attached style
            '&';
      return {
        backgroundColor: theme.inputColors.inputBackgroundColorActive,
        boxShadow: `4.0px 4.0px 8.0px 0px ${colors.CHARCOAL_5}`,
        border: borderStyle,
        '&.MuiAutocomplete-paper': {
          margin: 0
        },
        [menuIsAttachedSelector]: positionStyles,
        ['&::-webkit-scrollbar']: {
          // HACK: Add extra width to the scrollbar to account for the border.
          width: `calc((${inputScrollbarSpacing} * 2) + ${inputScrollbarWidth})`
        },
        ['&::-webkit-scrollbar-track']: {
          backgroundColor: theme.inputColors.inputBackgroundColorHover,
          ...commonScrollbarStyles
        },
        ['&::-webkit-scrollbar-thumb']: {
          backgroundColor: theme.palette.primary.main,
          ...commonScrollbarStyles
        }
      };
    },
    option: {
      zIndex: Z_INDICES.DROPDOWN_OPTIONS,
      width: '100%',
      '&.MuiAutocomplete-option': {
        padding: 0
      },
      '&.MuiAutocomplete-option[aria-selected="true"], &.MuiAutocomplete-option[data-focus="true"]':
        {
          backgroundColor: theme.inputColors.inputBackgroundColorHover
        }
    },
    noOptions: {
      '&.MuiAutocomplete-noOptions': {
        color: theme.inputColors.inputFontColorResponse,
        padding: inputBackgroundSpacing,
        // Cannot use mixin here since makeStyles does
        //  not support styled-component's css objects
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.01em',
        fontFamily: 'Aktiv-Grotesk-Regular, Helvetica, sans-serif',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal'
      }
    },
    listbox: {
      '&.MuiAutocomplete-listbox': {
        padding: 0,
        // Note: the height of the autoscroll depends on
        //  this max-height
        maxHeight: '300px',
        ['&::-webkit-scrollbar']: {
          width: `calc((${inputScrollbarSpacing} * 2) + ${inputScrollbarWidth})`
        },
        ['&::-webkit-scrollbar-track']: {
          backgroundColor: theme.inputColors.inputBackgroundColorHover,
          ...commonScrollbarStyles
        },
        ['&::-webkit-scrollbar-thumb']: {
          backgroundColor: theme.palette.primary.main,
          ...commonScrollbarStyles
        }
      }
    },
    list: ({}) => ({
      padding: 0
    })
  };
});

// Filter Dropdowns
export const StyledDropdownTextInput = styled(DropdownTextInput)<
  PropsWithTheme<{ hasValue: boolean }>
>`
  /** 
  * The "hasValue" prop is used to track when an option is selected (i.e. "value !==''" ),
  *  but does not track the value when the user is typing (ie "hasValue=false" in this case).
  */
  ${({ placeholder, hasValue }) => {
    return placeholder && !hasValue
      ? css`
          .MuiAutocomplete-inputRoot {
            ${unselectedMixin}
          }
          .MuiInputBase-input {
            color: ${({ theme }: PropsWithTheme<{}>) => theme.inputColors.inputFontColorLabelAbove};
          }
          .MuiAutocomplete-inputFocused {
            &${focusVisibleSelectorMixin} {
              opacity: 1;
            }
          }
        `
      : css`
          .MuiAutocomplete-inputRoot {
            ${selectedMixin}
          }
        `;
  }}
`;
