import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../core';
import {
  ownUpDetailMediumMixin,
  ownUpDetailMixin,
  ownUpSmallDetailMediumMixin,
  ownUpSmallDetailMixin
} from './mixins';
import { OwnUpDetailProps } from './properties';

const Detail = (props: OwnUpDetailProps) => <Typography {...props} />;

export const OwnUpDetail = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${ownUpDetailMixin}
`;

export const OwnUpDetailMedium = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${ownUpDetailMediumMixin}
`;

export const OwnUpSmallDetail = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${ownUpSmallDetailMixin}
`;

export const OwnUpSmallDetailMedium = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${ownUpSmallDetailMediumMixin}
`;
