import Accordion from '@material-ui/core/Accordion';
import AccordionSummary, { AccordionSummaryProps } from '@material-ui/core/AccordionSummary';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../core/theme';
import { createElementOutlineMixin } from '../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { OwnUpIcon, OwnUpIconProps } from '../icons';
import {
  OwnUpBody,
  OwnUpBodyMedium,
  OwnUpBodyProps,
  ownUpDetailMixin,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium,
  ownUpSmallBodyMixin
} from '../typography';
import { whiteThemeSpacingBuilderPX } from './helpers';
import { OwnUpAccordionProps } from './properties';

const accordionBackgroundRadius = '4px';

// Spacing
// Between accordions
const accordionSpacing = whiteThemeSpacingBuilderPX(1.5);
// Between the title and description
const accordionFontSpacing = whiteThemeSpacingBuilderPX(2.5);
const lineAccordionFontSpacing = whiteThemeSpacingBuilderPX(2);
const accordionFontSpacingSlim = whiteThemeSpacingBuilderPX(1.75);
// Background
const accordionBackgroundSpacingTextTop = whiteThemeSpacingBuilderPX(2.5);
const accordionBackgroundSpacingTextBottomCollapsed = whiteThemeSpacingBuilderPX(2.5);
const accordionBackgroundSpacingTextBottomExpanded = whiteThemeSpacingBuilderPX(3);
const accordionBackgroundSpacingTextHorizontal = whiteThemeSpacingBuilderPX(3);
// Icon Button
const accordionIconSpacing = whiteThemeSpacingBuilderPX(2);
const accordionBackgroundSpacingIconTop = whiteThemeSpacingBuilderPX(2);
const accordionBackgroundSpacingIconHorizontal = whiteThemeSpacingBuilderPX(2.5);
// Spacing for line accordions
// Background
const lineAccordionBackgroundSpacingTextTop = whiteThemeSpacingBuilderPX(2);
const lineAccordionBackgroundSpacingTextBottomCollapsed = whiteThemeSpacingBuilderPX(2);
const lineAccordionBackgroundSpacingTextHorizontal = whiteThemeSpacingBuilderPX(1.5);
// Icon
const lineAccordionBackgroundSpacingIconTop = whiteThemeSpacingBuilderPX(2.25);
const lineAccordionBackgroundSpacingIconHorizontal = whiteThemeSpacingBuilderPX(1.25);
// Spacing for slim line accordions
// Background
const accordionBackgroundSpacingTextTopSlim = whiteThemeSpacingBuilderPX(1.75);
const accordionBackgroundSpacingTextBottomCollapsedSlim = whiteThemeSpacingBuilderPX(1.75);
const accordionBackgroundSpacingTextBottomExpandedSlim = whiteThemeSpacingBuilderPX(2.5);
// Icon
const accordionBackgroundSpacingIconTopSlim = whiteThemeSpacingBuilderPX(1.75);

// Focus
const accordionFocusRadius = '4px';
const accordionFocusWidth = '2px';
const accordionFocusStyle = 'solid';
// line accordions
const lineAccordionFocusRadius = '2px';

// Border
// Line accordion border
const lineAccordionBorderWidth = '1px';

interface AccordionInternalProps {
  $isLineAccordion?: boolean;
  $isSlimLineAccordion?: boolean;
  $expandIconPosition?: 'left' | 'right';
}

interface StyledAccordionProps extends OwnUpAccordionProps, AccordionInternalProps {
  $focusVisible?: boolean;
}

// FILL ACCORDIONS
const fillAccordionStyle = css`
  border-radius: ${accordionBackgroundRadius};
  box-shadow: none;
  &:not(:first-child) {
    margin-top: ${accordionSpacing};
  }
  &:before {
    height: 0px;
  }
`;

// LINE ACCORDIONS
export const LineAccordionIcon = styled(OwnUpIcon)<PropsWithTheme<OwnUpIconProps>>`
  svg {
    path {
      fill: ${({ theme }: PropsWithTheme<OwnUpIconProps>) =>
        theme.accordionColors.accordionIconColor};
    }
  }
`;

const lineAccordionStyle = css`
  box-shadow: none;
  &.Mui-expanded {
    margin: 0px;
  }
  &.Mui-focused {
    border-width: 0px;
  }
  &.MuiAccordion-rounded:first-child {
    border-radius: 0px;
  }
  &.MuiAccordion-rounded:last-child {
    border-radius: 0px;
  }
  &:before {
    height: 0px;
  }
`;

export const StyledAccordion = styled(Accordion)<PropsWithTheme<StyledAccordionProps>>`
  ${(props) => (props.$isLineAccordion ? lineAccordionStyle : fillAccordionStyle)};
  background-color: ${({ $isLineAccordion, theme }: PropsWithTheme<StyledAccordionProps>) =>
    $isLineAccordion ? 'transparent' : theme.accordionColors.accordionBackgroundColor};
  .MuiAccordionDetails-root {
    background-color: transparent;
    padding: ${({ $isLineAccordion }) =>
      `0px ${
        $isLineAccordion
          ? lineAccordionBackgroundSpacingTextHorizontal
          : accordionBackgroundSpacingTextHorizontal
      }`};
  }
  ${({
    $focusVisible,
    theme,
    $isLineAccordion,
    $isSlimLineAccordion
  }: PropsWithTheme<StyledAccordionProps>) => {
    if ($isLineAccordion || $isSlimLineAccordion) {
      return $focusVisible
        ? css`
    &.Mui-expanded {   
          &${createElementOutlineMixin({
            color: theme.accordionColors.accordionFocusColor,
            width: accordionFocusWidth,
            style: accordionFocusStyle,
            radius: lineAccordionFocusRadius
          })}
          }
              `
        : css`
            :first-child {
              box-shadow:
                0 ${lineAccordionBorderWidth} 0 0
                  ${({ theme: innerTheme }: PropsWithTheme<StyledAccordionProps>) =>
                    innerTheme.accordionColors.accordionBorderColor},
                0 -${lineAccordionBorderWidth} 0 0 ${({ theme: innerTheme }: PropsWithTheme<StyledAccordionProps>) => innerTheme.accordionColors.accordionBorderColor};
            }
            &:not(:first-child) {
              box-shadow: 0 ${lineAccordionBorderWidth} 0 0
                ${({ theme: innerTheme }: PropsWithTheme<StyledAccordionProps>) =>
                  innerTheme.accordionColors.accordionBorderColor};
            }
          `;
    } else {
      return $focusVisible
        ? css`
          &.Mui-expanded {
            &${createElementOutlineMixin({
              color: theme.accordionColors.accordionFocusColor,
              width: accordionFocusWidth,
              style: accordionFocusStyle,
              radius: accordionFocusRadius
            })}            
             }`
        : undefined;
    }
  }}
`;

const accordionSummaryStyle = css`
  padding-right: 0px;
  &.Mui-expanded {
    min-height: 24px;
  }
  .MuiAccordionSummary-expandIcon {
    transition: none;
    margin-bottom: auto;
    &.Mui-expanded {
      transform: none;
    }
  }
`;

interface StyledAccordionSummaryProps extends AccordionSummaryProps, AccordionInternalProps {}

export const StyledAccordionSummary = styled(AccordionSummary)<
  PropsWithTheme<StyledAccordionSummaryProps>
>`
  ${accordionSummaryStyle};
  background-color: transparent;
  flex-direction: ${({ $expandIconPosition }) =>
    $expandIconPosition === 'left' ? 'row-reverse' : 'row'};
  padding-left: ${(props) => {
    if (props.$expandIconPosition === 'left') {
      return 0;
    }
    return props.$isLineAccordion
      ? lineAccordionBackgroundSpacingTextHorizontal
      : accordionBackgroundSpacingTextHorizontal;
  }}}
  &.Mui-focusVisible {
    background-color: transparent;
  }
  .MuiAccordionSummary-content {
    margin: ${(props) => {
      if (props.$isLineAccordion) {
        return props.$isSlimLineAccordion
          ? `${accordionBackgroundSpacingTextTopSlim} 0px
    ${accordionBackgroundSpacingTextBottomCollapsedSlim} 0px`
          : `${lineAccordionBackgroundSpacingTextTop} 0px
    ${lineAccordionBackgroundSpacingTextBottomCollapsed} 0px`;
      } else {
        return `${accordionBackgroundSpacingTextTop} 0px ${accordionBackgroundSpacingTextBottomCollapsed} 0px`;
      }
    }};
    ${(props) => {
      if (props.$expandIconPosition === 'left') {
        return `padding-right: ${
          props.$isLineAccordion
            ? lineAccordionBackgroundSpacingTextHorizontal
            : accordionBackgroundSpacingTextHorizontal
        }`;
      }
      return 0;
    }};
    &.Mui-expanded {
      margin: ${(props) => {
        if (props.$isLineAccordion) {
          return props.$isSlimLineAccordion
            ? `${accordionBackgroundSpacingTextTopSlim} 0px ${accordionFontSpacingSlim} 0px`
            : `${lineAccordionBackgroundSpacingTextTop} 0px ${lineAccordionFontSpacing} 0px`;
        } else {
          return `${accordionBackgroundSpacingTextTop} 0px ${accordionFontSpacing} 0px`;
        }
      }};      
    }
  }
  .MuiAccordionSummary-expandIcon {
    padding: ${(props) => {
      if (props.$isLineAccordion) {
        const paddingLeft =
          props.$expandIconPosition === 'left'
            ? lineAccordionBackgroundSpacingIconHorizontal
            : accordionIconSpacing;
        return props.$isSlimLineAccordion
          ? `${accordionBackgroundSpacingIconTopSlim} 0px 0px ${paddingLeft}`
          : `${lineAccordionBackgroundSpacingIconTop} 0px 0px ${paddingLeft}`;
      } else {
        return `${accordionBackgroundSpacingIconTop} 0px 0px ${
          props.$expandIconPosition === 'left'
            ? accordionBackgroundSpacingIconHorizontal
            : accordionIconSpacing
        }`;
      }
    }};
    margin-right: ${(props) => {
      if (props.$expandIconPosition === 'left') {
        return accordionIconSpacing;
      }
      return props.$isLineAccordion
        ? lineAccordionBackgroundSpacingIconHorizontal
        : accordionBackgroundSpacingIconHorizontal;
    }};
  }
  &:not(.Mui-expanded) {
    &${focusVisibleSelectorMixin} {
      ${({ theme }: PropsWithTheme<AccordionSummaryProps>) =>
        createElementOutlineMixin({
          color: theme.accordionColors.accordionFocusColor,
          width: accordionFocusWidth,
          style: accordionFocusStyle,
          radius: accordionFocusRadius
        })}
    }
  }
`;

// FONTS
export const AccordionRegularTitleStyle = styled(OwnUpBody)<OwnUpBodyProps>`
  color: ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.accordionColors.accordionFontColor};
`;

export const AccordionMediumTitleStyle = styled(OwnUpBodyMedium)<OwnUpBodyProps>`
  color: ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.accordionColors.accordionFontColor};
`;

export const DescriptionStyle = styled.span<OwnUpBodyProps>`
  ${ownUpSmallBodyMixin}
  color: ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.accordionColors.accordionFontColor};
  margin-bottom: ${accordionBackgroundSpacingTextBottomExpanded};
  width: 100%;
`;

// Slim line accordion variation fonts.
export const SlimLineAccordionRegularTitleStyle = styled(OwnUpSmallBody)<OwnUpBodyProps>`
  color: ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.accordionColors.accordionFontColor};
`;

export const SlimLineAccordionMediumTitleStyle = styled(OwnUpSmallBodyMedium)<OwnUpBodyProps>`
  color: ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.accordionColors.accordionFontColor};
`;

export const SlimLineAccordionDescriptionStyle = styled.span<OwnUpBodyProps>`
  ${ownUpDetailMixin}
  color: ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.accordionColors.accordionFontColor};
  margin-bottom: ${accordionBackgroundSpacingTextBottomExpandedSlim};
`;
