import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export const BACKGROUND_COLOR_PRIMARY_VARIANT = 'primaryTheme';
export const BACKGROUND_COLOR_SECONDARY_VARIANT = 'secondaryTheme';
export const BACKGROUND_COLOR_TERTIARY_VARIANT = 'tertiaryTheme';
export const BACKGROUND_COLOR_QUATERNARY_VARIANT = 'quaternaryTheme';
export const BACKGROUND_COLOR_QUINARY_VARIANT = 'quinaryTheme';
export const BACKGROUND_COLOR_VARIANTS = [
  BACKGROUND_COLOR_PRIMARY_VARIANT,
  BACKGROUND_COLOR_SECONDARY_VARIANT,
  BACKGROUND_COLOR_TERTIARY_VARIANT,
  BACKGROUND_COLOR_QUATERNARY_VARIANT,
  BACKGROUND_COLOR_QUINARY_VARIANT
] as const;

export type BackgroundColorVariants = (typeof BACKGROUND_COLOR_VARIANTS)[number];

export type BackgroundColorTokens = Record<
  BackgroundColorVariants,
  | {
      backgroundColor: React.CSSProperties['color'];
    }
  | undefined
>;

type BackgroundTheme = Record<ThemeNames, BackgroundColorTokens>;

export const backgroundColorsTheme: BackgroundTheme = {
  [ThemeNames.White]: {
    [BACKGROUND_COLOR_PRIMARY_VARIANT]: {
      backgroundColor: colors.WHITE_100
    },
    [BACKGROUND_COLOR_SECONDARY_VARIANT]: undefined,
    [BACKGROUND_COLOR_TERTIARY_VARIANT]: undefined,
    [BACKGROUND_COLOR_QUATERNARY_VARIANT]: undefined,
    [BACKGROUND_COLOR_QUINARY_VARIANT]: undefined
  },
  [ThemeNames.Light]: {
    [BACKGROUND_COLOR_PRIMARY_VARIANT]: {
      backgroundColor: colors.MOSS_100
    },
    [BACKGROUND_COLOR_SECONDARY_VARIANT]: {
      backgroundColor: colors.MOSS_50
    },
    [BACKGROUND_COLOR_TERTIARY_VARIANT]: {
      backgroundColor: colors.FOG_20
    },
    [BACKGROUND_COLOR_QUATERNARY_VARIANT]: {
      backgroundColor: colors.APRICOT_10
    },
    [BACKGROUND_COLOR_QUINARY_VARIANT]: undefined
  },
  [ThemeNames.Midtone]: {
    [BACKGROUND_COLOR_PRIMARY_VARIANT]: {
      backgroundColor: colors.MINT_100
    },
    [BACKGROUND_COLOR_SECONDARY_VARIANT]: {
      backgroundColor: colors.SAGE_100
    },
    [BACKGROUND_COLOR_TERTIARY_VARIANT]: {
      backgroundColor: colors.MYSTIC_60
    },
    [BACKGROUND_COLOR_QUATERNARY_VARIANT]: {
      backgroundColor: colors.MYSTIC_40
    },
    [BACKGROUND_COLOR_QUINARY_VARIANT]: {
      backgroundColor: colors.MANGO_40
    }
  },
  [ThemeNames.Dark]: {
    [BACKGROUND_COLOR_PRIMARY_VARIANT]: {
      backgroundColor: colors.ALOE_100
    },
    [BACKGROUND_COLOR_SECONDARY_VARIANT]: {
      backgroundColor: colors.PINE_100
    },
    [BACKGROUND_COLOR_TERTIARY_VARIANT]: {
      backgroundColor: colors.ATLANTIC_100
    },
    [BACKGROUND_COLOR_QUATERNARY_VARIANT]: {
      backgroundColor: colors.ATLANTIC_90
    },
    [BACKGROUND_COLOR_QUINARY_VARIANT]: undefined
  }
};
