import InputAdornment from '@material-ui/core/InputAdornment';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { OwnUpThemeProvider, ownUpWhiteTheme } from '../../core';
import { SearchIcon } from '../../icon-library/system-icons/standard-icons/search';
import { SearchInputProps } from './properties';
import { SearchIconButton, SearchTextField } from './styles';
/**
 * Renders a text input search bar.
 *
 * @remarks
 * This component leverages material-ui's TextField component {@link https://mui.com/components/text-fields/}. For more detailed
 * descriptions of the parameters, visit the TextField component page{@link https://mui.com/components/text-fields/}.
 *
 * Note that any children of the search bar will be forced into a `white` theme,
 *   as the search bar has a white background.
 *
 * @param buttonClick - action event that fires when search icon button is clicked on by user
 * @param inputAdornmentRef - (optional) Pass a ref to the input adornment.
 * @param inputRef - (optional) Pass a ref to the overall input element.
 * @param onChange - (optional) Callback fired when the value is changed.
 * @param searchIconRef - (optional) Pass a ref to the div surrounding the search icon.
 * @param wrapperRef - (optional) Pass a ref to the div surrounding the entire component.
 * @returns A stylized text field component for allowing users to search for elements
 *
 */
export const SearchInput = ({
  wrapperRef,
  searchIconRef,
  inputAdornmentRef,
  onSearchSubmit,
  icon = SearchIcon,
  ...props
}: SearchInputProps) => {
  // Current value of the field
  const [currentValue, setCurrentValue] = useState((props.value as string) || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (typeof event.target.value == 'string') {
      setCurrentValue(event.target.value);
    }
    if (typeof props.onChange != 'undefined') {
      props.onChange(event);
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // A user typing 'Enter' will submit the search
      if (event.key === 'Enter' && onSearchSubmit !== undefined) {
        onSearchSubmit(currentValue);
      }
    },
    [currentValue, onSearchSubmit]
  );

  const handleButtonClick = useCallback(() => {
    if (onSearchSubmit !== undefined) {
      onSearchSubmit(currentValue);
    }
  }, [currentValue, onSearchSubmit]);

  return (
    <SearchTextField
      ref={wrapperRef}
      role="search"
      aria-label={'search bar container'}
      tabIndex={-1}
      className={'searchBarStyle'}
      fullWidth
      value={currentValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        disableUnderline: true,
        placeholder: props.placeholder ? props.placeholder : 'Search',
        endAdornment: (
          // Search Bars have a white background so all sub-elements are forced into the "white" theme.
          <OwnUpThemeProvider theme={ownUpWhiteTheme}>
            <InputAdornment position="end" ref={inputAdornmentRef}>
              <SearchIconButton
                name="Search"
                tabIndex={0}
                onClick={handleButtonClick}
                ref={searchIconRef}
                role="button"
                aria-label={'submit search query'}
                icon={icon}
                size={3}
              />
            </InputAdornment>
          </OwnUpThemeProvider>
        ),
        ...props.InputProps
      }}
      {...props}
    />
  );
};
