import React from 'react';
import * as colors from '../../../colors';

export const MicroChevronUpIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.232144 8.79252C0.531768 9.07852 1.00651 9.06748 1.29252 8.76786L6 3.83621L10.7075 8.76786C10.9935 9.06748 11.4682 9.07852 11.7679 8.79252C12.0675 8.50651 12.0785 8.03177 11.7925 7.73214L6.54252 2.23214C6.401 2.08388 6.20496 2 6 2C5.79504 2 5.59901 2.08388 5.45748 2.23214L0.207485 7.73214C-0.0785198 8.03177 -0.0674792 8.50651 0.232144 8.79252Z"
        fill={colors.CHARCOAL_100}
      />
    </svg>
  );
};
