import React from 'react';
import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export interface UnderlineTooltipTriggerThemeTokens {
  fontColor: React.CSSProperties['color'];
  underlineColor: React.CSSProperties['color'];
  backgroundColor: React.CSSProperties['color'];
  focusColor: React.CSSProperties['color'];
  backgroundRadius: React.CSSProperties['borderRadius'];
  focusSpacingMultiplier: number;
  focusRadius: React.CSSProperties['borderRadius'];
}

type UnderlineTooltipTriggerTheme = {
  [key in ThemeNames]: UnderlineTooltipTriggerThemeTokens;
};

export interface IconTooltipTriggerThemeTokens {
  fontColor: React.CSSProperties['color'];
  focusColor: React.CSSProperties['color'];
  focusSpacingMultiplier: number;
  focusRadius: React.CSSProperties['borderRadius'];
}

type IconTooltipTriggerTheme = {
  [key in ThemeNames]: IconTooltipTriggerThemeTokens;
};

const commonUnderlineTheme = {
  backgroundRadius: '2px',
  focusSpacingMultiplier: 0.25,
  focusRadius: '4px'
};

const lightColorsUnderline = {
  ...commonUnderlineTheme,
  fontColor: colors.CHARCOAL_100,
  underlineColor: colors.WALDEN_100,
  backgroundColor: colors.MYSTIC_40,
  focusColor: colors.WALDEN_100
};

const midtoneColorsUnderline = {
  ...commonUnderlineTheme,
  fontColor: colors.CHARCOAL_100,
  underlineColor: colors.ATLANTIC_90,
  backgroundColor: colors.WHITE_50,
  focusColor: colors.ATLANTIC_90
};

const darkColorsUnderline = {
  ...commonUnderlineTheme,
  fontColor: colors.WHITE_100,
  underlineColor: colors.MYSTIC_80,
  backgroundColor: colors.WHITE_20,
  focusColor: colors.MYSTIC_80
};

const commonIconTheme = {
  focusSpacingMultiplier: 0.25,
  focusRadius: '4px'
};

const lightColorsIcon = {
  ...commonIconTheme,
  fontColor: colors.CHARCOAL_100,
  focusColor: colors.ALOE_100
};

const midtoneColorsIcon = {
  ...commonIconTheme,
  fontColor: colors.CHARCOAL_100,
  focusColor: colors.ALOE_100
};

const darkColorsIcon = {
  ...commonIconTheme,
  fontColor: colors.WHITE_100,
  focusColor: colors.WHITE_100
};

export const underlineTooltipTriggerTheme: UnderlineTooltipTriggerTheme = {
  [ThemeNames.White]: lightColorsUnderline,
  [ThemeNames.Light]: lightColorsUnderline,
  [ThemeNames.Midtone]: midtoneColorsUnderline,
  [ThemeNames.Dark]: darkColorsUnderline
};

export const iconTooltipTriggerTheme: IconTooltipTriggerTheme = {
  [ThemeNames.White]: lightColorsIcon,
  [ThemeNames.Light]: lightColorsIcon,
  [ThemeNames.Midtone]: midtoneColorsIcon,
  [ThemeNames.Dark]: darkColorsIcon
};
