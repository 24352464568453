import React from 'react';
import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export type NavThemeTokens = {
  navigationIconColor: React.CSSProperties['color'];
  navigationFontColorActive: React.CSSProperties['color'];
  navigationFontColorHover: React.CSSProperties['color'];
  navigationFontColorFocus: React.CSSProperties['color'];
  navigationFontColorSelected: React.CSSProperties['color'];
};

type NavLinkTheme = {
  [key in ThemeNames]: NavThemeTokens;
};

const whiteTheme: NavThemeTokens = {
  navigationIconColor: colors.ALOE_100,
  navigationFontColorActive: colors.CHARCOAL_100,
  navigationFontColorHover: colors.ALOE_100,
  navigationFontColorFocus: colors.ALOE_100,
  navigationFontColorSelected: colors.ALOE_100
};

export const navLinkTheme: NavLinkTheme = {
  [ThemeNames.White]: whiteTheme,
  [ThemeNames.Light]: whiteTheme,
  [ThemeNames.Midtone]: whiteTheme,
  [ThemeNames.Dark]: {
    navigationIconColor: colors.WHITE_100,
    navigationFontColorActive: colors.WHITE_100,
    navigationFontColorHover: colors.WHITE_60,
    navigationFontColorFocus: colors.WHITE_100,
    navigationFontColorSelected: colors.WHITE_100
  }
};
