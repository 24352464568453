import styled, { css } from 'styled-components';
import { CHARCOAL_100, CHARCOAL_5, WHITE_100 } from '../../colors';
import { ownUpWhiteTheme, PropsWithTheme } from '../../core';
import { Z_INDICES } from '../../core/theme/constants/z-indices';
import { OwnUpGridWrapper } from '../../grid';

export const navBarBaseStyle = css<PropsWithTheme<{}>>`
  max-width: 100%;
  background-color: ${WHITE_100};
  color: ${CHARCOAL_100};
  box-shadow: ${ownUpWhiteTheme.spacing(0.5)}px ${ownUpWhiteTheme.spacing(0.5)}px
    ${ownUpWhiteTheme.spacing(1)}px ${CHARCOAL_5};
  display: flex;
  justify-content: center;
  z-index: ${Z_INDICES.NAVBAR_BASE};
`;

export const NavBarGridWrapper = styled(OwnUpGridWrapper)`
  height: 100%;
  display: flex;
`;
