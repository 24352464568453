import { useMemo } from 'react';

export type SanitizedUrlOptions = {
  /**
   * The fallback url to use, will not be sanitized so don't
   * get this value from user input.
   */
  fallback?: string;

  /**
   * The protocols allowed, by default allows http: and https:
   * you should end your protocol with a ':' character.
   */
  allowedProtocols?: string[];

  /**
   * The set of allowed hosts for the urls
   * if no allowed hosts are specified allows all hosts.
   */
  allowedHosts?: string[];
};

const defaultAllowedProtocols = ['http:', 'https:', 'mailto:', 'tel:', 'sms:'];

/**
 * Given a URL returns it if the url matches the allowed sanitization checks
 * If the sanitization checks fail, or if the url is undefined return a fallback value.
 * @param url - The URL, usually from an untrusted source
 * @param options - Options to control sanitization
 * @returns A URL or undefined. Undefined will be returned if fallback is not present, and if the url is either undefined or had issues.
 */
export const useSanitizedUrl = (
  url: string | undefined = undefined,
  { fallback, allowedProtocols = defaultAllowedProtocols, allowedHosts }: SanitizedUrlOptions = {}
): string | undefined => {
  // Use memo so we don't re-parse URLS on every render when they're
  // likely static.
  const parsed = useMemo(() => {
    if (url != null) {
      if (typeof document === 'undefined') {
        return url;
      }
      // use document.baseURI to correctly handle relative urls
      const urlObj = new URL(url, document.baseURI);
      if (allowedProtocols.includes(urlObj.protocol)) {
        if (allowedHosts == null || allowedHosts.includes(urlObj.host)) {
          return url;
        }
      }
    }
    return undefined;
  }, [url, allowedProtocols, allowedHosts]);
  return parsed ?? fallback;
};
