import React from 'react';
import * as colors from '../../colors';

export const InformationDarkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" style={{ fill: colors.WALDEN_100 }} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10.65C12.4142 10.65 12.75 10.9858 12.75 11.4L12.7501 16.25C12.7501 16.6642 12.4143 17 12.0001 17C11.5859 17 11.2501 16.6642 11.2501 16.25L11.25 11.4C11.25 10.9858 11.5858 10.65 12 10.65Z"
      style={{ fill: colors.WHITE_100 }}
    />
  </svg>
);
