import { ButtonProps } from '@material-ui/core/Button';
import { ComposableNavigationProps } from '../../types/composable';

/**
 * An extension of {@see ButtonProps} that contains optional
 *   icon helpers.
 */
export interface OwnUpButtonProps extends ComposableNavigationProps, Omit<ButtonProps, 'size'> {
  /**
   * Icon to render. Should be an SVG element.
   */
  icon?: JSX.Element | React.FC;
  iconPosition?: 'left' | 'right';
  /**
   * Optional message to show in a tooltip when inactive.
   */
  disabledReason?: string;
  $idle?: boolean;
}

/**
 * {@see OwnUpIconButton} properties. An extension of the base
 *   {@see OwnUpButtonProps} which forces certain attributes
 *   to be used to support an icon-only button.
 */
export interface OwnUpIconButtonProps
  extends Omit<OwnUpButtonProps, 'iconPosition' | 'children' | 'size' | 'name'> {
  /**
   * Icon to render. Should be an SVG element.
   */
  icon: JSX.Element | React.FC;

  /**
   * Screen-reader friendly name/label for this button.
   *   Should be descriptive of what the button does, more-so
   *   than what the button contains.
   */
  name: string;

  /**
   * Only certain icon button sizes are permitted in our
   *   design system. These sizes are in rem units. If not
   *   specified, will default to 3rem (48px)
   */
  size?: 3 | 2.5 | 2 | 1.5 | 1.25 | 1.125;
}

/**
 * Spread this over your button to turn it into a
 *   "decorative" button. It will have all the look
 *   and feel of a button but it will not be treated
 *   as a button by screen readers. (i.e. not focusable)
 */
export const decorativeButtonProps: Partial<OwnUpButtonProps> = {
  component: 'div' as const,
  // HACK: Forces the tabIndex on the element to be unset
  tabIndex: '' as any
};
