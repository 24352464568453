import Link from '@material-ui/core/Link';
import styled from 'styled-components';
import { PropsWithTheme } from '../core';
import { createElementOutlineMixin } from '../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { ComposedLink, DivProps, SpanProps } from './properties';
import { iconLinkSizes } from './theme';

// spacing
const linkIconSpacing = '8px';

// radius
const linkBackgroundRadius = '4px';
const linkFocusRadius = '4px';

export const StyledLink = styled(Link)<ComposedLink>`
  display: flex;
  place-items: center;
`;

export const StyledIconLink = styled(StyledLink)`
  cursor: pointer;
  width: fit-content;
  padding: 0 ${linkFocusRadius};
  margin: 0 -${linkFocusRadius};
  color: ${(props: PropsWithTheme<DivProps>) => props.theme.linkColors.linkFontColor};

  &${focusVisibleSelectorMixin} {
    ${(props: PropsWithTheme<DivProps>) =>
      createElementOutlineMixin({
        radius: linkBackgroundRadius,
        width: '2px',
        color: props.theme.linkColors.linkOutlineColor
      })}
    &::after {
      top: 0;
      bottom: 0;
    }
  }

  :hover {
    background-color: ${(props: PropsWithTheme<DivProps>) =>
      props.theme.linkColors.linkHoverBackgroundColor};
    border-radius: ${linkFocusRadius};
    text-decoration: none;
    position: relative;
  }

  svg {
    width: ${(props) => iconLinkSizes[props.size].linkSize};
    height: ${(props) => iconLinkSizes[props.size].linkSize};
    ${({ theme }: PropsWithTheme<DivProps>) => theme.breakpoints.up('lg')} {
      width: ${(props) => (props.size === 'large' ? '1.5rem' : '')};
      height: ${(props) => (props.size === 'large' ? '1.5rem' : '')};
    }
    margin-right: ${(props) => (props.iconPlacement === 'left' ? linkIconSpacing : 0)};
    margin-left: ${(props) => (props.iconPlacement === 'right' ? linkIconSpacing : 0)};

    path {
      fill: ${(props: PropsWithTheme<DivProps>) => props.theme.linkColors.linkIconColor};
    }
  }
`;

export const UnderlineLinkWrapper = styled(Link)<PropsWithTheme<SpanProps & ComposedLink>>`
  color: ${({ theme }: PropsWithTheme<SpanProps>) => theme.underlineLinkColors.linkFontColor};
  cursor: pointer;
  border-bottom: ${({ theme }: PropsWithTheme<SpanProps>) =>
    `1px solid ${theme.underlineLinkColors.linkUnderlineColor}`};
  &:hover {
    background-color: ${({ theme }: PropsWithTheme<SpanProps>) =>
      theme.underlineLinkColors.linkHoverBackgroundColor};
    border-radius: ${linkFocusRadius} ${linkFocusRadius} 0 0;
    text-decoration: none;
  }
  &${focusVisibleSelectorMixin} {
    ${({ theme }: PropsWithTheme<SpanProps>) =>
      createElementOutlineMixin({
        color: theme.underlineLinkColors.linkOutlineColor,
        width: '2px',
        inset: '-2px',
        radius: '4px'
      })}
  }
  &:focus {
    outline-style: unset;
  }
  &:visited {
    color: ${({ theme }: PropsWithTheme<SpanProps>) => theme.underlineLinkColors.linkFontColor};
  }
`;
