import { makeStyles, Theme } from '@material-ui/core/styles';
import styled, { useTheme } from 'styled-components';
import * as colors from '../colors';
import { ownUpDarkTheme, ownUpWhiteTheme } from '../core/theme';
import { TooltipStyleProps } from './properties';

/**
 * Material Ui tooltips does not play nice with styled components (element renders outside
 *   of the base element). Thus, styling had to be done this way :(
 */
export const tooltipContainerStyles = makeStyles<Theme, TooltipStyleProps>((theme: Theme) => ({
  popper: () => ({
    margin: '4px'
  }),
  tooltip: ({
    tooltipFontColor,
    tooltipBackgroundColor,
    tooltipBorderColor,
    tooltipBoxShadow
  }) => ({
    backgroundColor: tooltipBackgroundColor
      ? tooltipBackgroundColor
      : theme.tooltipColors.tooltipBackgroundColor,
    color: tooltipFontColor ? tooltipFontColor : theme.tooltipColors.tooltipFontColor,
    border: `1px solid ${
      tooltipBorderColor ? tooltipBorderColor : theme.tooltipColors.tooltipBorderColor
    }`,
    boxShadow: tooltipBoxShadow ? tooltipBoxShadow : theme.tooltipColors.tooltipBoxShadow,
    padding: `${theme.spacing(2)}px`,
    borderRadius: `${theme.shape.borderRadius}px`,
    marginTop: '4px',
    maxWidth: '312px'
  }),
  arrow: ({ tooltipBackgroundColor, tooltipBorderColor }) => ({
    color: tooltipBackgroundColor
      ? tooltipBackgroundColor
      : theme.tooltipColors.tooltipBackgroundColor,
    '&:before': {
      border: `1px solid ${
        tooltipBorderColor ? tooltipBorderColor : theme.tooltipColors.tooltipBorderColor
      }`
    }
  })
}));

export const useTooltipClasses = (themeMode?: string) => {
  const currentTheme = useTheme() as Theme;
  if (!themeMode) {
    themeMode = currentTheme.name === ownUpDarkTheme.name ? 'light' : 'dark';
  }
  if (themeMode === 'light') {
    return [
      tooltipContainerStyles({
        tooltipFontColor: colors.CHARCOAL_100,
        tooltipBackgroundColor: colors.WHITE_100,
        tooltipBorderColor: colors.FOG_60,
        tooltipBoxShadow: `4.0px 4.0px 8.0px 0px ${colors.CHARCOAL_5}`
      }),
      ownUpWhiteTheme
    ] as const;
  }
  return [
    tooltipContainerStyles({
      tooltipFontColor: colors.WHITE_100,
      tooltipBackgroundColor: colors.CHARCOAL_90,
      tooltipBorderColor: colors.CHARCOAL_90,
      tooltipBoxShadow: 'none'
    }),
    ownUpDarkTheme
  ] as const;
};

export const TooltipTitle = styled.div`
  margin-bottom: 4px;
`;
