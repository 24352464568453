import { useCallback } from 'react';

type WrappedFunctType<TArgs extends any[], TOutput> = (...args: TArgs) => TOutput;
/**
 * Wrapper that allows the end-user to pass user-defined functions e.g
 *  a required setState function via the newFunction and an optional
 *  event tracker via the wrappedFunction
 *
 * @param newFunction -  required user defined function.
 * @param wrappedFunction - optional user defined function.
 *
 * NB: If the types of the arguments/return-values of the functions passed
 *     to this hook differ, Typescript will try to infer the types of the
 *     arguments/return-values. Therefore, the user might need to specify
 *     the types expected/returned by the function(s) when utilizing this hook.
 *     Example where the newFunction expects a boolean and the wrappedFunction
 *     expects a ChangeEvent:
 *  useWrappedFunction<[React.ChangeEvent<{}>], void>(() => setState(true), onChange);
 *  Alternative Syntax:
 *  useWrappedFunction((_: React.ChangeEvent<{}>) => setState(true),onChange);
 */

export const useWrappedFunction = <TArgs extends any[], TOutput>(
  newFunction: WrappedFunctType<TArgs, TOutput>,
  wrappedFunction?: WrappedFunctType<TArgs, TOutput>
) =>
  useCallback(
    (...args: TArgs) => {
      newFunction(...args);
      wrappedFunction?.(...args);
    },
    [wrappedFunction, newFunction]
  );
