import React from 'react';
import styled from 'styled-components';
import { OwnUpErrorContainerProps } from './properties';
import { StandaloneError } from './styles';

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Shows an error above checkboxes/radio buttons, if given conditions are met
 */
export const OwnUpErrorContainer = ({
  showError,
  errorText = 'Please make a selection',
  elements,
  ...props
}: OwnUpErrorContainerProps) => (
  <>
    {showError ? <StandaloneError {...props}>{errorText}</StandaloneError> : null}
    <OptionsContainer>{elements}</OptionsContainer>
  </>
);
