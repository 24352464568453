/* eslint-disable @typescript-eslint/naming-convention */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { createElementOutlineMixin } from '../../core/theme/mixins/element-outline';
import { stickyHoverMixin } from '../../core/theme/mixins/sticky-hover';
import { commonIconInputsMixin } from '../common/common-icon-inputs-mixin';

interface CheckboxLabelProps {
  /**
   * The focus-visible polyfill doesn't appear to be compatible with
   *   MUI's checkboxes so we have to make do with some custom props.
   */
  $focusVisible: boolean;
  width?: React.CSSProperties['width'];
}

export const StyledCheckboxLabel = styled(FormControlLabel)<PropsWithTheme<CheckboxLabelProps>>`
  ${commonIconInputsMixin}
  color: ${({ theme }: PropsWithTheme<CheckboxLabelProps>) =>
    theme.checkboxColors.checkboxFontColorLabel};
  background-color: ${({ theme }: PropsWithTheme<CheckboxLabelProps>) =>
    theme.checkboxColors.checkboxColorBackground};
  max-width: none;

  .Mui-disabled {
    color: ${({ theme }: PropsWithTheme<{}>) => theme.checkboxColors.checkboxFontColorLabel};
  }

  .ouCheckboxChecked {
    rect {
      fill: ${({ theme }: PropsWithTheme<CheckboxLabelProps>) =>
        theme.checkboxColors.checkboxColorSelected};
    }
    path {
      fill: ${({ theme }: PropsWithTheme<CheckboxLabelProps>) =>
        theme.checkboxColors.checkboxColorControl};
    }
  }

  .ouCheckboxUnchecked rect {
    stroke: ${({ theme }: PropsWithTheme<CheckboxLabelProps>) =>
      theme.checkboxColors.checkboxColorBorder};
  }

  ${stickyHoverMixin} {
    &:hover:not(.Mui-disabled) {
      .ouCheckboxUnchecked rect {
        fill: ${({ theme }: PropsWithTheme<CheckboxLabelProps>) =>
          theme.checkboxColors.checkboxColorHover};
      }
    }
  }

  ${({ $focusVisible, theme }: PropsWithTheme<CheckboxLabelProps>) =>
    $focusVisible
      ? css`
          &:not(:hover) {
            .ouCheckboxUnchecked rect {
              fill: ${theme.checkboxColors.checkboxColorFocus};
            }
          }

          & .Mui-disabled .ouCheckboxUnchecked rect {
          fill: none;
        }

          &${createElementOutlineMixin({
            width: `${theme.spacing(0.25)}px`,
            color: theme.checkboxColors.checkboxColorBorder,
            radius: `${theme.shape.borderRadius}px`
          })}
        `
      : undefined}
`;
