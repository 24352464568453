import Link from '@material-ui/core/Link';
import React from 'react';
import styled, { css } from 'styled-components';
import { ComposableNavigationProps } from '../../types/composable';
import * as colors from '../colors';
import { PropsWithTheme } from '../core';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { useSanitizedUrl } from '../hooks/use-sanitized-url';
import { OwnUpLogoSvg } from '../logo-library/own-up-logo';
import { OwnUpLogoWithTextSvg } from '../logo-library/own-up-logo-with-text';
import { OwnUpLogoProps } from './properties';

interface LinkProps extends ComposableNavigationProps {}

const LinkStyle = styled(Link)<LinkProps>`
  display: inline-block;
  cursor: pointer;
`;

interface LogoStyleProps {
  hasText?: boolean;
}

/**
 * The width is tuned to the wordmark.
 */
export const logoStyleMixin = css<PropsWithTheme<LogoStyleProps>>`
  & svg {
    height: 3rem;
    ${({ theme }: PropsWithTheme<LogoStyleProps>) => theme.breakpoints.down('md')} {
      height: 2.375rem;
      width: ${({ hasText }: PropsWithTheme<LogoStyleProps>) =>
        hasText ? '8.75rem' : '1.6565rem'};
    }
    ${({ theme }: PropsWithTheme<LogoStyleProps>) => theme.breakpoints.down('xs')} {
      height: 2.125rem;
      width: ${({ hasText }: PropsWithTheme<LogoStyleProps>) =>
        hasText ? '7.815rem' : '1.475rem'};
    }
  }
  display: inline-block;
  line-height: 0;
`;

export const logoLinkStyleMixin = css`
  & a {
    border: 2px solid transparent;
    &${focusVisibleSelectorMixin} {
      border: 2px solid ${colors.ALOE_100};
      border-radius: 4px;
      outline: none;
    }
    &:focus {
      outline-style: unset;
    }
  }
`;

export const LogoStyle = styled.span<PropsWithTheme<LogoStyleProps>>`
  ${logoStyleMixin}
`;

export const LogoLinkStyle = styled.span<PropsWithTheme<LogoStyleProps>>`
  ${logoStyleMixin}
  ${logoLinkStyleMixin}
`;

export const OwnUpLogoWithText = () => (
  <LogoStyle hasText>
    <OwnUpLogoWithTextSvg />
  </LogoStyle>
);

export const OwnUpLogoWithTextLink = ({
  logoRef,
  href,
  component,
  to,
  role,
  ...props
}: OwnUpLogoProps) => {
  return (
    <LogoLinkStyle hasText={true} ref={logoRef} {...props}>
      <LinkStyle
        href={useSanitizedUrl(href)}
        aria-label="return to homepage"
        component={component}
        to={to}
        role={role}
      >
        <OwnUpLogoWithTextSvg />
      </LinkStyle>
    </LogoLinkStyle>
  );
};

export const OwnUpLogo = () => (
  <LogoStyle>
    <OwnUpLogoSvg />
  </LogoStyle>
);

export const OwnUpLogoLink = ({ logoRef, href, role, component, to, ...props }: OwnUpLogoProps) => {
  return (
    <LogoLinkStyle hasText={false} ref={logoRef} {...props}>
      <LinkStyle
        href={useSanitizedUrl(href)}
        aria-label="return to homepage"
        component={component}
        to={to}
        role={role}
      >
        <OwnUpLogoSvg />
      </LinkStyle>
    </LogoLinkStyle>
  );
};
