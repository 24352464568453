import React from 'react';

export const CheckboxIconUnchecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ouCheckboxUnchecked"
    width="24"
    height="24"
    fill="none"
  >
    <rect x="1" y="1" width="22" height="22" rx="3" strokeWidth="2" />
  </svg>
);

export const CheckboxIconChecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ouCheckboxChecked"
    width="24"
    height="24"
    fill="none"
  >
    <rect width="24" height="24" rx="4" />
    <path d="M18.7724 7.21181C19.0696 7.5003 19.0767 7.97513 18.7882 8.27236L10.5382 16.7724C10.3983 16.9165 10.2064 16.9985 10.0056 17C9.80475 17.0015 9.61169 16.9224 9.46967 16.7803L5.21967 12.5303C4.92678 12.2374 4.92678 11.7626 5.21967 11.4697C5.51256 11.1768 5.98744 11.1768 6.28033 11.4697L9.99203 15.1814L17.7118 7.22764C18.0003 6.93041 18.4751 6.92333 18.7724 7.21181Z" />
  </svg>
);
