import React from 'react';

export const RadioButtonIconUnchecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={'ouRadioButtonUnchecked'}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect className="outer" x="1" y="1" width="22" height="22" rx="11" strokeWidth="2" />
  </svg>
);

export const RadioButtonIconChecked = () => (
  <svg
    className={'ouRadioButtonChecked'}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect className="outer" x="1" y="1" width="22" height="22" rx="11" strokeWidth="2" />
    <rect className="inner" x="4" y="4" width="16" height="16" rx="8" />
  </svg>
);
