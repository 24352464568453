import { css } from 'styled-components';
import {
  aktivGroteskBoldMixin,
  aktivGroteskMediumMixin,
  aktivGroteskRegularMixin
} from '../../fonts';

const boldMixin = css`
  & em,
  & b {
    ${aktivGroteskBoldMixin}
  }
`;

const detailMixin = css`
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.01em;

  ${boldMixin}
`;

const smallDetailMixin = css`
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.01em;

  ${boldMixin}
`;

export const ownUpDetailMixin = css`
  ${aktivGroteskRegularMixin}
  ${detailMixin}
`;

export const ownUpDetailMediumMixin = css`
  ${aktivGroteskMediumMixin}
  ${detailMixin}
`;

export const ownUpSmallDetailMixin = css`
  ${aktivGroteskRegularMixin}
  ${smallDetailMixin}
`;

export const ownUpSmallDetailMediumMixin = css`
  ${aktivGroteskMediumMixin}
  ${smallDetailMixin}
`;
