import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { OwnUpThemeProvider } from '../core/provider';
import { ownUpWhiteTheme } from '../core/theme';
import { CloseLargeIcon } from '../icon-library/system-icons/standard-icons/close-large';
import { OwnUpModalProps, OwnUpSideModalProps } from './properties';
import {
  OwnUpBottomModalBody,
  OwnUpBottomModalExitButton,
  OwnUpBottomModalTitle,
  OwnUpFloatingModalBody,
  OwnUpFloatingModalContent,
  OwnUpFloatingModalExitButton,
  OwnUpFloatingModalTitle,
  OwnUpModalBackground,
  OwnUpSideModalBody,
  OwnUpSideModalContent,
  OwnUpSideModalTitle
} from './styles';

/**
 * Renders a modal, a temporary surface which renders the rest of
 *  the page uninteractable.
 */
export const OwnUpBottomModal = ({
  wrapperRef,
  className,
  onClose,
  title,
  children,
  ...props
}: OwnUpModalProps) => (
  <OwnUpThemeProvider theme={ownUpWhiteTheme}>
    <OwnUpModalBackground
      ref={wrapperRef}
      onClose={onClose}
      className={className}
      {...props}
      data-test-id="modalBackground"
    >
      <ScopedCssBaseline>
        <OwnUpBottomModalBody data-test-id="modalBody" className="modal-body">
          <OwnUpBottomModalExitButton
            className="modal-exit-button"
            data-test-id="modalExitButton"
            name="Modal Exit Button"
            size={2.5}
            onClick={() => onClose()}
            icon={<CloseLargeIcon />}
          />
          <OwnUpBottomModalTitle variant="h2">{title}</OwnUpBottomModalTitle>
          <OwnUpFloatingModalContent maxWidth="lg" className="modal-content">
            {children}
          </OwnUpFloatingModalContent>
        </OwnUpBottomModalBody>
      </ScopedCssBaseline>
    </OwnUpModalBackground>
  </OwnUpThemeProvider>
);

export const OwnUpFloatingModal = ({
  wrapperRef,
  className,
  onClose,
  title,
  children,
  ...props
}: OwnUpModalProps) => {
  // Check if the current breakpoint of the screen is xsmall or below.
  const screenSizeXSmallOrBelow = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  // Floating modal becomes a bottom modal on mobile screens
  return screenSizeXSmallOrBelow ? (
    <OwnUpBottomModal
      wrapperRef={wrapperRef}
      className={className}
      onClose={onClose}
      title={title}
      {...props}
    >
      {children}
    </OwnUpBottomModal>
  ) : (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <OwnUpModalBackground
        ref={wrapperRef}
        onClose={onClose}
        className={className}
        {...props}
        data-test-id="modalBackground"
      >
        <ScopedCssBaseline>
          <OwnUpFloatingModalBody data-test-id="modalBody" className="modal-body">
            <OwnUpFloatingModalExitButton
              className="modal-exit-button"
              data-test-id="modalExitButton"
              name="Modal Exit Button"
              size={2.5}
              onClick={() => onClose()}
              icon={<CloseLargeIcon />}
            />
            <OwnUpFloatingModalTitle variant="h2">{title}</OwnUpFloatingModalTitle>
            <OwnUpFloatingModalContent maxWidth="lg" className="modal-content">
              {children}
            </OwnUpFloatingModalContent>
          </OwnUpFloatingModalBody>
        </ScopedCssBaseline>
      </OwnUpModalBackground>
    </OwnUpThemeProvider>
  );
};

export const OwnUpSideModal = ({
  wrapperRef,
  className,
  onClose,
  title,
  $anchorSide = 'right',
  children,
  ...props
}: OwnUpSideModalProps) => {
  // Check if the current breakpoint of the screen is xsmall or below.
  const screenSizeXSmallOrBelow = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  // Floating modal becomes a bottom modal on mobile screens
  return screenSizeXSmallOrBelow ? (
    <OwnUpBottomModal
      wrapperRef={wrapperRef}
      className={className}
      onClose={onClose}
      title={title}
      {...props}
    >
      {children}
    </OwnUpBottomModal>
  ) : (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <OwnUpModalBackground
        ref={wrapperRef}
        onClose={onClose}
        className={className}
        {...props}
        data-test-id="modalBackground"
      >
        <ScopedCssBaseline>
          <OwnUpSideModalBody
            data-test-id="modalBody"
            $anchorSide={$anchorSide}
            className="modal-body"
          >
            <OwnUpBottomModalExitButton
              className="modal-exit-button"
              data-test-id="modalExitButton"
              name="Modal Exit Button"
              size={2.5}
              onClick={() => onClose()}
              icon={<CloseLargeIcon />}
            />
            <OwnUpSideModalTitle variant="h2">{title}</OwnUpSideModalTitle>
            <OwnUpSideModalContent maxWidth="lg" className="modal-content">
              {children}
            </OwnUpSideModalContent>
          </OwnUpSideModalBody>
        </ScopedCssBaseline>
      </OwnUpModalBackground>
    </OwnUpThemeProvider>
  );
};
