import React from 'react';
import * as colors from '../../colors';

export const ErrorDarkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" style={{ fill: colors.BERRY_100 }} />
    <path
      d="M11.65 6.5C11.4709 6.5 11.2997 6.57392 11.1769 6.70432C11.054 6.83471 10.9905 7.01001 11.0012 7.18882L11.3511 13.0388C11.3717 13.3821 11.6561 13.65 12 13.65C12.3439 13.65 12.6283 13.3821 12.6488 13.0388L12.9988 7.18882C13.0095 7.01001 12.9459 6.83471 12.8231 6.70432C12.7003 6.57392 12.5291 6.5 12.35 6.5H11.65Z"
      style={{ fill: colors.WHITE_100 }}
    />
    <path
      d="M13 16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 15.5 12 15.5C12.5523 15.5 13 15.9477 13 16.5Z"
      style={{ fill: colors.WHITE_100 }}
    />
  </svg>
);
