import React from 'react';
import { ownUpWhiteTheme } from '../core';
import { OwnUpThemeProvider } from '../core/provider';
import { OwnUpGridContainer } from '../grid';
import { ArrowRightIcon } from '../icon-library/system-icons/standard-icons/arrow-right';
import { OwnUpLargeBodyMedium } from '../typography';
import { OwnUpNavLink } from './nav-link';
import { OwnUpNavMenuDrawerItemProps, OwnUpNavMenuDrawerProps } from './properties';
import {
  DescriptionStyle,
  DrawerItemLinkDesktop,
  FullWidthContainer,
  MenuDrawerItemDesktop,
  ModalDrawerContainer,
  OwnUpNavMenuDrawerStyleDesktop
} from './styles/nav-menu-drawer';

export const OwnUpNavMenuDrawer = ({
  onModal = false,
  children,
  ...props
}: OwnUpNavMenuDrawerProps) => {
  return onModal ? (
    <ModalDrawerContainer>{children}</ModalDrawerContainer>
  ) : (
    <FullWidthContainer {...props}>
      <OwnUpNavMenuDrawerStyleDesktop>
        <OwnUpGridContainer variant="slim">{children}</OwnUpGridContainer>
      </OwnUpNavMenuDrawerStyleDesktop>
    </FullWidthContainer>
  );
};

export const OwnUpNavMenuDrawerItemDesktop = ({
  title,
  description,
  link,
  linkText = 'Link',
  linkProps
}: OwnUpNavMenuDrawerItemProps) => (
  <MenuDrawerItemDesktop>
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <OwnUpLargeBodyMedium variant="body1">{title}</OwnUpLargeBodyMedium>
      <DescriptionStyle variant="body2">{description}</DescriptionStyle>
      <DrawerItemLinkDesktop icon={<ArrowRightIcon />} link={link} {...linkProps}>
        {linkText}
      </DrawerItemLinkDesktop>
    </OwnUpThemeProvider>
  </MenuDrawerItemDesktop>
);

export const OwnUpNavMenuItemModal = ({ title, link, linkProps }: OwnUpNavMenuDrawerItemProps) => (
  <OwnUpNavLink size="small" href={link} className="modal-drawer-link" {...linkProps}>
    {title}
  </OwnUpNavLink>
);
