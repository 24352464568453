/* stylelint-disable font-family-no-missing-generic-family-keyword */
import { css } from 'styled-components';

/**
 * Mixin to apply the Aktiv Grotesk Bold font.
 */
export const aktivGroteskBoldMixin = css`
  font-family: 'Aktiv-Grotesk-Bold', Helvetica, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
`;

/**
 * Mixin to apply the Aktiv Grotesk Medium font.
 */
export const aktivGroteskMediumMixin = css`
  font-family: 'Aktiv-Grotesk-Medium', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
`;

/**
 * Mixin to apply the Aktiv Grotesk Regular font.
 */
export const aktivGroteskRegularMixin = css`
  font-family: 'Aktiv-Grotesk-Regular', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
`;

/**
 * Mixin to apply the Aktiv Grotesk Italic font.
 */
export const aktivGroteskItalicMixin = css`
  font-family: 'Aktiv-Grotesk-Italic', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
`;
