import { useMemo } from 'react';
import { ChevronDownIcon } from '../../icon-library/system-icons/standard-icons/chevron-down';
import { ChevronUpIcon } from '../../icon-library/system-icons/standard-icons/chevron-up';

/**
 * Determines which icon to show. Will show the down chevron unless
 *   the menu is bottom-anchored and open.
 *
 * @param menuPosition - position of the dropdown menu
 * @param isOpen - whether or not the menu is open
 * @returns - which icon to show
 */
export const useChevronIcon = (
  menuPosition: number | 'top' | 'center' | 'bottom' | undefined,
  isOpen: boolean = false
) =>
  useMemo(
    () => (menuPosition === 'bottom' && isOpen ? ChevronUpIcon : ChevronDownIcon),
    [menuPosition, isOpen]
  );
