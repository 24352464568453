import React from 'react';
import { OwnUpAccordion } from './element';
import { OwnUpAccordionProps } from './properties';

export const OwnUpLineAccordion = ({
  'aria-label': ariaLabel,
  children,
  ...props
}: OwnUpAccordionProps) => {
  return (
    <OwnUpAccordion
      title={props.title}
      defaultExpanded={props.defaultExpanded}
      $isLineAccordion={true}
      $isSlimLineAccordion={false}
      {...props}
    >
      {children}
    </OwnUpAccordion>
  );
};
