import * as colors from '../colors';
import { SizeNames, ThemeNames } from '../core/theme/types';

interface BaseLinkThemeTokens {
  linkFontColor: React.CSSProperties['color'];
  linkOutlineColor: React.CSSProperties['color'];
  linkHoverBackgroundColor: React.CSSProperties['color'];
}
export interface LinkThemeTokens extends BaseLinkThemeTokens {
  linkIconColor: React.CSSProperties['color'];
}

type LinkTheme = {
  [key in ThemeNames]: LinkThemeTokens;
};

export interface UnderlineLinkThemeTokens extends BaseLinkThemeTokens {
  linkUnderlineColor: React.CSSProperties['color'];
}

type UnderlineLinkTheme = {
  [key in ThemeNames]: UnderlineLinkThemeTokens;
};

export const linkTheme: LinkTheme = {
  [ThemeNames.White]: {
    linkFontColor: colors.WALDEN_100,
    linkOutlineColor: colors.WALDEN_100,
    linkIconColor: colors.WALDEN_100,
    linkHoverBackgroundColor: colors.MYSTIC_40
  },
  [ThemeNames.Light]: {
    linkFontColor: colors.WALDEN_100,
    linkOutlineColor: colors.WALDEN_100,
    linkIconColor: colors.WALDEN_100,
    linkHoverBackgroundColor: colors.MYSTIC_40
  },
  [ThemeNames.Midtone]: {
    linkFontColor: colors.ATLANTIC_90,
    linkOutlineColor: colors.ATLANTIC_90,
    linkIconColor: colors.ATLANTIC_90,
    linkHoverBackgroundColor: colors.WHITE_50
  },
  [ThemeNames.Dark]: {
    linkFontColor: colors.MYSTIC_80,
    linkOutlineColor: colors.MYSTIC_80,
    linkIconColor: colors.MYSTIC_80,
    linkHoverBackgroundColor: colors.WHITE_20
  }
};

export const underlineLinkTheme: UnderlineLinkTheme = {
  [ThemeNames.White]: {
    linkFontColor: colors.WALDEN_100,
    linkUnderlineColor: colors.WALDEN_100,
    linkHoverBackgroundColor: colors.MYSTIC_40,
    linkOutlineColor: colors.WALDEN_100
  },
  [ThemeNames.Light]: {
    linkFontColor: colors.WALDEN_100,
    linkUnderlineColor: colors.WALDEN_100,
    linkHoverBackgroundColor: colors.MYSTIC_40,
    linkOutlineColor: colors.WALDEN_100
  },
  [ThemeNames.Midtone]: {
    linkFontColor: colors.ATLANTIC_90,
    linkUnderlineColor: colors.ATLANTIC_90,
    linkHoverBackgroundColor: colors.WHITE_50,
    linkOutlineColor: colors.ATLANTIC_90
  },
  [ThemeNames.Dark]: {
    linkFontColor: colors.MYSTIC_80,
    linkUnderlineColor: colors.MYSTIC_80,
    linkHoverBackgroundColor: colors.WHITE_20,
    linkOutlineColor: colors.MYSTIC_80
  }
};

interface IconLinkSizing {
  linkSize: string;
}

type IconLinkSizes = {
  [key in SizeNames]: IconLinkSizing;
};

export const iconLinkSizes: IconLinkSizes = {
  [SizeNames.Small]: {
    linkSize: '1rem'
  },
  [SizeNames.Default]: {
    linkSize: '1.25rem'
  },
  [SizeNames.Large]: {
    linkSize: '1.25rem'
  }
};
