import { css } from 'styled-components';
import { ownUpWhiteTheme } from '../../../core';
import { Z_INDICES } from '../../../core/theme/constants/z-indices';
import { PropsWithTheme } from '../../../core/theme/properties';
import { ownUpBodyMixin, ownUpSmallBodyMediumMixin } from '../../../typography/body/mixins';
import { ownUpDetailMixin } from '../../../typography/detail/mixins';
import { OwnUpFormControlProps } from './properties';

const outerLabelFontSize = '1.25rem';
const innerLabelFontSizeUnselected = '1.5rem';
export const innerLabelFontSizeSelected = '1.125rem';
export const inputFontSpacingVertical = `${ownUpWhiteTheme.spacing(1.5)}px`;
export const inputFontSpacingHorizontal = `${ownUpWhiteTheme.spacing(2.5)}px`;
export const inputFontSizeResponse = '1.5rem';
export const inputFontSizePlaceholder = '1.5rem';
export const inputPadding = inputFontSpacingHorizontal;
export const inputBackgroundSpacing = inputPadding;
export const inputFontSpacingTopEntry = `${ownUpWhiteTheme.spacing(1.25)}px`;
export const inputFontSpacingBottomEntry = `${ownUpWhiteTheme.spacing(1.5)}px`;
export const inputBackgroundRadius = '4px';
export const inputBackgroundRadiusTop = inputBackgroundRadius;
export const inputBackgroundRadiusBottom = inputBackgroundRadius;
export const inputScrollbarWidth = '4px';
export const inputScrollbarSpacing = `${ownUpWhiteTheme.spacing(1)}px`;
export const inputIconSizeDropdown = '1.25rem';
const inputIconSizeError = '1.5rem';
const inputIconSpacing = `${ownUpWhiteTheme.spacing(2)}px`;

/**
 * Mixin to generate right-padding and text truncation properties
 *   for text in an input.
 *
 * Necessitates that the field receive a "Mui-error" class when in
 *   an error state.
 */
export const inputRightPaddingMixin = css`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(.MuiInputBase-inputAdornedEnd) {
    padding-right: calc(
      ${inputFontSpacingHorizontal} + ${inputIconSizeDropdown} + ${inputIconSpacing}
    );
  }

  &.MuiInputBase-inputAdornedEnd {
    padding-right: calc(
      ${inputFontSpacingHorizontal} + ${inputIconSizeDropdown} + (${inputIconSpacing} * 2) +
        ${inputIconSizeError}
    );
  }
`;

const outerLabelStyle = css<PropsWithTheme<OwnUpFormControlProps>>`
  ${ownUpSmallBodyMediumMixin}
  line-height: ${outerLabelFontSize};
  color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
    theme.inputColors.inputFontColorLabelAbove};
`;

const innerLabelStyle = css<PropsWithTheme<OwnUpFormControlProps>>`
  transform: unset;
  color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
    theme.inputColors.inputFontColorLabel};
  pointer-events: none;

  /* No selection made */
  &:not(.MuiInputLabel-shrink) {
    ${ownUpBodyMixin}
    margin: ${inputPadding};
    line-height: ${innerLabelFontSizeUnselected};
  }

  /* Selection made */
  &.MuiInputLabel-shrink {
    ${ownUpDetailMixin}
    margin: ${inputFontSpacingTopEntry} ${inputFontSpacingHorizontal} 0 ${inputFontSpacingHorizontal};
    line-height: ${innerLabelFontSizeSelected};
  }

  ${inputRightPaddingMixin}
`;

const inputUnderlineMixin = css`
  border-bottom: none;
  height: 1px;
  margin: auto;
  width: 100%;
  border-radius: 25px;
`;

export const ownUpFormControlStyle = css<PropsWithTheme<OwnUpFormControlProps>>`
  &.MuiFormControl-root {
    width: 100%;
  }

  & .MuiFormLabel-root {
    transform: unset;
    ${({ labelPosition }) => (labelPosition === 'outer' ? outerLabelStyle : innerLabelStyle)};
    z-index: ${Z_INDICES.FORM_LABELS};
  }

  & .MuiInputBase-root {
    margin-top: ${({ labelPosition }) =>
      labelPosition === 'outer' ? `calc(${inputFontSpacingVertical} + ${outerLabelFontSize})` : 0};
    margin-bottom: ${inputFontSpacingVertical};
  }

  .MuiInput-underline:not(.Mui-error):before,
  .MuiInput-underline:not(.Mui-error):after {
    ${inputUnderlineMixin}
    background-color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
      theme.inputColors.inputUnderline};
  }
  .MuiInput-underline.Mui-error:before,
  .MuiInput-underline.Mui-error:after {
    ${inputUnderlineMixin}
    background-color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
      theme.inputColors.inputBorderColorError};
  }
  .MuiInput-underline:not(.Mui-error):not(.Mui-disabled):hover:before,
  .MuiInput-underline:not(.Mui-error):not(.Mui-disabled):hover:after,
  .MuiInput-underline:not(.Mui-error).Mui-focused:before,
  .MuiInput-underline:not(.Mui-error).Mui-focused:after {
    ${inputUnderlineMixin}
    height: 2px;
    background-color: ${(props: PropsWithTheme<OwnUpFormControlProps>) =>
      props.theme.inputColors.inputUnderline};
  }

  .MuiInput-underline.Mui-error:not(.Mui-disabled):hover:before,
  .MuiInput-underline.Mui-error:not(.Mui-disabled):hover:after,
  .MuiInput-underline.Mui-error.Mui-focused:before,
  .MuiInput-underline.Mui-error.Mui-focused:after {
    ${inputUnderlineMixin}
    height: 2px;
    background-color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
      theme.inputColors.inputBorderColorError};
  }

  & .MuiFormHelperText-root {
    ${ownUpDetailMixin}
    color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
      theme.inputColors.inputFontColorHelp};
    margin: 0 ${inputFontSpacingHorizontal} ${inputFontSpacingVertical}
      ${inputFontSpacingHorizontal};

    &.Mui-error {
      color: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
        theme.inputColors.inputFontColorError};
    }
  }

  & .MuiInputAdornment-positionEnd {
    position: absolute;
    right: calc(${inputIconSizeDropdown} + ${inputFontSpacingHorizontal} + ${inputIconSpacing});
    pointer-events: none;

    &,
    & svg {
      margin: 0;
      width: ${inputIconSizeError};
      height: ${inputIconSizeError};
      & path {
        fill: ${({ theme }: PropsWithTheme<OwnUpFormControlProps>) =>
          theme.inputColors.inputFontColorError};
      }
    }
  }
`;
