import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { OwnUpTooltip } from '../../../tooltips/tooltip';
import { OwnUpFormControlProps } from './properties';
import { ownUpFormControlStyle } from './style';

/**
 * Wrapper around MUI's {@see FormControl} element which helps
 *   structure pages with form elements. Contains common styling
 *   for certain label types.
 *
 * @param {@see OwnUpFormControlProps}
 */
const BaseFormControl = ({
  children,
  disabled,
  disabledReason,
  $inputId,
  label,
  labelPosition,
  helperText,
  error,
  inputLabelRef,
  formHelperTextRef,
  errorTextRef,
  shrink,
  ...props
}: OwnUpFormControlProps) => {
  // Label will be rendered either embedded in the form element
  //   or above it, depending on the "labelPosition" input
  const labelElement = useMemo(() => {
    return label ? (
      <InputLabel
        error={!!error}
        htmlFor={$inputId}
        // Match the input's enabled/disabled state only if it's an inner
        //   label.
        disabled={labelPosition === 'inner' ? disabled : false}
        // Always set the label to shrink when it's an outer label
        shrink={labelPosition === 'outer' ? true : shrink}
        ref={inputLabelRef}
      >
        {label}
      </InputLabel>
    ) : undefined;
  }, [disabled, error, $inputId, inputLabelRef, label, labelPosition, shrink]);

  // Below the element the helper text will be rendered (if
  //   provided). If an error message is present it will be
  //   shown instead.
  const helperTextElement = useMemo(() => {
    return error || helperText ? (
      <FormHelperText error={!!error} ref={error ? errorTextRef : formHelperTextRef}>
        {error || helperText}
      </FormHelperText>
    ) : undefined;
  }, [helperText, error, errorTextRef, formHelperTextRef]);

  // If the element is disabled and a disabledReason is provided,
  //   a tooltip displaying the disabledReason will be shown.
  const tooltipWrappedComponent = useMemo(() => {
    return disabled && disabledReason ? (
      <OwnUpTooltip description={disabledReason}>{children}</OwnUpTooltip>
    ) : (
      children
    );
  }, [children, disabled, disabledReason]);

  // Memoize the full component.
  const fullComponent = useMemo(() => {
    return (
      <FormControl error={!!error} {...props}>
        {labelElement}
        {tooltipWrappedComponent}
        {helperTextElement}
      </FormControl>
    );
  }, [tooltipWrappedComponent, error, helperTextElement, labelElement, props]);

  return fullComponent;
};

/**
 * Wrapper around MUI's {@see FormControl} element which helps
 *   structure pages with form elements. Contains common styling
 *   for certain label types.
 *
 * @param {@see OwnUpFormControlProps}
 */
export const OwnUpFormControl = styled(BaseFormControl)`
  ${ownUpFormControlStyle}
`;
