/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme } from '@material-ui/core/styles';
import { accordionTheme, AccordionThemeTokens } from '../../accordions/theme';
import { buttonTheme, ButtonThemeTokens } from '../../buttons/theme';
import { ATLANTIC_100, BERRY_100, CHARCOAL_100, PINE_100, WHITE_100 } from '../../colors';
import { backgroundColorsTheme, BackgroundColorTokens } from '../../colors/theme';
import { checkboxTheme, CheckboxThemeTokens } from '../../inputs/checkbox/theme';
import { errorContainerTheme, ErrorContainerThemeTokens } from '../../inputs/error-container/theme';
import { radioButtonTheme, RadioButtonThemeTokens } from '../../inputs/radio/radio-button/theme';
import { inputTheme } from '../../inputs/text-input/theme';
import { InputThemeTokens } from '../../inputs/text-input/theme';
import {
  linkTheme,
  LinkThemeTokens,
  underlineLinkTheme,
  UnderlineLinkThemeTokens
} from '../../links/theme';
import { navLinkTheme, NavThemeTokens } from '../../nav/theme';
import { notificationTheme, NotificationThemeTokens } from '../../notifications/theme';
import {
  iconTooltipTriggerTheme,
  IconTooltipTriggerThemeTokens,
  underlineTooltipTriggerTheme,
  UnderlineTooltipTriggerThemeTokens
} from '../../tooltip-triggers/theme';
import { tooltipTheme, TooltipThemeTokens } from '../../tooltips/theme';

import {
  aktivGroteskBoldFontDefinition,
  aktivGroteskItalicFontDefinition,
  aktivGroteskMediumFontDefinition,
  aktivGroteskRegularFontDefinition,
  monostenCFontDefinition,
  p22MackinacBookFontDefinition,
  p22MackinacMediumFontDefinition
} from './fonts';

interface ThemeType {
  name: string;
  inputColors: InputThemeTokens;
  linkColors: LinkThemeTokens;
  underlineLinkColors: UnderlineLinkThemeTokens;
  navLinkColors: NavThemeTokens;
  checkboxColors: CheckboxThemeTokens;
  radioButtonColors: RadioButtonThemeTokens;
  buttonColors: ButtonThemeTokens;
  tooltipColors: TooltipThemeTokens;
  accordionColors: AccordionThemeTokens;
  underlineTooltipTriggerTheme: UnderlineTooltipTriggerThemeTokens;
  iconTooltipTriggerTheme: IconTooltipTriggerThemeTokens;
  notificationColors: NotificationThemeTokens;
  backgroundColors: BackgroundColorTokens;
  errorContainerColors: ErrorContainerThemeTokens;
}

// extend Theme to allow custom properties
declare module '@material-ui/core/styles/createTheme' {
  interface Theme extends ThemeType {}
  // allow configuration using `createTheme`
  interface ThemeOptions extends ThemeType {}
}

export const ownUpWhiteTheme = createTheme({
  name: 'own-up-white',
  /**
   * Custom breakpoints a defined in ZeroHeight:
   *   {@link https://zeroheight.com/7b3231350/p/973579-breakpoints}
   *
   * Numbers indicate the _lower_ bound of the breakpoint.
   *
   * Material UI Documentation: {@link https://v4.mui.com/customization/breakpoints/#breakpoints}
   */
  breakpoints: {
    values: {
      xs: 0,
      sm: 512,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 4
  },
  typography: {
    fontFamily: '"Aktiv-Grotesk-Regular", Helvetica, sans-serif',
    fontSize: 16
  },
  inputColors: inputTheme.white,
  linkColors: linkTheme.white,
  underlineLinkColors: underlineLinkTheme.white,
  navLinkColors: navLinkTheme.white,
  checkboxColors: checkboxTheme.white,
  buttonColors: buttonTheme.white,
  tooltipColors: tooltipTheme.white,
  accordionColors: accordionTheme.white,
  radioButtonColors: radioButtonTheme.white,
  underlineTooltipTriggerTheme: underlineTooltipTriggerTheme.white,
  iconTooltipTriggerTheme: iconTooltipTriggerTheme.white,
  notificationColors: notificationTheme.white,
  backgroundColors: backgroundColorsTheme.white,
  errorContainerColors: errorContainerTheme.white,
  palette: {
    primary: {
      main: PINE_100
    },
    secondary: {
      main: ATLANTIC_100
    },
    error: {
      main: BERRY_100
    },
    background: {
      default: backgroundColorsTheme.white.primaryTheme?.backgroundColor
    },
    text: {
      primary: CHARCOAL_100
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // Global font declarations
        '@font-face': [
          aktivGroteskBoldFontDefinition,
          aktivGroteskItalicFontDefinition,
          aktivGroteskMediumFontDefinition,
          aktivGroteskRegularFontDefinition,
          monostenCFontDefinition,
          p22MackinacBookFontDefinition,
          p22MackinacMediumFontDefinition
        ],
        // Apply to body
        body: {
          overflowX: 'hidden',
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0
        }
      }
    },
    MuiScopedCssBaseline: {
      root: {
        // Note, any CSS rules that involve selectors must be prefixed
        //   with an & to indicate ot the JSS engine that these should
        //   not be treated as JavaScript attributes, but CSS rules.
        '& *': {
          // Apply border-box to all child elements
          boxSizing: 'border-box'
        },
        // For disabled / inactive elements, the top-level container should be set to
        //   40% opacity. Any disabled elements below that should be forced
        //   to 100% opacity so that it doesn't double-dip on the transparency
        //   change.
        '& [aria-disabled="true"],& .Mui-disabled': {
          opacity: '40%',
          '& [aria-disabled="true"],& .Mui-disabled': {
            opacity: '100%',
            // Keep Mui's hiding of original inputs
            '& input[type="radio"],& input[type="checkbox"]': { opacity: 0 }
          }
        },
        '& [disabled],& .Mui-disabled': {
          opacity: '40%',
          '& [disabled],& .Mui-disabled': {
            opacity: '100%',
            // Keep Mui's hiding of original inputs
            '& input[type="radio"],& input[type="checkbox"]': { opacity: 0 }
          }
        }
      }
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: undefined,
          '@media (hover: none)': {
            backgroundColor: undefined
          }
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true // Disable ripple on all buttons by default
    }
  }
});

// ownUpWhiteTheme extensions
export const ownUpLightTheme = createTheme({
  ...ownUpWhiteTheme,
  name: 'own-up-light',
  inputColors: inputTheme.light,
  linkColors: linkTheme.light,
  underlineLinkColors: underlineLinkTheme.light,
  navLinkColors: navLinkTheme.light,
  checkboxColors: checkboxTheme.light,
  radioButtonColors: radioButtonTheme.light,
  buttonColors: buttonTheme.light,
  tooltipColors: tooltipTheme.light,
  accordionColors: accordionTheme.light,
  underlineTooltipTriggerTheme: underlineTooltipTriggerTheme.light,
  iconTooltipTriggerTheme: iconTooltipTriggerTheme.light,
  notificationColors: notificationTheme.light,
  backgroundColors: backgroundColorsTheme.light,
  errorContainerColors: errorContainerTheme.light,
  palette: {
    ...ownUpWhiteTheme.palette,
    background: {
      default: backgroundColorsTheme.light.tertiaryTheme?.backgroundColor
    },
    text: {
      primary: CHARCOAL_100
    }
  }
});

export const ownUpMidtoneTheme = createTheme({
  ...ownUpWhiteTheme,
  name: 'own-up-midtone',
  inputColors: inputTheme.midtone,
  linkColors: linkTheme.midtone,
  underlineLinkColors: underlineLinkTheme.midtone,
  navLinkColors: navLinkTheme.midtone,
  checkboxColors: checkboxTheme.midtone,
  radioButtonColors: radioButtonTheme.midtone,
  buttonColors: buttonTheme.midtone,
  tooltipColors: tooltipTheme.midtone,
  accordionColors: accordionTheme.midtone,
  underlineTooltipTriggerTheme: underlineTooltipTriggerTheme.midtone,
  iconTooltipTriggerTheme: iconTooltipTriggerTheme.midtone,
  notificationColors: notificationTheme.midtone,
  backgroundColors: backgroundColorsTheme.midtone,
  errorContainerColors: errorContainerTheme.midtone,
  palette: {
    ...ownUpWhiteTheme.palette,
    background: {
      default: backgroundColorsTheme.midtone.primaryTheme?.backgroundColor
    },
    text: {
      primary: CHARCOAL_100
    }
  }
});

export const ownUpDarkTheme = createTheme({
  ...ownUpWhiteTheme,
  name: 'own-up-dark',
  inputColors: inputTheme.dark,
  linkColors: linkTheme.dark,
  underlineLinkColors: underlineLinkTheme.dark,
  navLinkColors: navLinkTheme.dark,
  checkboxColors: checkboxTheme.dark,
  radioButtonColors: radioButtonTheme.dark,
  buttonColors: buttonTheme.dark,
  tooltipColors: tooltipTheme.dark,
  accordionColors: accordionTheme.dark,
  underlineTooltipTriggerTheme: underlineTooltipTriggerTheme.dark,
  iconTooltipTriggerTheme: iconTooltipTriggerTheme.dark,
  notificationColors: notificationTheme.dark,
  backgroundColors: backgroundColorsTheme.dark,
  errorContainerColors: errorContainerTheme.dark,
  palette: {
    ...ownUpWhiteTheme.palette,
    background: {
      default: backgroundColorsTheme.dark.tertiaryTheme?.backgroundColor
    },
    text: {
      primary: WHITE_100
    }
  }
});
