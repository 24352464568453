/* stylelint-disable font-family-no-missing-generic-family-keyword */
import { css } from 'styled-components';

/**
 * Mixin to apply the Monosten C font.
 */
export const monostenCMixin = css`
  font-family: 'Monosten', 'Courier-New', Courier, monospace;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
`;
