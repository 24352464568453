import React, { useMemo } from 'react';
import { BREAKPOINTS } from '../../core/theme/breakpoints';
import { useCombinedClassName } from '../../hooks/use-combined-class-name';
import { OwnUpGridItemProps } from '../item';
import { OwnUpGridBreakProps } from './properties';
import { StyledGridBreak } from './styles';

/**
 * A wrapper around our {@link OwnUpGridItem} component that
 *   contains nothing in it and occupies the rest of the grid
 *   row.
 *
 * You should only use this when you need to force a grid break.
 *   You should avoid using these when it's not necessary (i.e.
 *   you already have 12 columns used.) Though the CSS will ensure
 *   that they do not cause extra voidspace.
 *
 * Can use the breakpoint args (`xs`, `sm`, `md`, `lg`, `xl`) to
 *   dictate if it should appear or not at certain screen sizes.
 *
 * Like GridItems, you should set at least one field (usually `xs`) to
 *   true.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://v4.mui.com/api/grid/}
 *
 * @param props - {@link OwnUpGridBreakProps}
 */
export const OwnUpGridBreak = ({ className, ...props }: OwnUpGridBreakProps) => {
  /* Add a class name to the break. */
  const builtClassName = useCombinedClassName(className, 'break');
  /* Build modified properties to show/hide the breakpoint at certain resolutions */
  const modifiedProps = useMemo(
    () =>
      BREAKPOINTS.reduce((acc, cur) => {
        const value = acc[cur];
        if (value === true) {
          acc[cur] = 'auto';
        } else if (value === false) {
          acc[cur] = 0;
        } else {
          acc[cur] = undefined;
        }
        return acc;
      }, props as OwnUpGridItemProps),
    [props]
  );
  return <StyledGridBreak className={builtClassName} {...modifiedProps} />;
};
