import React from 'react';
import { useSanitizedUrl } from '../hooks/use-sanitized-url';
import { UnderlineLinkProps } from './properties';
import { UnderlineLinkWrapper } from './styles';

export const OwnUpUnderlineLink = ({
  link,
  wrapperRef = undefined,
  children,
  ...props
}: UnderlineLinkProps) => (
  <UnderlineLinkWrapper
    href={useSanitizedUrl(link)}
    underline="none"
    ref={wrapperRef}
    tabIndex={0}
    {...props}
  >
    {children}
  </UnderlineLinkWrapper>
);
