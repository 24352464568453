import React from 'react';
import * as colors from '../../../colors';
import { useRandomId } from '../../../hooks/use-random-id';

export const MicroCloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1899_23336)">
      <path
        d="M1.2197 9.7196C0.926807 10.0125 0.926807 10.4874 1.2197 10.7803C1.51259 11.0732 1.98747 11.0732 2.28036 10.7803L6.00003 7.06059L9.7197 10.7803C10.0126 11.0732 10.4875 11.0732 10.7804 10.7803C11.0733 10.4874 11.0733 10.0125 10.7804 9.7196L7.06069 5.99993L10.7656 2.29498C11.0585 2.00209 11.0585 1.52721 10.7656 1.23432C10.4727 0.941425 9.99788 0.941425 9.70498 1.23432L6.00003 4.93927L2.29508 1.23432C2.00219 0.941425 1.52731 0.941426 1.23442 1.23432C0.941526 1.52721 0.941526 2.00209 1.23442 2.29498L4.93937 5.99993L1.2197 9.7196Z"
        fill={colors.CHARCOAL_100}
      />
    </g>
    <defs>
      <clipPath id={useRandomId('clip')}>
        <rect width="10" height="10" fill={colors.WHITE_100} transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);
