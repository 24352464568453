import React from 'react';
import * as colors from '../../colors';
import { ThemeNames } from '../../core/theme/types';

export interface CheckboxThemeTokens {
  checkboxFontColorLabel: React.CSSProperties['color'];
  checkboxColorBorder: React.CSSProperties['color'];
  checkboxColorHover: React.CSSProperties['color'];
  checkboxColorFocus: React.CSSProperties['color'];
  checkboxColorSelected: React.CSSProperties['color'];
  checkboxColorControl: React.CSSProperties['color'];
  checkboxColorBackground: React.CSSProperties['color'];
}

type CheckboxTheme = {
  [key in ThemeNames]: CheckboxThemeTokens;
};

const lightColors = {
  checkboxFontColorLabel: colors.CHARCOAL_100,
  checkboxColorBorder: colors.ALOE_100,
  checkboxColorHover: colors.SAGE_100,
  checkboxColorFocus: colors.SAGE_100,
  checkboxColorSelected: colors.ALOE_100,
  checkboxColorControl: colors.WHITE_100,
  checkboxColorBackground: colors.WHITE_100
};

const darkColors = {
  checkboxFontColorLabel: colors.WHITE_100,
  checkboxColorBorder: colors.WHITE_100,
  checkboxColorHover: colors.WHITE_20,
  checkboxColorFocus: colors.WHITE_20,
  checkboxColorSelected: colors.WHITE_100,
  checkboxColorControl: colors.CHARCOAL_100,
  checkboxColorBackground: colors.WHITE_10
};

export const checkboxTheme: CheckboxTheme = {
  [ThemeNames.White]: { ...lightColors, checkboxColorBackground: colors.FOG_20 },
  [ThemeNames.Light]: lightColors,
  [ThemeNames.Midtone]: lightColors,
  [ThemeNames.Dark]: darkColors
};
