import React from 'react';
import * as colors from '../../colors';
import { ThemeNames } from '../../core/theme/types';

export interface ErrorContainerThemeTokens {
  inputFontColorError: React.CSSProperties['color'];
}

type ErrorContainerTheme = {
  [key in ThemeNames]: ErrorContainerThemeTokens;
};

const whiteColors = {
  inputFontColorError: colors.BERRY_100
};

const midtoneColors = {
  inputFontColorError: colors.CHARCOAL_100
};

const darkColors = {
  inputFontColorError: colors.WHITE_100
};

export const errorContainerTheme: ErrorContainerTheme = {
  [ThemeNames.White]: whiteColors,
  [ThemeNames.Light]: whiteColors,
  [ThemeNames.Midtone]: midtoneColors,
  [ThemeNames.Dark]: darkColors
};
