import React from 'react';
import { OwnUpIconButtonQuiet } from '../buttons';
import { ownUpWhiteTheme } from '../core';
import { OwnUpThemeProvider } from '../core/provider';
import { useCombinedClassName } from '../hooks/use-combined-class-name';
import { CloseLargeIcon } from '../icon-library/system-icons/standard-icons/close-large';
import { OwnUpNavMenuModalProps } from './properties';
import {
  ModalButtonsContainer,
  ModalLinksContainer,
  ModalOwnUpLogoDesktopContainer,
  ModalOwnUpLogoMobileContainer,
  ModalSection,
  OwnUpNavModalContainer,
  OwnUpNavModalHeader
} from './styles/nav-menu-modal';

export const OwnUpNavMenuModal = ({
  children,
  className,
  buttons,
  homepageLinkProps,
  onClose,
  ...props
}: OwnUpNavMenuModalProps) => {
  const exitButtonName = useCombinedClassName(className, 'nav-modal-exit-button');

  return (
    <OwnUpNavModalContainer anchor="right" elevation={0} onClose={onClose} {...props}>
      <OwnUpThemeProvider theme={ownUpWhiteTheme}>
        <ModalSection>
          <OwnUpNavModalHeader>
            <ModalOwnUpLogoDesktopContainer {...homepageLinkProps} />
            <ModalOwnUpLogoMobileContainer {...homepageLinkProps} />
            <OwnUpIconButtonQuiet
              className={exitButtonName}
              data-test-id="modalExitButton"
              name="Modal Exit Button"
              size={2.5}
              onClick={() => onClose()}
              icon={<CloseLargeIcon />}
            />
          </OwnUpNavModalHeader>
          <ModalLinksContainer>{children}</ModalLinksContainer>
        </ModalSection>
        {buttons ? (
          <ModalSection $footer={true}>
            <ModalButtonsContainer>{buttons}</ModalButtonsContainer>
          </ModalSection>
        ) : null}
      </OwnUpThemeProvider>
    </OwnUpNavModalContainer>
  );
};
