import AccordionDetails from '@material-ui/core/AccordionDetails';
import React, { useMemo, useState } from 'react';
import { decorativeButtonProps, OwnUpIconButton } from '../buttons';
import { OwnUpThemeProvider } from '../core/provider';
import { useTextRepresentation } from '../hooks/use-text-representation';
import { MinusIcon } from '../icon-library/system-icons/standard-icons/minus';
import { PlusIcon } from '../icon-library/system-icons/standard-icons/plus';
import { useAccordionChildTheme } from './helpers';
import { OwnUpAccordionProps } from './properties';
import {
  AccordionMediumTitleStyle,
  AccordionRegularTitleStyle,
  DescriptionStyle,
  LineAccordionIcon,
  SlimLineAccordionDescriptionStyle,
  SlimLineAccordionMediumTitleStyle,
  SlimLineAccordionRegularTitleStyle,
  StyledAccordion,
  StyledAccordionSummary
} from './styles';

interface ImplAccordionProps extends OwnUpAccordionProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'aria-label'?: string;
  $isLineAccordion?: boolean;
  $isSlimLineAccordion?: boolean;
}

export const OwnUpAccordion = ({
  expanded,
  'aria-label': ariaLabel,
  title,
  children,
  $isLineAccordion,
  $isSlimLineAccordion,
  $expandIconPosition = 'right',
  ...props
}: ImplAccordionProps) => {
  const [focusVisible, setFocusVisible] = useState(false);

  const childTheme = useAccordionChildTheme();

  // Icon to use for the icon button
  const iconButton = useMemo(() => (expanded ? <MinusIcon /> : <PlusIcon />), [expanded]);

  // Screen reader declaration for the icon button
  const buttonAction = useMemo(() => (expanded ? 'collapse' : 'expand'), [expanded]);

  const accordionTitle = useMemo(
    () =>
      expanded ? (
        <AccordionMediumTitleStyle variant="body1">{title}</AccordionMediumTitleStyle>
      ) : (
        <AccordionRegularTitleStyle variant="body1">{title}</AccordionRegularTitleStyle>
      ),
    [title, expanded]
  );

  const slimLineAccordionTitle = useMemo(
    () =>
      expanded ? (
        <SlimLineAccordionMediumTitleStyle variant="body1">
          {title}
        </SlimLineAccordionMediumTitleStyle>
      ) : (
        <SlimLineAccordionRegularTitleStyle variant="body1">
          {title}
        </SlimLineAccordionRegularTitleStyle>
      ),
    [title, expanded]
  );
  const derivedAriaLabel = useTextRepresentation(title);

  return (
    <StyledAccordion
      expanded={expanded}
      $focusVisible={focusVisible}
      aria-label={ariaLabel || derivedAriaLabel}
      $isLineAccordion={$isLineAccordion}
      $isSlimLineAccordion={$isSlimLineAccordion}
      $expandIconPosition={$expandIconPosition}
      {...props}
    >
      <StyledAccordionSummary
        onFocusVisible={() => setFocusVisible(true)}
        onBlur={() => setFocusVisible(false)}
        aria-label={ariaLabel || derivedAriaLabel}
        IconButtonProps={decorativeButtonProps}
        $isLineAccordion={$isLineAccordion}
        $isSlimLineAccordion={$isSlimLineAccordion}
        $expandIconPosition={$expandIconPosition}
        expandIcon={
          $isLineAccordion ? (
            <LineAccordionIcon icon={iconButton} />
          ) : (
            <OwnUpThemeProvider theme={childTheme}>
              <OwnUpIconButton
                name={buttonAction}
                size={2}
                icon={iconButton}
                {...decorativeButtonProps}
              />
            </OwnUpThemeProvider>
          )
        }
      >
        <OwnUpThemeProvider theme={childTheme}>
          {$isSlimLineAccordion ? slimLineAccordionTitle : accordionTitle}
        </OwnUpThemeProvider>
      </StyledAccordionSummary>
      {children && (
        <AccordionDetails>
          <OwnUpThemeProvider theme={childTheme}>
            {$isSlimLineAccordion ? (
              <SlimLineAccordionDescriptionStyle variant="body1">
                {children}
              </SlimLineAccordionDescriptionStyle>
            ) : (
              <DescriptionStyle variant="body1">{children}</DescriptionStyle>
            )}
          </OwnUpThemeProvider>
        </AccordionDetails>
      )}
    </StyledAccordion>
  );
};
