import { css } from 'styled-components';
import { Breakpoint, BREAKPOINTS } from '../../core/theme/breakpoints';
import { PropsWithTheme } from '../../core/theme/properties';
import { combineMediaQueries } from '../../modules/combine-media-queries';
import { OwnUpGridItemProps } from './properties';

/**
 * Produces a media query for this breakpoint by determining
 *   where the next defined breakpoint is.
 *
 * @param props - GridItemProperties
 * @param breakpoint - Current Breakpoint
 * @param currentIndex - Index of current breakpoint
 * @param all - Full list of breakpoints
 */
const createMediaQuery = (
  props: PropsWithTheme<OwnUpGridItemProps>,
  breakpoint: Breakpoint,
  currentIndex: number,
  all: readonly Breakpoint[]
) => {
  // Find the smallest breakpoint that is bigger than this but
  //  has a size declaration.
  const nextIndex = all.findIndex((x, i) => i > currentIndex && props[x]) - 1;
  if (nextIndex >= currentIndex) {
    // If it's the next index...
    if (nextIndex === currentIndex) {
      // ... this css is only for this index.
      return props.theme.breakpoints.only(breakpoint);
    }
    // ... otherwise it's for everything between these breakpoints
    return props.theme.breakpoints.between(breakpoint, all[nextIndex]);
  }
  return props.theme.breakpoints.up(breakpoint);
};

/**
 * Determines what media queries to generate for hiding this grid item.
 *   Does this by checking for any breakpoints with a "0" size (e.g.
 *   `xs={0}`) and then determining what the next highest breakpoint is.
 * Then turns the media queries into a comma-delimited list so that all
 *   applicable queries follow the same logic.
 */
export const createNoDisplaySelectors = (props: PropsWithTheme<OwnUpGridItemProps>) =>
  combineMediaQueries(
    BREAKPOINTS.reduce((acc, breakpoint, index, all) => {
      if (props[breakpoint] === 0) {
        acc.push(createMediaQuery(props, breakpoint, index, all));
      }
      return acc;
    }, [] as string[])
  ) || 'never';

/**
 * Produces media-query blocks that set `display: none` for any breakpoints
 *   with a "0" grid size (e.g. `xs={0}`), e.g.:
 * ```css
 * @media (min-width:0px) and (max-width:511.95px),
 * @media (min-width:768px) and (max-width:1279.95px) {
 *   display: none;
 * }
 * ```
 */
export const noDisplayMixin = css<PropsWithTheme<OwnUpGridItemProps>>`
  ${(props: PropsWithTheme<OwnUpGridItemProps>) => createNoDisplaySelectors(props)} {
    display: none;
  }
`;
