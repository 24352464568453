import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../core';
import { monostenCMixin } from '../../fonts';
import { OwnUpOverlineProps } from './properties';

const Overline = (props: OwnUpOverlineProps) => <Typography {...props} />;

export const OwnUpOverline = styled(Overline)<PropsWithTheme<OwnUpOverlineProps>>`
  ${monostenCMixin}

  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.25em;
`;
