import React from 'react';
import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export interface AccordionThemeTokens {
  accordionFontColor: React.CSSProperties['color'];
  accordionBackgroundColor: React.CSSProperties['color'];
  accordionFocusColor: React.CSSProperties['color'];
  accordionIconColor: React.CSSProperties['color'];
  accordionBorderColor: React.CSSProperties['color'];
}

type AccordionTheme = {
  [key in ThemeNames]: AccordionThemeTokens;
};

const commonColors = {
  accordionFontColor: colors.CHARCOAL_100,
  accordionBackgroundColor: colors.MOSS_50,
  accordionFocusColor: colors.ALOE_100,
  // line accordions
  accordionIconColor: colors.ALOE_100,
  accordionBorderColor: colors.MINT_100
};

const lightColors = {
  ...commonColors,
  accordionBackgroundColor: colors.WHITE_100
};

const midtoneColors = {
  ...lightColors,
  // line accordions
  accordionBorderColor: colors.ALOE_100
};

const darkColors = {
  accordionFontColor: colors.WHITE_100,
  accordionBackgroundColor: colors.WHITE_10,
  accordionFocusColor: colors.WHITE_100,
  // line accordions
  accordionIconColor: colors.WHITE_100,
  accordionBorderColor: colors.WHITE_100
};

export const accordionTheme: AccordionTheme = {
  [ThemeNames.White]: commonColors,
  [ThemeNames.Light]: lightColors,
  [ThemeNames.Midtone]: midtoneColors,
  [ThemeNames.Dark]: darkColors
};
