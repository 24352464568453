import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../core';
import {
  ownUpBodyItalicMixin,
  ownUpBodyMediumMixin,
  ownUpBodyMixin,
  ownUpLargeBodyMediumMixin,
  ownUpLargeBodyMixin,
  ownUpSmallBodyItalicMixin,
  ownUpSmallBodyMediumMixin,
  ownUpSmallBodyMixin
} from './mixins';
import { OwnUpBodyProps } from './properties';

const Body = (props: TypographyProps) => <Typography {...props} />;

export const OwnUpLargeBody = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpLargeBodyMixin}
`;

export const OwnUpLargeBodyMedium = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpLargeBodyMediumMixin}
`;

export const OwnUpBody = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpBodyMixin}
`;

export const OwnUpBodyMedium = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpBodyMediumMixin}
`;

export const OwnUpBodyItalic = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpBodyItalicMixin}
`;

export const OwnUpSmallBody = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpSmallBodyMixin}
`;

export const OwnUpSmallBodyMedium = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpSmallBodyMediumMixin}
`;

export const OwnUpSmallBodyItalic = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${ownUpSmallBodyItalicMixin}
`;
