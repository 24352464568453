import styled from 'styled-components';
import { CHARCOAL_5, FOG_20 } from '../../colors';
import { ownUpWhiteTheme, PropsWithTheme } from '../../core';
import { Z_INDICES } from '../../core/theme/constants/z-indices';
import { OwnUpGridWrapper } from '../../grid';
import { IconLink } from '../../links';
import { OwnUpSmallBody } from '../../typography/body';

export const FullWidthContainer = styled.div`
  width: 100vw;
  background: ${FOG_20};
  box-shadow: 4px 4px 8px 0px ${CHARCOAL_5};
  z-index: ${Z_INDICES.NAVBAR_DRAWER};
  position: fixed;
  left: 0;
`;

export const OwnUpNavMenuDrawerStyleDesktop = styled(OwnUpGridWrapper)`
  padding-top: ${ownUpWhiteTheme.spacing(6)}px;
  padding-bottom: ${ownUpWhiteTheme.spacing(8)}px;
`;

export const DescriptionStyle = styled(OwnUpSmallBody)`
  margin-top: ${ownUpWhiteTheme.spacing(2)}px;
`;

export const MenuDrawerItemDesktop = styled.div`
  flex: 1 1 0;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 1.5)};
`;

export const DrawerItemLinkDesktop = styled(IconLink)`
  margin-top: ${ownUpWhiteTheme.spacing(2)}px;
`;

export const ModalDrawerContainer = styled.div`
  margin-left: ${ownUpWhiteTheme.spacing(3)}px;
  display: flex;
  flex-direction: column;
`;
