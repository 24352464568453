import React from 'react';
import * as colors from '../../colors';

export const WarningDarkIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4991 2.09647C11.2598 0.718657 13.2402 0.718655 14.0009 2.09647L23.8622 19.9584C24.5981 21.2914 23.6339 22.9251 22.1113 22.9251H2.38872C0.866105 22.9251 -0.0980752 21.2914 0.637834 19.9584L10.4991 2.09647Z"
      style={{ fill: colors.APRICOT_100 }}
    />
    <path
      d="M11.65 8.5C11.4709 8.5 11.2997 8.57392 11.1769 8.70432C11.054 8.83471 10.9905 9.01001 11.0012 9.18882L11.3511 15.0388C11.3717 15.3821 11.6561 15.65 12 15.65C12.3439 15.65 12.6283 15.3821 12.6488 15.0388L12.9988 9.18882C13.0095 9.01001 12.9459 8.83471 12.8231 8.70432C12.7003 8.57392 12.5291 8.5 12.35 8.5H11.65Z"
      style={{ fill: colors.WHITE_100 }}
    />
    <path
      d="M13 18.5C13 19.0523 12.5523 19.5 12 19.5C11.4477 19.5 11 19.0523 11 18.5C11 17.9477 11.4477 17.5 12 17.5C12.5523 17.5 13 17.9477 13 18.5Z"
      style={{ fill: colors.WHITE_100 }}
    />
  </svg>
);
