// Primary colors
export const PINE_100 = '#1E4C49' as const;
export const ALOE_100 = '#3E6D6A' as const;
export const CACTUS_100 = '#84CDAA' as const;
export const CACTUS_80 = '#9DD7BB' as const;
export const MINT_100 = '#B9DDCC' as const;
export const MINT_80 = '#C7E4D6' as const;
export const SAGE_100 = '#DDECE5' as const;
export const MOSS_100 = '#EDF5F1' as const;
export const MOSS_70 = '#F2F8F5' as const;
export const MOSS_50 = '#F6FAF8' as const;

// Secondary colors
export const ATLANTIC_100 = '#053C53' as const;
export const ATLANTIC_90 = '#1E4F64' as const;
export const WALDEN_100 = '#00769B' as const;
export const MYSTIC_100 = '#B6E3ED' as const;
export const MYSTIC_80 = '#C4E9F1' as const;
export const MYSTIC_60 = '#D3EEF4' as const;
export const MYSTIC_40 = '#E2F4F8' as const;
export const MYSTIC_20 = '#F0F9FB' as const;

// Accent colors
export const MANGO_100 = '#FFE485' as const;
export const MANGO_60 = '#FFEFB6' as const;
export const MANGO_40 = '#FFF4CE' as const;
export const APRICOT_100 = '#FFB21C' as const;
export const APRICOT_60 = '#FFD177' as const;
export const APRICOT_40 = '#FFE0A4' as const;
export const APRICOT_10 = '#FFF7E8' as const;

// Neutral colors
export const CHARCOAL_100 = '#1D1D1F' as const;
export const CHARCOAL_90 = '#343435' as const;
export const SLATE_100 = '#657383' as const;
export const FOG_100 = '#DBDFE2' as const;
export const FOG_60 = '#E9ECEE' as const;
export const FOG_40 = '#F1F2F3' as const;
export const FOG_20 = '#F8F9F9' as const;
export const FOG_10 = '#FBFCFC' as const;
export const WHITE_100 = '#FFFFFF' as const;

// Opacities
export const CHARCOAL_40 = '#1D1D1F66' as const;
export const CHARCOAL_20 = '#1D1D1F33' as const;
export const CHARCOAL_10 = '#1D1D1F19' as const;
export const CHARCOAL_5 = '#1D1D1F0C' as const;
export const SLATE_50 = '#6573837F' as const;
export const WHITE_60 = '#FFFFFF99' as const;
export const WHITE_50 = '#FFFFFF7F' as const;
export const WHITE_20 = '#FFFFFF33' as const;
export const WHITE_10 = '#FFFFFF19' as const;

// Semantic colors
export const BERRY_100 = '#BB2F37' as const;
export const BERRY_20 = '#F1D5D7' as const;
export const BERRY_10 = '#F8EAEB' as const;

// Gradients
export const GRADIENT_ALOE_PINE =
  'linear-gradient(180.0deg, rgba(62, 109, 106, 1.0) 0%, rgba(30, 76, 73, 1.0) 100%)' as const;
export const GRADIENT_MINT_CACTUS =
  'linear-gradient(180.0deg, rgba(185, 221, 204, 1.0) 0%, rgba(132, 205, 170, 1.0) 80%)' as const;
export const GRADIENT_WHITE_MOSS =
  'linear-gradient(180.0deg, rgba(255, 255, 255, 1.0) 0%, rgba(237, 245, 241, 1.0) 80%)' as const;
