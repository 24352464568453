/**
 * Mixin to create hover styling only on hover compatible devices.
 * Since mobile devices don't have a concept of hover, just having the
 * :hover selector will cause the style to get triggered on the first click
 * and not go away until something else on the screen is clicked (we call this sticky hover).
 *
 * To avoid this, use this mixin when you just want to define hover state on
 * hover compatible devices
 */
export const stickyHoverMixin = `@media (hover: hover)`;
