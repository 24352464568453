import CssBaseline from '@material-ui/core/CssBaseline';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/core/styles';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { OwnUpComponentLibraryPolyfills } from '../../polyfills';
import { ownUpWhiteTheme } from '../theme';
import { OwnUpHead } from './head';
import { OwnUpComponentLibraryProviderProps } from './properties';

/**
 * Applies styles to all children. Should be used if a certain
 *   section of the site differs in its theme from the root.
 *
 * @param props - See {@link OwnUpComponentLibraryProviderProps}
 */
export const OwnUpThemeProvider = ({
  theme = ownUpWhiteTheme,
  children
}: OwnUpComponentLibraryProviderProps) => {
  return (
    // Allow styled-components to also access the Material UI theme
    <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
  );
};

/**
 * Applies the core stylesheet to the page. You should
 *   only have one of these in your hierarchy or else
 *   your style sheets may get mixed up. Use {@link OwnUpThemeProvider}
 *   to have nested themes.
 *
 * @param children - Child elements to put inside this wrapper
 * @param props - See {@link OwnUpComponentLibraryProviderProps}
 */
export const OwnUpComponentLibraryProvider = ({
  theme = ownUpWhiteTheme,
  polyfills,
  children
}: OwnUpComponentLibraryProviderProps) => {
  return (
    <React.Fragment>
      {/* Inject MaterialUI styles first to ensure styled-components can override their definitions */}
      <StylesProvider injectFirst>
        <OwnUpHead />
        {/* Mui Theme Provider should onl be at the root*/}
        <MuiThemeProvider theme={theme}>
          <OwnUpThemeProvider theme={theme}>
            {/* Add the Mui CSS baseline to inject the fonts and body style. */}
            <CssBaseline />
            {/* Use the scoped CSS baseline as well so that if there are conflicting
                styles on the body they will be asserted here */}
            <ScopedCssBaseline>{children}</ScopedCssBaseline>
          </OwnUpThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
      <OwnUpComponentLibraryPolyfills {...polyfills} />
    </React.Fragment>
  );
};
