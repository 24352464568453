import React from 'react';
import * as colors from '../../colors';

export const ErrorLightIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" style={{ fill: colors.BERRY_20 }} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17.5C12.5523 17.5 13 17.0523 13 16.5C13 15.9477 12.5523 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5Z"
      style={{ fill: colors.BERRY_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1769 6.70432C11.2997 6.57392 11.4709 6.5 11.65 6.5H12.35C12.5291 6.5 12.7003 6.57392 12.8231 6.70432C12.9459 6.83471 13.0095 7.01001 12.9988 7.18882L12.6488 13.0388C12.6283 13.3821 12.3439 13.65 12 13.65C11.6561 13.65 11.3717 13.3821 11.3511 13.0388L11.0012 7.18882C10.9905 7.01001 11.054 6.83471 11.1769 6.70432Z"
      style={{ fill: colors.BERRY_100 }}
    />
  </svg>
);
